import { React, useState } from 'react'

import {
  EyeIcon,
  EyeSlashIcon,
  TrashIcon,
  ClockIcon,
  CalendarDaysIcon,
  Bars3Icon,
} from '@heroicons/react/24/outline'

// import { Draggable } from 'react-beautiful-dnd'

function formatTime(milliseconds) {
  const totalSeconds = Math.floor(milliseconds / 1000)
  let minutes = Math.floor(totalSeconds / 60)
  let seconds = totalSeconds % 60

  minutes = String(minutes).padStart(2, '0')
  seconds = String(seconds).padStart(2, '0')

  return `${minutes}:${seconds}`
}

function SlideListItem({ slide, onSelect, reference, draggableProps, dragHandleProps, deleteSlide, selectedSlide, index }) {

  const weekDays = ['L', 'M', 'X', 'J', 'V', 'S', 'D']

  // Manejador del evento click para seleccionar un slide
  const handleClick = () => {
    onSelect(slide)
  }

  // Manejador del evento click de visibilidad de un slide
  const [isVisible, setIsVisible] = useState(true)

  const handleEyeClick = () => {
    slide.config.settings.visibility = !slide.config.settings.visibility
    setIsVisible(!slide.config.settings.visibility)
  }

  // Manejador del evento click para borrar un slide
  const handleDeleteSlide = event => {
    event.stopPropagation()
    deleteSlide(slide.slideId)
  }

  // Constante de selector de slide
  const isSelected = selectedSlide && selectedSlide.slideId === slide.slideId

  return (
    <li

      {...draggableProps}
      {...dragHandleProps}
      onClick={handleClick}
      // Asociamos el evento click al manejador handleClick
      ref={reference}
    >
      <div className='flex items-center justify-between'>
        <div className='flex items-center'>
          <button onClick={handleEyeClick}
            className='me-2 text-gray-50 hover:text-gray-600'>
            {isVisible
              ? <EyeIcon className='h-4 w-4 text-gray-500' aria-hidden='true' />
              : <EyeSlashIcon className='h-4 w-4 text-gray-500' aria-hidden='true' />
            }
          </button>
        </div>
        <div className={`rounded-lg py-1 ps-1 pe-2 grow ${!slide.config.settings.visibility ? '' : 'opacity-30'} ${isSelected ? 'bg-blue-20' : 'bg-white'}`}>
          <div className='flex items-center'>
            <div className='w-16 rounded overflow-hidden me-2'>
              <img src='https://picsum.photos/seed/2/200/200' className='w-full aspect-video' />
            </div>
            <div className='grow flex flex-col'>
              <span className='font-semibold text-xs leading-none'>{slide.config.settings.title}</span>
              <div className='text-gray-50 text-xs leading-none flex items-center mt-0.5'>
                <ClockIcon className='h-4 w-4 me-1 text-gray-50 flex-[0_0_16px]' aria-hidden='true' />
                {slide.config.settings.autoDuration ? 'Auto' : formatTime(slide.config.settings.duration)}
              </div>
            </div>
            <div className='ms-4'>
              <div className='flex items-center'>
                <CalendarDaysIcon className='h-4 w-4 me-1 text-gray-50 flex-[0_0_16px]' aria-hidden='true' />
                <p className='text-gray-50 text-xs leading-none'>
                  {weekDays.map((day, index) => {
                    const isActive = slide.config.settings.daysActive.find(d => d.value === day && d.active)

                    return <span key={index} className={isActive ? 'text-primary' : ''}>{day} </span>
                  })}
                </p>
              </div>
              <div className='flex items-center mt-0.5'>
                <ClockIcon className='h-4 w-4 me-1 text-gray-50 flex-[0_0_16px]' aria-hidden='true' />
                <p className='text-gray-50 text-xs leading-none'>
                  {slide.config.settings.schedule.map(item =>
                    <span key={item.id}>{item.value} </span>
                  )}
                </p>
              </div>
            </div>
            <Bars3Icon className='h-4 w-4 ms-2 text-gray-50 flex-[0_0_16px]' aria-hidden='true' />
          </div>
        </div>
        <div className='flex items-center'>
          <button className='ms-2 text-gray-50 hover:text-red-600' onClick={handleDeleteSlide} >
            <TrashIcon className='h-4 w-4' aria-hidden='true' />
          </button>
        </div>
      </div>
      {/* Mostramos el contenido del slide */}
    </li>
  )
}

export default SlideListItem
