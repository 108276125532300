import React from 'react'

function ItemThree() {
  return (
    <div className='p-6 bg-white shadow rounded-lg'>
      <h2 className='text-xl font-semibold mb-4'>Item Three Title</h2>
      <div className='grid grid-cols-2 gap-4'>
        <div className='bg-gray-200 p-4 rounded'>
          <h3 className='font-medium mb-2'>Section A</h3>
          <p className='text-sm text-gray-600'>Aliquam erat volutpat.</p>
        </div>
        <div className='bg-gray-200 p-4 rounded'>
          <h3 className='font-medium mb-2'>Section B</h3>
          <p className='text-sm text-gray-600'>Sed do eiusmod tempor incididunt ut labore.</p>
        </div>
      </div>
    </div>
  )
}

export default ItemThree
