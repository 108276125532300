import React from 'react'

import {
  ArrowRightIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/outline'
import { Dialog } from 'primereact/dialog'

const people = [
  {
    name: 'Albert Flores',
    role: 'Owner',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    name: 'Bessie Cooper',
    role: '',
    imageUrl:
      'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    name: 'Marvin McKinney',
    role: '',
    imageUrl:
      'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    name: 'Robert Fox',
    role: '',
    imageUrl:
      'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  }
  // More people...
]

function DialogShareTemplate({ visible, setVisible }) {

  const headerContent = (
    <>
      <h3 className='text-xl'>Share</h3>
      <p className='text-sm font-light'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </>
  )

  const footerContent = (
    <div className='flex flex-row-reverse'>
      <button className='flex items-center rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'>
        Next
        <ArrowRightIcon className='ml-1.5 h-5 w-5 text-white' aria-hidden='true' />
      </button>
      <button onClick={() => setVisible(false)} className='rounded-md bg-gray-200 px-3 py-2 text-sm hover:bg-gray-300'>
        Cancel
      </button>
    </div>
  )

  return (
    <Dialog visible={visible} style={{ 'max-width': '465px', width: '90vw' }} onHide={() => setVisible(false)} header={headerContent} footer={footerContent}>
      <div className='flex'>
        <label htmlFor='search-user' className='sr-only'>
          Search user
        </label>
        <input
          id='search-user'
          name='user'
          type='text'
          autoComplete='user'
          required
          className='min-w-0 flex-auto rounded-l-lg border-0 px-3.5 py-2 ring-1 ring-inset ring-gray-200 placeholder:text-gray-50 sm:text-sm sm:leading-6'
          placeholder='Search user'
        />
        <button
          type='button'
          className='flex-none rounded-r-lg bg-primary px-3.5 py-2.5 text-sm text-white hover:bg-blue-600 focus-visible:outline-blue-600'
        >
          Share
        </button>
      </div>
      <ul role='list' className='pt-4'>
        {people.map(person => (
          <li key={person.name} className='flex mb-2'>
            <div className='flex items-center gap-x-2'>
              <img className='h-7 w-7 rounded-full' src={person.imageUrl} alt='' />
              <div className='flex items-center'>
                <h3 className='text-sm font-semibold tracking-tight'>{person.name}</h3>
                {person.role ? <span className='text-sm text-primary ms-2'>{person.role}</span> : null}
              </div>
            </div>
            <div className='relative ms-auto'>
              <select className='form-select h-9 w-full rounded-lg border-0 bg-transparent bg-none p-0 pl-3.5 pr-[1.875rem] font-medium text-slate-900 sm:text-sm focus:ring-transparent'>
                <option value='html'>can view</option>
                <option value='react'>can edit</option>
              </select>
              <ChevronDownIcon className='h-full w-2.5 pointer-events-none absolute inset-y-0 right-3.5 stroke-slate-500' aria-hidden='true' />
            </div>
          </li>
        ))}
      </ul>
    </Dialog>
  )
}

export default DialogShareTemplate
