import React, { useEffect, useState } from 'react'

import clsx from 'clsx'
import { InputNumber } from 'primereact/inputnumber'

import { activeCardStyles } from './Step1NewTemplate'

const customCardStyles = 'rounded-md border border-solid shadow-3xl py-1.5 cursor-pointer flex items-center flex-1 self-stretch'
const inactiveCardStyles = 'border-white bg-white'

export default function FormatCardCustomOption({
  label,
  text,
  pickAFormat,
  isCustomActive, setIsCustomActive,
  icon,
  templateFormat,
}) {

  const [customX, setCustomX] = useState(0)
  const [customY, setCustomY] = useState(0)
  const [customFinalValue, setCustomFinalValue] = useState('0:0')

  function onChangeCustomValue() {
    pickAFormat(customFinalValue)
  }

  useEffect(() => {
    setCustomFinalValue(`${customX}:${customY}`)
  }, [customX, customY])

  useEffect(() => {
    if (templateFormat !== '16:9' && templateFormat !== '9:16' && templateFormat !== '4:3') {
      console.log(templateFormat)
      const [x, y] = templateFormat.split(':')

      setCustomX(x)
      setCustomY(y)
      setCustomFinalValue(templateFormat)
      setIsCustomActive(true)
    }

  }, [])

  return (
    <div onClick={() => {
      pickAFormat(customFinalValue)
      setIsCustomActive(true)
    }} className={clsx(isCustomActive ? activeCardStyles : inactiveCardStyles, customCardStyles)}>
      <div className='w-11 flex items-center justify-center'>{icon}</div>
      <div className=' w-full overflow-hidden ms-2 leading-none' >
        <span className='text-xs font-semibold'>{text}</span>
        <div className='flex items-center' >
          <InputNumber className='rounded-full' max={2000} min={0} useGrouping={false} value={customX} onChange={e => {
            setCustomX(e.value)
            onChangeCustomValue()
          }
          }/>
          <span className='mx-1 text-xs text-gray-50'>x</span>
          <InputNumber className='rounded-full' max={2000} min={0} useGrouping={false} value={customY} onChange={e => {
            setCustomY(e.value)
            onChangeCustomValue()
          }
          }/>
        </div>
      </div>
    </div>
  )
}
