export default function AvatarGroup({ className }) {

  return (
    <div className={`flex ${className}`}>
      <div className='flex items-center h-8 w-8 rounded-full overflow-hidden border-2 border-white'>
        <img
          className='object-cover w-full h-full bg-gray-50'
          src='https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
          alt=''
        />
      </div>
      <div className='-ms-2 flex items-center h-8 w-8 rounded-full overflow-hidden border-2 border-white'>
        <img
          className='object-cover w-full h-full bg-gray-50'
          src='https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80'
          alt=''
        />
      </div>
      <div className='-ms-2 flex items-center h-8 w-8 rounded-full overflow-hidden border-2 border-white'>
        <img
          className='object-cover w-full h-full bg-gray-50'
          src='https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=761&q=80'
          alt=''
        />
      </div>
    </div>
  )
}
