import React, { useState } from 'react'

import CardTemplateSelectContent from './CardTemplateSelectContent'

const StreamContentOption = ({
  templates,
  setLocalTemplate,
  accessCode,
  dialogStage,
  setDialogStage,
  DIALOG_STAGES,
  setSelectedTemplate,
}) => {
  const [showAll, setShowAll] = useState(false)

  // Determine the slice of templates to show
  const displayedTemplates = showAll ? templates : templates.slice(0, 4)

  // Toggle function to show more or less templates
  const toggleShowAll = () => setShowAll(!showAll)

  return (
    <div className='mt-2'>
      <p>My content</p>
      <div className='mt-4 grid grid-cols-1 gap-y-10 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-0 xl:grid-cols-3 2xl:grid-cols-4'>
        {displayedTemplates.map((template, index) =>
          <CardTemplateSelectContent key={template.id} template={template} index={index} accessCode={accessCode} dialogStage={dialogStage}
            setDialogStage={setDialogStage} DIALOG_STAGES={DIALOG_STAGES} setSelectedTemplate={setSelectedTemplate}/>
        )}
      </div>
      {/* Button to toggle showing more */}
      <button className='mt-4 text-blue-600 hover:underline' onClick={toggleShowAll}>
        {showAll ? 'Show Less' : 'Show More'}
      </button>
      <p>New</p>
    </div>
  )
}

export default StreamContentOption
