/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from 'react'

import { updateDoc, query, where, getDocs, collection, doc, getDoc } from 'firebase/firestore'
import { AutoComplete } from 'primereact/autocomplete'
import { Dialog } from 'primereact/dialog'
import { ProgressSpinner } from 'primereact/progressspinner'
import { resetServerContext } from 'react-beautiful-dnd'
import { useNavigate } from 'react-router-dom'

import PairScreenOption from './PairScreenOption'
import ScreenConfigOption from './ScreenConfigOption'
import ScreenConfirmationOption from './ScreenConfirmationOption'
import StreamContentOption from './StreamContentOption'
import { AuthContext } from '../../../AuthProvider'
import { useWorkspaceContext } from '../../../context/workspace/workspace-context'
import { db } from '../../../firebase'
import { handleInputChange, handleKeyDown, handlePaste } from '../../../utils/codeInputUtils'
import {
  fetchScreenByAccessCode,
  updateScreenUser,
  getWorkspaceName,
  fetchWorkspaceByName,
  fetchDefaultWorkspace,
  addNewWorkspace,
  updateScreenWorkspace,
  addScreenToWorkspace,
  getTemplatesByUser,
  handleSaveTemplate,
} from '../../../utils/firebaseUtils'

function DialogNewScreen({ visible, setVisible, setLocalTemplate }) {

  // consts for the dialog

  const [accessCode, setAccessCode] = useState(Array(6).fill(''))
  const [errorMessage, setErrorMessage] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [successMessage, setSuccessMessage] = useState('')
  const [isSuccessful, setIsSuccessful] = useState(false)
  const [workspacesArray, setWorkspacesArray] = useState([null])
  const [selectedWorkspace, setSelectedWorkspace] = useState('')
  const [tempWorkspace, setTempWorkspace] = useState({ name: '' })
  const [subCategoryName, setSubCategoryName] = useState('') // State for subcategory or screen name
  const [selectedWorkspaceName, setSelectedWorkpaceName] = useState('') // State for subcategory or screen name
  const [workspaceMessage, setWorkspaceMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isCodeCorrect, setIsCodeCorrect] = useState(false)
  const [staticWorkspaces, setStaticWorkspaces] = useState([])
  const [userTemplates, setUserTemplates] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [moveToStreamContent, setMoveToStreamContent] = useState(false)
  const { workspaces, addNewWorkspace, getAllUserWorkspaces } = useWorkspaceContext()
  const [templateUrl, setTemplateUrl] = useState('')

  const DIALOG_STAGES = {
    SELECT_CONTENT: 'SELECT_CONTENT',
    LOADING: 'LOADING',
    CONFIGURATION: 'CONFIGURATION',
    ERROR: 'ERROR',
    PAIR_SCREEN: 'PAIR_SCREEN',
    CONFIRMATION_SCREEN: 'CONFIRMATION_SCREEN',
  }
  const [dialogStage, setDialogStage] = useState(DIALOG_STAGES.PAIR_SCREEN)
  const { user } = useContext(AuthContext)
  const history = useNavigate()

  useEffect(() => {
    getAllUserWorkspaces(user.uid)
  }, [user])

  useEffect(() => {
    // Directly set the array with only the 'name' property from each workspace object
    setWorkspacesArray(workspaces.map(workspace => ({ name: workspace.name })))
  }, [workspaces])

  useEffect(() => {
    if (workspacesArray.length) {
      setStaticWorkspaces(getRandomWorkspaces())
    }
  }, [workspacesArray])

  function getRandomWorkspaces() {
    if (!workspacesArray.length) {
      console.error('workspacesArray is empty')

      return []
    }

    const defaultWorkspaceObj = workspacesArray.find(workspace => workspace?.name === 'Default Workspace')

    if (!defaultWorkspaceObj) {
      console.error('Default Workspace name not found')

      return []
    }

    let otherWorkspaces = workspacesArray.filter(workspace => workspace.name !== 'Default Workspace')

    // Shuffle only if we have more than 3 workspaces, else no need to shuffle
    if (otherWorkspaces.length > 3) {
      otherWorkspaces.sort(() => 0.5 - Math.random())
    }

    // Slice first three after shuffling
    otherWorkspaces = otherWorkspaces.slice(0, 3)

    // Join the default workspace and other selected workspaces
    return [defaultWorkspaceObj.name, ...otherWorkspaces.map(workspace => workspace.name)]
  }

  function resetDialog() {
    setVisible(false)
    setIsSuccessful(false) // Reset to false when dialog is closed
    setErrorMessage('')
    setSuccessMessage('')
    setAccessCode(Array(6).fill(''))
    setWorkspaceMessage('')
    setDialogStage(DIALOG_STAGES.PAIR_SCREEN)
    // Clear the input and autocomplete
    setSubCategoryName('') // Assuming you have a state setter like this
    setSelectedWorkspace('')
    setIsCodeCorrect(false)
    setTempWorkspace({ name: '' })// Resetting to an empty object
    setMoveToStreamContent(false)
  }
  // checks if screen is in db

  async function saveScreen() {

    setIsSuccessful(false)
    const codeAsString = accessCode.join('') // Convert array to string

    const screenDoc = await fetchScreenByAccessCode(codeAsString)

    if (screenDoc) {
      setIsCodeCorrect(true) // This line turns the inputs green only if accessCode is correct
      const isSuccess = await handleSuccessfulScreenFetch(screenDoc)

      setDialogStage(DIALOG_STAGES.LOADING)
      setTimeout(() => {
        if (isSuccess) {
          setSuccessMessage('New screen added!')
          setDialogStage(DIALOG_STAGES.CONFIGURATION)
          setErrorMessage(null)
        } else {
          setIsCodeCorrect(false) // Reset the inputs color
          setErrorMessage('There was an issue pairing your screen. Please try again.')
          setDialogStage(DIALOG_STAGES.PAIR_SCREEN)
        }
      }, 1000) // Add 1 second delay before showing success/error message
    } else {
      setIsCodeCorrect(false) // Reset the inputs color
      setErrorMessage('Invalid access code. Please try again.')
      setDialogStage(DIALOG_STAGES.PAIR_SCREEN)
    }
  }

  async function handleSuccessfulScreenFetch(screenDoc) {
    const screenId = screenDoc.id
    const userId = user.uid

    return await updateScreenUser(screenId, userId) // this will return true/false
  }

  const getWorkspaceName = workspace => {
    if (typeof workspace === 'string') return workspace
    if (workspace && workspace.name) return workspace.name
    if (typeof workspace === 'object') return workspace.toString()

    return ''
  }
  // buttons in dialog footer

  const footerContent = (
    <div className='flex justify-between items-center'>
      <p className='text-sm text-gray-600'>
        Are you having problems pairing your screens? More information
      </p>
      <div className='flex space-x-2'>
        <button
          onClick={async () => {
            if (dialogStage === DIALOG_STAGES.CONFIGURATION) {
              console.log('isSuccessful')
              const workspaceName = getWorkspaceName(tempWorkspace)

              setSelectedWorkspace(workspaceName)
              const finalSubCategoryName = subCategoryName?.trim() || accessCode.join('')

              setSubCategoryName(finalSubCategoryName)
              const isWorkspaceExisting = workspaceExists(workspaceName)
              const result = await saveScreenAndWorkspace(finalSubCategoryName, workspaceName, isWorkspaceExisting)

              if (result) {
                setDialogStage(DIALOG_STAGES.SELECT_CONTENT)
                console.log('dialog_stage', dialogStage)
              }
            } else if (dialogStage === DIALOG_STAGES.PAIR_SCREEN) {
              // Call saveScreen or any other relevant function for PAIR_SCREEN
              saveScreen()
            } else if (dialogStage === DIALOG_STAGES.CONFIRMATION_SCREEN) {
              // Attempt to save the template, then set the URL
              await handleSaveTemplate(true, accessCode, selectedTemplate)
              // setTemplateUrl(`/template-editor/${selectedTemplate.id}`)
              resetDialog()
            }
          }}
          className='rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
        >
          Confirm
        </button>
      </div>
    </div>
  )

  useEffect(() => {
    // This effect will run when `templateUrl` changes.
    // If `templateUrl` is not an empty string, navigate to the URL.
    if (templateUrl !== '') {
      console.log('templateUrl', templateUrl)
      history(templateUrl)
    }
  }, [templateUrl])

  const workspaceExists = workspace => {
    const workspaceName = getWorkspaceName(workspace)
    const exists = workspacesArray.some(w => String(w.name) === workspaceName)

    if (exists) setSelectedWorkspace(workspaceName)

    return exists
  }

  useEffect(() => {
    console.log(selectedWorkspace)
    console.log(subCategoryName)
  }, [selectedWorkspace, subCategoryName, selectedWorkspaceName])

  // set name

  const handleSubcategoryChange = e => {
    setSubCategoryName(e.target.value)
    console.log('subcategory_name', subCategoryName)
  }

  async function saveScreenAndWorkspace(subCategoryName, workspaceName, isWorkspaceExisting) {
    try {
      const screenDoc = await fetchScreenByAccessCode(accessCode.join(''))

      if (!screenDoc) throw new Error('Something went wrong. Please try again.')

      const screenId = screenDoc.id
      const screenData = screenDoc.data()

      if (screenData.workspaceId) {
        throw new Error(`The screen with the code ${accessCode.join('')} is already assigned to a workspace.`)
      }

      let targetWorkspaceId

      if (!workspaceName || typeof workspaceName === 'object' || workspaceName === '[object Object]') {
        const defaultWorkspace = await fetchDefaultWorkspace(workspaces)

        setSelectedWorkpaceName('Default Workspace')

        if (!defaultWorkspace) throw new Error('Default Workspace not found.')
        targetWorkspaceId = defaultWorkspace.id
      } else if (isWorkspaceExisting) {
        const existingWorkspace = await fetchWorkspaceByName(workspaceName, workspaces)

        if (!existingWorkspace) throw new Error('Workspace not found.')
        targetWorkspaceId = existingWorkspace.id
      } else {
        const userId = user.uid

        targetWorkspaceId = await addNewWorkspace(workspaceName, userId)
      }

      await updateScreenWorkspace(screenId, subCategoryName, targetWorkspaceId)
      const updatedScreenSnapshot = await fetchScreenByAccessCode(accessCode.join(''))
      const updatedScreen = updatedScreenSnapshot.data()

      await addScreenToWorkspace(targetWorkspaceId, updatedScreen)

      return true
    } catch (error) {
      setErrorMessage(`Failed to update the screen: ${error.message}`)

      return false
    }
  }

  useEffect(() => {
    const codeAsString = accessCode.join('')

    // Check if all characters are filled and are valid alphanumeric characters
    if (codeAsString.length === 6 && /^[a-zA-Z0-9]{6}$/.test(codeAsString)) {
      setTimeout(() => {
        saveScreen()
      }, 1000)
    }

  }, [accessCode])

  useEffect(() => {
    async function fetchData() {
      const templates = await getTemplatesByUser(user.uid)

      setUserTemplates(templates)
    }

    fetchData()
  }, [user.uid])

  return (
    <Dialog
      header={
        successMessage ? 'New screen configuration'
          : moveToStreamContent ? 'Stream Content' : 'Pair a new screen'
      }
      visible={visible}
      style={{ width: '40vw' }}
      onHide={resetDialog}
      footer={footerContent}
    >

      <div className=''>
        {dialogStage === DIALOG_STAGES.SELECT_CONTENT &&
        <StreamContentOption templates={userTemplates} accessCode={accessCode}
          dialogStage={dialogStage} setDialogStage={setDialogStage} DIALOG_STAGES={DIALOG_STAGES} setSelectedTemplate={setSelectedTemplate} />
        }
        {dialogStage === DIALOG_STAGES.CONFIRMATION_SCREEN &&
        <ScreenConfirmationOption
          subCategoryName={subCategoryName}
          selectedWorkspaceName={selectedWorkspaceName}/>
        }
        {/* Success message */}
        {dialogStage === DIALOG_STAGES.LOADING && (
          <div className='flex justify-center items-center'>
            <ProgressSpinner />
          </div>
        )}
        {dialogStage === DIALOG_STAGES.CONFIGURATION && (
          <ScreenConfigOption
            successMessage={successMessage}
            handleSubcategoryChange={handleSubcategoryChange}
            subCategoryName={subCategoryName}
            setSubCategoryName={setSubCategoryName}
            tempWorkspace={tempWorkspace}
            accessCode={accessCode}
            staticWorkspaces={staticWorkspaces}
            setTempWorkspace={setTempWorkspace}
            selectedWorkspace={selectedWorkspace}
            workspaceMessage={workspaceMessage}
            workspacesArray={workspacesArray}
          />
        )}

        {errorMessage && !isLoading && (
          <div className='mb-4 text-red-500'>
            {errorMessage}
          </div>
        )}
        {/* Code entry */}
        {!isSuccessful && dialogStage === DIALOG_STAGES.PAIR_SCREEN && (
          <PairScreenOption
            accessCode={accessCode}
            handleInputChange={handleInputChange}
            handleKeyDown={handleKeyDown}
            handlePaste={handlePaste}
            isCodeCorrect={isCodeCorrect}
            setAccessCode={setAccessCode}
          />
        )}
      </div>
    </Dialog>

  )
}
export default DialogNewScreen
