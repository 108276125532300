// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react'

import SocialsInputComponent from './socialsInputComponent'

function SocialsComponent({ addNewItem, closeMediaPanel }) {
  const [selectedPlatform, setSelectedPlatform] = useState(null)

  const PLATFORM_CONFIG = {
    youtube: {
      icon: '/assets/images/youtube.svg',
    },
    twitch: {
      icon: '/assets/images/twitch2.svg',
    },
    vimeo: {
      icon: '/assets/images/vimeo (2).svg',
    },
    dailymotion: {
      icon: '/assets/images/dailymotion2.svg',
    },
    // Add similar config for other platforms like tiktok
  }

  if (selectedPlatform) {
    return <SocialsInputComponent selectedPlatform={selectedPlatform} addNewItem={addNewItem} closeMediaPanel={closeMediaPanel}/>
  }

  return (
    <>
      <div className='relative mb-4 rounded-md'>
        <div className='platform-buttons mb-4 pb-2 overflow-x-scroll whitespace-nowrap' style={{ maxWidth: '100%' }}>
          {Object.keys(PLATFORM_CONFIG).map(platform => (
            <div
              key={platform}
              className='inline-block bg-gray-200 p-2 rounded-md cursor-pointer hover:opacity-80 mr-6'
              onClick={() => setSelectedPlatform(platform)}
            >
              <img
                src={PLATFORM_CONFIG[platform].icon}
                alt={platform}
                className='h-8 w-8'
              />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default SocialsComponent
