/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react'

import {
  PlusCircleIcon,
} from '@heroicons/react/24/outline'
import { doc, updateDoc } from 'firebase/firestore'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import { AuthContext } from '../AuthProvider'
import DialogSetTemplateToScreen from '../components/dialog/DialogSetTemplateToScreen'
import DialogShareTemplate from '../components/dialog/DialogShareTemplate'
import SelectSlideDesign from '../components/SelectSlideDesign'
import SlideMock from '../constants/SlideMock'
import TemplateMock from '../constants/TemplateMock'
import { db } from '../firebase'
import { LayoutEditor } from '../layout/LayoutEditor'
import {
  fetchTemplateFromFirestore,
  saveTemplateToFirestore,
  publishTemplateToScreenFirestore,
  fetchSlidesFromFirestore,
} from '../services/userService'
import DroppableCanvas from '../shared/canvas-frame/DroppableCanvas'
import SlideListItem from '../shared/SlideListItem'

function TemplateEditorPage() {
  // Estado para almacenar los slides
  const [slides, setSlides] = useState([])
  // const [template, setTemplate] = useState(TemplateMock)

  const [localTemplate, setLocalTemplate] = useState(TemplateMock)

  console.log(localTemplate)
  // Estado para almacenar el template localmente
  const [visibleDialogSetTemplateToScreen, setVisibleDialogSetTemplateToScreen] = useState(false)
  const [visibleDialogShareTemplate, setVisibleDialogShareTemplate] = useState(false)
  const [totalDuration, setTotalDuration] = useState(0)

  // Estado para controlar la visibilidad del sidebar que muestra las opciones para agregar un nuevo slide
  const [isSidebarAddSlideVisible, setIsSidebarAddSlideVisible] = useState(false)

  const toggleSidebar = () => {
    setIsSidebarAddSlideVisible(!isSidebarAddSlideVisible)
  }

  const startFromScratch = () => {
    // Aquí agregarías un nuevo slide en blanco
    addSlide()
    setIsSidebarAddSlideVisible(false)
  }

  // Mostramos modal para seleccionar una pantalla
  const showDialogSetTemplateToScreen = () => {
    setVisibleDialogSetTemplateToScreen(true)
  }

  // Mostramos modal para compartir el template
  const showDialogShareTemplate = () => {
    setVisibleDialogShareTemplate(true)
  }

  // Estado para almacenar el slide seleccionado
  const [selectedSlide, setSelectedSlide] = useState()

  // Obtenemos el parámetro 'id' de la URL
  const { id } = useParams()

  // Obtenemos el usuario actual del contexto
  const { user } = useContext(AuthContext)

  useEffect(() => {
    const fetchData = async () => {
      const templateData = await fetchTemplateFromFirestore(id, user.uid)

      if (templateData) {
        setLocalTemplate(templateData)
        setSlides(templateData.slides)
      }
    }

    fetchData()
  }, [id, user])

  useEffect(() => {
    fetchSlidesFromFirebase()
  }, [])

  const addSlide = () => {
    // Crear y seleccionar un nuevo slide
    const newSlide = {
      ...SlideMock,
      slideId: uuidv4(),
      creationTime: new Date(),
      updateTime: new Date(),
    }

    console.log(newSlide)
    console.log(newSlide.slideId)

    const newSlides = [...localTemplate.slides, newSlide]

    setLocalTemplate(prevTemplate => ({
      ...prevTemplate,
      slides: newSlides,
    }))

    setSelectedSlide(newSlide)
  }

  const handleSelectSlide = async newSlide => {
    // Guarda cualquier cambio no guardado en el slide actual
    if (selectedSlide) {
      const updatedSlides = localTemplate.slides.map(slide => {
        if (slide.slideId === selectedSlide.slideId) {
          return selectedSlide
        }

        return slide
      })

      setLocalTemplate(prevTemplate => ({
        ...prevTemplate,
        slides: updatedSlides,
      }))

      // Considera la posibilidad de guardar también en la base de datos
      // Asegúrate de manejar cualquier error que pueda surgir al guardar en la base de datos
      try {
        const templateRef = doc(db, 'templates', id)

        await updateDoc(templateRef, localTemplate)
      } catch (error) {
        console.error('Error guardando el template:', error)
      }
    }

    // Actualiza el slide seleccionado
    const updatedSlide = localTemplate.slides.find(s => s.slideId === newSlide.slideId) || newSlide

    setSelectedSlide(updatedSlide)
  }

  // Seguimiento de dnd
  const handleDragEnd = result => {
    if (!result.destination) {
      return
    }

    const newSlides = Array.from(localTemplate.slides)
    const [reorderedSlide] = newSlides.splice(result.source.index, 1)

    newSlides.splice(result.destination.index, 0, reorderedSlide)

    setLocalTemplate(prevTemplate => ({
      ...prevTemplate,
      slides: newSlides,
    }))
  }

  const addItemToSlide = item => {
    const updatedSlide = {
      ...selectedSlide,
      items: [...selectedSlide.items, item],
    }

    const updatedSlides = localTemplate.slides.map(slide => {
      if (slide.slideId === selectedSlide.slideId) {
        return updatedSlide
      }

      return slide
    })

    setLocalTemplate(prevTemplate => ({
      ...prevTemplate,
      slides: updatedSlides,
    }))

    setSelectedSlide(updatedSlide)
  }

  /* const deleteSlide = slideId => {
    const updatedSlides = slides.filter(slide => slide.slideId !== slideId)

    setSlides(updatedSlides)
    setLocalTemplate(prevTemplate => ({
      ...prevTemplate,
      slides: updatedSlides,
    }))

    // Si se borra la seleccionada, vaciar el estado selectedSlide
    if (selectedSlide && selectedSlide.slideId === slideId) {

      setSelectedSlide(null)
    } else {
      // Actualizamos selectedSlide solo si el slide seleccionado no fue el que se borró
      const newSelectedSlide = updatedSlides.find(slide => slide.slideId === selectedSlide.slideId)

      setSelectedSlide(newSelectedSlide)
    }

    // Actualizar duración total quitando la de la slide borrada
    const deletedSlide = slides.find(slide => slide.slideId === slideId)

    if (deletedSlide) {
      setTotalDuration(totalDuration - deletedSlide.config.settings.duration)
    }
  } */

  const deleteSlide = slideId => {
    const deletedSlide = slides.find(slide => slide.slideId === slideId)
    const updatedSlides = slides.filter(slide => slide.slideId !== slideId)

    console.log(deletedSlide.slideId)

    if (deletedSlide) {
      const deletedSlideIndex = slides.findIndex(slide => slide.slideId === slideId)

      console.log(deletedSlideIndex)

      if (deletedSlideIndex > 0) {
        setSelectedSlide(slides[deletedSlideIndex - 1])
      } else if (deletedSlideIndex === 0 && slides.length > 1) {
        setSelectedSlide(slides[deletedSlideIndex + 1])
      } else if (deletedSlideIndex === 0 && slides.length === 1) {
        setSelectedSlide(null)
      } else if (deletedSlideIndex === 0 && slides.length === 0) {
        setSelectedSlide(null)
      }

      setSlides(updatedSlides)
      setLocalTemplate(prevTemplate => ({
        ...prevTemplate,
        slides: updatedSlides,
      }))

      /*
      if (selectedSlide && selectedSlide.slideId === slideId) {
        let newSelectedSlide = null

        // Buscar el slide anterior o posterior
        const index = updatedSlides.findIndex(slide => slide.slideId === slideId)

        if (index !== -1) {
          // Si hay un slide anterior, seleccionarlo; de lo contrario, seleccionar el siguiente
          newSelectedSlide = updatedSlides[index - 1] || updatedSlides[index]
        }

        setSelectedSlide(newSelectedSlide)
      } */

      setTotalDuration(totalDuration - deletedSlide.config.settings.duration)
    }
  }

  // Guardas los slides del array
  const saveTemplate = async () => {
    setLocalTemplate(prevTemplate => ({
      ...prevTemplate,
      updateTime: new Date(),
    }))

    try {
      await saveTemplateToFirestore(id, localTemplate)
      console.log('Template guardado correctamente')
    } catch (error) {
      console.error('Error guardando el template:', error)
    }
  }

  // Publish changes of template to screen asociated in screensIdAttached
  const publishTemplatetoScreen = async () => {
    try {
      await publishTemplateToScreenFirestore(id, localTemplate)
      console.log('Template publicado en screen correctamente')
    } catch (error) {
      console.error('Error guardando el template:', error)
    }
  }

  const fetchSlidesFromFirebase = async () => {
    try {
      const fetchedSlides = await fetchSlidesFromFirestore(id)

      setSlides(fetchedSlides)
      setSelectedSlide(fetchedSlides[0])
    } catch (error) {
      console.error('Error cogiendo los slides:', error)
    }
  }

  const updateSlideTitle = newTitle => {
    console.log(newTitle)
    setLocalTemplate(prevSlide => ({
      ...prevSlide,
      config: {
        ...prevSlide.config,
        settings: {
          ...prevSlide.config.settings,
          title: newTitle,
        },
      },
    }))
  }

  return (
    <LayoutEditor localTemplate={localTemplate} showDialogSetTemplateToScreen={showDialogSetTemplateToScreen} saveTemplate={saveTemplate} publishTemplatetoScreen={publishTemplatetoScreen} showDialogShareTemplate={showDialogShareTemplate} setLocalTemplate={setLocalTemplate}>
      <DialogSetTemplateToScreen visible={visibleDialogSetTemplateToScreen} setVisible={setVisibleDialogSetTemplateToScreen} template={localTemplate} setLocalTemplate={setLocalTemplate} />
      <DialogShareTemplate visible={visibleDialogShareTemplate} setVisible={setVisibleDialogShareTemplate} />
      <DragDropContext >
        <div className='xl:pl-[400px] h-[--main-height]'>
          <div className='p-6 flex flex-col h-[--main-height]'>
            {selectedSlide && (
              <DroppableCanvas
                setSlides={setSlides}
                slideId={selectedSlide.slideId}
                selectedSlide={selectedSlide}
                setSelectedSlide={setSelectedSlide}
                addItem={addItemToSlide}
                updateSlideTitle={updateSlideTitle}
                setLocalTemplate={setLocalTemplate}
                localTemplate={localTemplate}
                deleteSlide={deleteSlide}
              />
            )}
          </div>
        </div>
      </DragDropContext>
      <aside className='fixed bottom-6 top-[88px] left-100 hidden w-[400px] overflow-y-auto border-r border-gray-20  xl:block'>
        <div className='px-6'>
          {isSidebarAddSlideVisible && (
            <SelectSlideDesign
              toggleSidebar={toggleSidebar}
              startFromScratch={startFromScratch}
            />
          )}
          {!isSidebarAddSlideVisible && (
            <div>
              <button
                onClick={toggleSidebar}
                type='button'
                className='inline-flex items-center rounded-md bg-primary px-3 py-2.5 w-full text-white hover:bg-blue-600 '
              >
                <PlusCircleIcon className='-ml-0.5 mr-1.5 h-6 w-6' aria-hidden='true' />
                Add new Slide
              </button>
              <div className='flex my-4'>
                <span className='h-5 flex items-center text-[10px] rounded-full bg-white px-2'>{localTemplate.slides?.length} slides</span>
                <span className='h-5 flex items-center text-[10px] rounded-full bg-white px-2 ms-auto'>
                  {`${localTemplate.slides.reduce((total, slide) => {
                    const slideDuration = slide.config.settings.duration instanceof Date ? 5000 : slide.config.settings.duration

                    return total + (typeof slideDuration === 'number' ? slideDuration : 0)
                  }, 0) / 1000}s`}
                </span>
              </div>
              <DragDropContext onDragEnd={handleDragEnd}>

                <Droppable droppableId='slides'>
                  {provided => (
                    <ul
                      role='list'
                      className='space-y-3'
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {localTemplate.slides &&
                      localTemplate.slides.map(
                        (slide, index) =>
                          slide && (
                            <Draggable
                              key={slide.slideId}
                              draggableId={slide.slideId}
                              index={index}
                            >
                              {provided => (
                                <SlideListItem
                                  reference={provided.innerRef}
                                  draggableProps={provided.draggableProps}
                                  dragHandleProps={provided.dragHandleProps}
                                  slide={slide}
                                  onSelect={handleSelectSlide}
                                  deleteSlide={deleteSlide}
                                  selectedSlide={selectedSlide}
                                  index={index}
                                />
                              )}
                            </Draggable>
                          )
                      )}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          )}
        </div>
      </aside>

    </LayoutEditor>
  )
}

export default TemplateEditorPage
