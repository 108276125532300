import React from 'react'

const ScreenConfirmationOption = ({

  subCategoryName,
  selectedWorkspaceName,
}) => {
  // Assuming the image URL is passed directly into the component.
  // If it's part of the templates or another prop, you'll need to get it from there.
  const imageUrl = 'https://s3-alpha-sig.figma.com/img/5eee/498a/fb40e5844b3c83e18e163598b54aefb7?Expires=1699228800&Signature=fBe18nG6jvn7KBqmR4zB726vH08hL7cEg~Tdh-U1Gsx~sM9rX03kZN2o~xnota6i7rdc5dSTbjwDl3OeYtddAHfVQSDQQHVJsROZsI52sAegxQ9clBH4cYvGJWxC~WvcjQEXPcL-7TAF7sHrtESwNwRSDlYoQa-M6loQht01gUSemKttyucNpz40YubHxR1LUf73QO7u~KBZSEQuxTwm~c9YbaY3jJK3wwWHUkcV5JUPhFaDzeKiYtQPoZSdxsegYfSKiLEFNIuPrmqui43xB1eH21UkCQAz4yxax6b7KuYABkBLU-dR~6HvV5Hp-G~xrhT0Cheug95WP6sFcmS0ww__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4'

  return (
    <div className='flex justify-evenly'>
      <div>
        <p>Workspace: #{selectedWorkspaceName}</p>
        <p>Name: {subCategoryName}</p>
      </div>
      <img src={imageUrl} alt='Marketing Proposal' style={{ maxWidth: '70%', height: '100%' }} />

    </div>
  )
}

export default ScreenConfirmationOption
