import { useContext, useState, useEffect } from 'react'

import { Dialog } from 'primereact/dialog'

import { AuthContext } from '../../AuthProvider'
import { useWorkspaceContext } from '../../context/workspace/workspace-context'

export default function DialogGlobalSettings({
  visible
  , setVisible,
  localTemplate,
  setLocalTemplate,
}) {

  const { workspaces, getAllUserWorkspaces } = useWorkspaceContext()
  const { user } = useContext(AuthContext)

  console.log(workspaces)
  const { config: { settings: { title } } } = localTemplate
  const [templateTitleValue, setTemplateTitleValue] = useState(title)
  const [selectedWorkspace, setSelectedWorkspace] = useState(null)

  console.log(title)

  function onSubmitChanges(e) {
    e.preventDefault()

    setLocalTemplate({
      ...localTemplate,
      config: {
        ...localTemplate.config,
        settings: {
          ...localTemplate.config.settings,
          title: templateTitleValue,
        },
      },
      workspaceId: selectedWorkspace.id,
    })

    setVisible(false)

  }

  useEffect(() => {
    getAllUserWorkspaces(user.uid)
  }, [user])

  useEffect(() => {
    setSelectedWorkspace(workspaces.find(workspace => workspace.id === localTemplate.workspaceId))
    setTemplateTitleValue(localTemplate.config.settings.title)
  }, [workspaces, localTemplate, user])

  return (
    <Dialog header='Template configuration' visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)} draggable={false} >
      <form onSubmit={onSubmitChanges} >
        <div className='grid gap-6 mb-6 md:grid-cols-2'>
          <div>
            <label htmlFor='templateName' className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>Edit your template name</label>
            <input type='text' id='templateName' className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' placeholder='' value={templateTitleValue} required

              onChange={e => setTemplateTitleValue(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor='addWorkspace' className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>Add to another workspace</label>
            <select
              value={selectedWorkspace?.id}
              onChange={e => setSelectedWorkspace(workspaces.find(workspace => workspace.id === e.target.value))}
              className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
            >
              {workspaces.map((workspace, index) => {
                return <option key={index} value={workspace.id}>{workspace.name}</option>
              })}
            </select>
          </div>
        </div>
        <button type='submit' className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'>Save</button>
      </form>
    </Dialog>
  )
}
