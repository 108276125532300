import { useContext } from 'react'

import { BrowserRouter as Router, Navigate, Route, Routes, Outlet } from 'react-router-dom'

import { AuthContext } from './AuthProvider'
import { FirebaseRequestProvider } from './hooks/useFirebaseRequestCount'
import DashboardPage from './pages/DashboardPage'
import LeadListPage from './pages/LeadListPage'
import LoginPage from './pages/LoginPage'
import PasswordResetPage from './pages/PasswordResetPage'
import PricingPage from './pages/PricingPage'
import RegisterPage from './pages/RegisterPage'
import TemplateEditorPage from './pages/TemplateEditorPage'
import TemplateListPage from './pages/TemplateListPage'
import TVListPage from './pages/TVListPage'
import UserProfilePage from './pages/UserProfilePage'
import './index.css'
import Workspace from './pages/Workspace'

function PrivateLayout() {
  const auth = useContext(AuthContext)

  if (!auth.user) {
    return <Navigate to='/login' replace />
  }

  return <Outlet />
}

function PublicLayout() {
  const auth = useContext(AuthContext)

  if (auth.user) {
    return <Navigate to='/' replace />
  }

  return <Outlet />
}

export default function AppRoutes() {
  return (
    <FirebaseRequestProvider>
      <Router>
        <Routes>
          <Route path='/' element={<PrivateLayout />}>
            <Route path='/' element={<DashboardPage />} />
            <Route path='/workspace/:idWorkspace' element={<Workspace />} />
            <Route path='/template-editor/:id' element={<TemplateEditorPage />} />
            <Route path='/tv-list' element={<TVListPage />} />
            <Route path='/user-profile' element={<UserProfilePage />} />
            <Route path='/template-list' element={<TemplateListPage />} />
            <Route path='/lead-list' element={<LeadListPage />} />
            <Route path='/pricing' element={<PricingPage />} />
            <Route path='*' element={<Navigate to='/' replace />} />
          </Route>
          <Route path='/' element={<PublicLayout />}>
            <Route path='/login' element={<LoginPage />} />
            <Route path='/register' element={<RegisterPage />} />
            <Route path='/reset-password' element={<PasswordResetPage />} />
          </Route>
        </Routes>
      </Router>
    </FirebaseRequestProvider>
  )
}
