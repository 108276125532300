// import moment from 'moment'

import AvatarGroup from './AvatarGroup'
import { useWorkspaceContext } from '../context/workspace/workspace-context'
export default function CardTemplate({ template, index }) {

  const { workspaces } = useWorkspaceContext()

  console.log('Log para verificar un posible loop', workspaces)
  const workspaceName = workspaces.find(workspace => workspace.id === template.workspaceId)

  // Recorremos el array template.slides y de cada uno cogemos el duration para sumarlos y pasar los milesegundos a minutos en una variable duration

  const duration = template.slides.reduce((acc, slide) => acc + slide.config.settings.duration, 0)

  const minutes = Math.floor(duration / 1000 / 60)
  const seconds = duration % 60
  const durationString = `${minutes}:${seconds}`

  // Convertir template.updateTime a formato fecha
  // const updateTime = moment(template.updateTime.seconds * 1000).fromNow()

  // console.log(updateTime)

  const imageSrc = 'https://images.unsplash.com/photo-1614849286521-4c58b2f0ff15?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2670&q=80'
  const imageAlt = 'Real State'

  // Genera la URL del enlace utilizando el template id
  const templateUrl = `/template-editor/${template.templateId}`

  return (
    <div key={index} className='group relative rounded-lg overflow-hidden bg-white'>
      <div className='aspect-video w-full group-hover:opacity-75 relative'>
        <div className='flex absolute py-3 px-4 inset-0'>
          <div className='flex'>
            <span className='h-5 flex items-center text-[10px] rounded-full bg-white me-1 px-2'>{template.slides.length} slides</span>
            <span className='h-5 flex items-center text-[10px] rounded-full bg-white px-2'>{durationString}</span>
          </div>
          {template.screensIdAttached.length > 0 ? (
            <div className='h-5 flex items-center text-[10px] rounded-full bg-white ps-2 pe-1 ms-auto'>
              <span className='bg-red-500 w-2 h-2 rounded-full me-1'></span>
              Live
              <span className='h-[14px] min-w-[14px] text-[8px] px-1 rounded-full bg-black ms-2 flex items-center justify-center text-white'>{template.screensIdAttached.length}</span>
            </div>
          ) : null}
        </div>
        <div className='absolute bottom-3 right-4'>
          <AvatarGroup />
        </div>
        <img
          src={imageSrc}
          alt={imageAlt}
          className='h-full w-full object-cover object-center'
        />
      </div>
      <div className='p-4 flex'>
        <div className='flex-1'>
          <h3 className='text-sm font-semibold'>
            <a href={templateUrl}>
              <span className='absolute inset-0' />
              {template.config.settings.title}
            </a>
          </h3>
          <p className='text-sm text-gray-50'>
            <span className='me-1'>In </span>
            <a href='#' className='text-sm text-primary hover:text-blue-600'> #{workspaceName?.name ? workspaceName.name : 'Default workspace'} </a>
            <span className='mx-1.5'>.</span>
            {/* <span>Updated {updateTime}</span> */}
          </p>
        </div>
      </div>
    </div>
  )
}
