// import moment from 'moment'

import React, { useState } from 'react'

import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { useNavigate } from 'react-router-dom'

import { useWorkspaceContext } from '../../../context/workspace/workspace-context'
import { handleSaveTemplate } from '../../../utils/firebaseUtils'

export default function CardTemplateSelectContent({ template, index, dialogStage, accessCode, setDialogStage, DIALOG_STAGES, setSelectedTemplate }) {

  console.log('accessCode', accessCode)
  const history = useNavigate()
  const { workspaces } = useWorkspaceContext()
  const [dialogVisible, setDialogVisible] = useState(false)

  console.log('Log para verificar un posible loop', workspaces)

  // Convertir template.updateTime a formato fecha
  // const updateTime = moment(template.updateTime.seconds * 1000).fromNow()

  // console.log(updateTime)

  // eslint-disable-next-line no-unused-vars
  const imageSrc = 'https://images.unsplash.com/photo-1614849286521-4c58b2f0ff15?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2670&q=80'
  const imageAlt = 'Real State'

  const themes = [
    {
      imageUrl: 'https://s3-alpha-sig.figma.com/img/5eee/498a/fb40e5844b3c83e18e163598b54aefb7?Expires=1699228800&Signature=fBe18nG6jvn7KBqmR4zB726vH08hL7cEg~Tdh-U1Gsx~sM9rX03kZN2o~xnota6i7rdc5dSTbjwDl3OeYtddAHfVQSDQQHVJsROZsI52sAegxQ9clBH4cYvGJWxC~WvcjQEXPcL-7TAF7sHrtESwNwRSDlYoQa-M6loQht01gUSemKttyucNpz40YubHxR1LUf73QO7u~KBZSEQuxTwm~c9YbaY3jJK3wwWHUkcV5JUPhFaDzeKiYtQPoZSdxsegYfSKiLEFNIuPrmqui43xB1eH21UkCQAz4yxax6b7KuYABkBLU-dR~6HvV5Hp-G~xrhT0Cheug95WP6sFcmS0ww__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
      name: 'Theme 1',
      author: 'Author 1',
    },
    {
      imageUrl: 'https://s3-alpha-sig.figma.com/img/a320/80e2/3f40551415dd93c9e316f2cc19ab218a?Expires=1699228800&Signature=mAcxBCx2Qpfo27Ec49a2HMqm3QCjk4VV4yEC~BAmq8TAlQhcnzUYe4FQyBfVfZ80~N7t6AiDySA7jz9b52Xg2MZeZo28IYvS9JJll8GBZx0r8nk4lHnxtM1vUxu7OW8Z87gfBqRpaCyuU4USkjgxsK42QqxL6nAf~qfCEqduiLmT2dcc6Oyi-mii5QFkT5v8XtHzw9vKdTAcgecigVYXUP47mkLjjwIJddO2E7MaP8MXCtTaSVrYMikP9tkmXmkBo5MP4w6Ssnk8Nfv2W-GH-wMu1CCWTaDfg3daKwGEMC7SlRQhp79nINurw7U5kopMJdKfLGRmtE6FXyp~Six9FA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
      name: 'Theme 2',
      author: 'Author 2',
    },
    {
      imageUrl: 'https://s3-alpha-sig.figma.com/img/a320/80e2/3f40551415dd93c9e316f2cc19ab218a?Expires=1699228800&Signature=mAcxBCx2Qpfo27Ec49a2HMqm3QCjk4VV4yEC~BAmq8TAlQhcnzUYe4FQyBfVfZ80~N7t6AiDySA7jz9b52Xg2MZeZo28IYvS9JJll8GBZx0r8nk4lHnxtM1vUxu7OW8Z87gfBqRpaCyuU4USkjgxsK42QqxL6nAf~qfCEqduiLmT2dcc6Oyi-mii5QFkT5v8XtHzw9vKdTAcgecigVYXUP47mkLjjwIJddO2E7MaP8MXCtTaSVrYMikP9tkmXmkBo5MP4w6Ssnk8Nfv2W-GH-wMu1CCWTaDfg3daKwGEMC7SlRQhp79nINurw7U5kopMJdKfLGRmtE6FXyp~Six9FA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
      name: 'Theme 3',
      author: 'Author 3',
    },
    {
      imageUrl: 'https://s3-alpha-sig.figma.com/img/a320/80e2/3f40551415dd93c9e316f2cc19ab218a?Expires=1699228800&Signature=mAcxBCx2Qpfo27Ec49a2HMqm3QCjk4VV4yEC~BAmq8TAlQhcnzUYe4FQyBfVfZ80~N7t6AiDySA7jz9b52Xg2MZeZo28IYvS9JJll8GBZx0r8nk4lHnxtM1vUxu7OW8Z87gfBqRpaCyuU4USkjgxsK42QqxL6nAf~qfCEqduiLmT2dcc6Oyi-mii5QFkT5v8XtHzw9vKdTAcgecigVYXUP47mkLjjwIJddO2E7MaP8MXCtTaSVrYMikP9tkmXmkBo5MP4w6Ssnk8Nfv2W-GH-wMu1CCWTaDfg3daKwGEMC7SlRQhp79nINurw7U5kopMJdKfLGRmtE6FXyp~Six9FA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
      name: 'Theme 4',
      author: 'Author 4',
    }
  ]

  // Genera la URL del enlace utilizando el template id
  const templateUrl = `/template-editor/${template.templateId}`

  console.log('dialog_stages', DIALOG_STAGES)

  const onCardClick = () => {
    if (dialogStage === DIALOG_STAGES.SELECT_CONTENT) {
      setDialogVisible(true)
      setSelectedTemplate(template)
    }
  }

  const dialogFooter = (
    <div className='flex justify-center items-center'>
      <Button
        label='Edit'
        icon='pi pi-pencil'
        onClick={async () => {
          await handleSaveTemplate(false, accessCode, template) // Pass false to only link without publishing
          history(templateUrl)
        }}
        className='p-button-rounded p-button-info mr-2 text-white'
      />
      <span className='mx-2 text-gray-600'>or</span>
      <Button
        label='Publish'
        icon='pi pi-upload'
        onClick={async () => {
          setDialogStage(DIALOG_STAGES.CONFIRMATION_SCREEN) // Pass false to only link without publishing
        }}
        className='p-button-rounded p-button-info ml-2 text-white'
      />
    </div>
  )

  if (dialogStage === DIALOG_STAGES.SELECT_CONTENT) {
    // When it is in Dialog
    return (
      <><div key={index} className='group relative rounded-lg overflow-hidden mb-5' onClick={onCardClick}>
        <div className='aspect-video w-full group-hover:opacity-75 relative'>
          {/* Remove the inner div if it's not being used */}
          <img
            src={themes[index % themes.length].imageUrl} // This will loop back to the start of the themes array
            alt={imageAlt}
            className='h-full w-full object-cover object-center' />
        </div>
        {/* Title block is outside and immediately follows the image div */}
        <div className='p-4'>
          <h3 className='text-sm font-semibold'>
            <a href={templateUrl} className='text-gray-900 hover:text-blue-600'> {/* Adjust the text color as needed */}
              {template.config.settings.title}
            </a>
          </h3>
        </div>
      </div><Dialog visible={dialogVisible} onHide={() => setDialogVisible(false)} footer={dialogFooter} header='Edit or Publish Content'>
        <p>Would you like to edit or publish your content for the new screen?</p>
      </Dialog></>

    )

  }
}
