// utils.js

/**
 * Handle input change for a multi-part code input.
 *
 * @param {Event} e - The input event object.
 * @param {number} idx - The index of the input element.
 * @param {Array} accessCode - The current access code state.
 * @param {Function} setAccessCode - Setter function for accessCode state.
 * @returns {void}
 */
export function handleInputChange(e, idx, accessCode, setAccessCode) {
  const newCode = [...accessCode]

  if (e.nativeEvent.inputType === 'deleteContentBackward') {
    newCode[idx] = ''
    setAccessCode(newCode)
    if (idx > 0) {
      e.target.previousSibling.focus()
      e.target.previousSibling.setSelectionRange(1, 1) // Set cursor to the right
    }
  } else {
    newCode[idx] = e.target.value
    setAccessCode(newCode)
    if (e.target.nextSibling) {
      e.target.nextSibling.focus()
    }
  }
}

/**
   * Handle key down events for a multi-part code input.
   *
   * @param {Event} e - The keydown event object.
   * @param {number} idx - The index of the input element.
   * @param {Array} accessCode - The current access code state.
   * @param {Function} setAccessCode - Setter function for accessCode state.
   * @returns {void}
   */
export function handleKeyDown(e, idx, accessCode, setAccessCode) {
  if (e.key === 'ArrowRight' && e.target.nextSibling) {
    e.preventDefault()
    e.target.nextSibling.focus()
    e.target.nextSibling.setSelectionRange(0, 0) // Set cursor to the left
  } else if (e.key === 'ArrowLeft' && e.target.previousSibling) {
    e.preventDefault()
    e.target.previousSibling.focus()
    e.target.previousSibling.setSelectionRange(1, 1) // Set cursor to the right
  } else if (e.key === 'Backspace' && idx > 0 && !e.target.value) {
    e.preventDefault()
    e.target.previousSibling.focus()
    e.target.previousSibling.setSelectionRange(1, 1) // Set cursor to the right
  } else if (e.key.length === 1 && /[a-zA-Z0-9]/.test(e.key)) {
    e.preventDefault()
    const newCode = [...accessCode]

    newCode[idx] = e.key
    setAccessCode(newCode)
    if (e.target.nextSibling) {
      e.target.nextSibling.focus()
    }
  }
}

/**
   * Handle paste event for a multi-part code input.
   *
   * @param {Event} e - The paste event object.
   * @param {Function} setAccessCode - Setter function for accessCode state.
   * @returns {void}
   */
export function handlePaste(e, setAccessCode) {
  e.preventDefault()
  const pastedData = e.clipboardData.getData('text/plain')

  if (pastedData.length <= 6) {
    const pastedArray = [...pastedData]
    const updatedAccessCode = [...pastedArray, ...Array(6 - pastedData.length).fill('')]

    setAccessCode(updatedAccessCode)
  }
}
