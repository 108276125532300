import React, { useState } from 'react'

import { MagnifyingGlassIcon, ArrowLeftIcon } from '@heroicons/react/24/outline'

import EmbedComponent from './embedComponent'

function EmbedInputComponent({ addNewItem, selectedOption, closeMediaPanel }) {
  const [content, setContent] = useState('')

  function isValidEmbedForPlatform(embed) {
    let pattern

    if (selectedOption === 'codeEmbed') {
      pattern = /<iframe.*?src="https:\/\/www.youtube.com\/embed\/.*?".*?<\/iframe>/i
    } else if (selectedOption === 'globeURL') {
      pattern = /^(http|https):\/\/[^ "]+$/i
    } else {
      return false // Return false or throw an error if selectedOption is not one of the expected values
    }

    return pattern.test(embed)
  }

  function handleAddClick() {
    if (!isValidEmbedForPlatform(content)) {
      alert('Invalid or mismatched embed code.')

      return
    }

    if (selectedOption === 'codeEmbed') {
      addNewItem({
        type: 'embed',
        embedCode: content,
      })
    } else if (selectedOption === 'globeURL') {
      addNewItem({
        type: 'urlWebsite',
        url: content,
      })
    }

    closeMediaPanel()

  }

  const [showEmbedComponent, setShowEmbedComponent] = useState(false)

  if (showEmbedComponent) {
    return <EmbedComponent addNewItem={addNewItem} />
  }

  let message

  if (selectedOption === 'codeEmbed') {
    message = 'You have selected to insert an iframe. Please embed the iframe below.'
  } else if (selectedOption === 'globeURL') {
    message = 'You have selected to insert a website URL. Please paste the URL below.'
  }

  return (
    <>
      <div className='relative mb-4 rounded-md'>
        <button
          className='inline-flex items-center justify-center text-xs text-gray-500 mb-4 hover:text-black'
          onClick={() => setShowEmbedComponent(true)}
        >
          <ArrowLeftIcon className='mr-1.5 h-4 w-4' aria-hidden='true' />
          Return to Embed
        </button>

        {message && (
          <div className='mb-4 text-sm'>
            {message}
          </div>
        )}

        {/* Wrapper div */}
        <div className='relative mb-4'>
          {/* Adjust positioning as needed */}
          <div className='absolute top-3 left-3'>
            <MagnifyingGlassIcon className='h-5 w-5 text-gray-50' aria-hidden='true' />
          </div>
          <textarea
            className='block w-full pt-3 pb-1 pl-12 text-sm rounded-md placeholder:text-gray-500 border-0 ring-1 ring-inset ring-gray-50 focus:ring-inset focus:ring-primary'
            placeholder='Paste your content here'
            value={content}
            onChange={e => setContent(e.target.value)}
          ></textarea>
        </div>
        <div className='flex'>
          <button
            className='ml-auto rounded-md bg-primary px-8 py-2 text-sm leading-6 text-white hover:bg-blue-600'
            onClick={handleAddClick}
          >
            Add
          </button>
        </div>
      </div>
    </>
  )
}

export default EmbedInputComponent
