import React, { useState } from 'react'

import DialogAICreateSlide from '../components/dialog/DialogAICreateSlide'

function SelectSlideDesign({ toggleSidebar, startFromScratch }) {

  // Estado para controlar la visibilidad del sidebar que muestra las opciones para agregar un nuevo slide

  const [isDialogAISlide, setIsDialogAISlide] = useState(false)

  const useAiToCreateSlide = () => {
    setIsDialogAISlide(true)
  }

  return (
    <div className='frame'>
      <DialogAICreateSlide visible={isDialogAISlide} setVisible={setIsDialogAISlide} />
      <div className='frame-wrapper'>
        <div className='div'>
          <div className='div-2' onClick={toggleSidebar}>
            <div className='div-3'>
              <div className='div-wrapper'>
                <div className='text-wrapper'>Choose a layout</div>
              </div>
            </div>
            <div className='close-wrapper'>
              <svg
                className={'close-instance'}
                fill='none'
                height='16'
                viewBox='0 0 16 16'
                width='16'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  className='path'
                  d='M12.6123 12.6118L3.38821 3.3877'
                  stroke='#B1B8CB'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  className='path'
                  d='M3.38821 12.6118L12.6123 3.3877'
                  stroke='#B1B8CB'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
          </div>
          <div className='frame-wrapper-2'>
            <div className='div-4'>
              <div className='card-layout'>
                <div className='master-card-layout'>
                  <div className='master-card' onClick={useAiToCreateSlide}>
                    <img className='group' alt='Group' src='https://c.animaapp.com/sobApbfM/img/group@2x.png' />
                    <div className='text-wrapper-2'>Use AI</div>
                  </div>
                  <div className='div-5'>
                    <div className='div-3'>
                      <div className='text-wrapper-3'>Use AI</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card-layout'>
                <div className='master-card-layout'>
                  <div className='master-card' onClick={startFromScratch}>
                    <svg
                      className={'plus-circle'}
                      fill='none'
                      height='25'
                      viewBox='0 0 25 25'
                      width='25'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        className='path'
                        clipRule='evenodd'
                        d='M12.5 3.25C17.608 3.25 21.75 7.391 21.75 12.5C21.75 17.608 17.608 21.75 12.5 21.75C7.391 21.75 3.25 17.608 3.25 12.5C3.25 7.392 7.392 3.25 12.5 3.25Z'
                        fillRule='evenodd'
                        stroke='#B1B8CB'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        className='path'
                        d='M12.4996 8.82715V16.1535'
                        stroke='#B1B8CB'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        className='path'
                        d='M16.1663 12.4904H8.83301'
                        stroke='#B1B8CB'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                    <div className='text-wrapper-4'>Start from scratch</div>
                  </div>
                  <div className='div-5'>
                    <div className='div-3'>
                      <div className='text-wrapper-3'>Blank Slide</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectSlideDesign
