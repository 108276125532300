import axios from 'axios'

//   googleFontApiKey:'AIzaSyDb3Na6t07e_yUxwOgQKHMcjHoimPEWj1w',
//   unsplashSecretKey: '0I7ohM0b6Cjc8U4d8tePLxwTRi2maZIS0hx6ud3C24Q',

// Reemplaza con tus credenciales
const PEXELS_API_KEY = '563492ad6f917000010000016f8661497e8a44a4b86e72f7ddffbe62'
const UNSPLASH_ACCESS_KEY = 'gvMR7kYPejScHZshk4gymqBgIx5ZEfEU7sN-mpSFrwg'
const BASE_API_URL = 'https://api.inapp.io' // Asegúrate de reemplazar esto con el dominio de tu API

const pexelsHttpOptions = {
  headers: {
    Authorization: PEXELS_API_KEY,
  },
}

const unsplashHttpOptions = {
  headers: {
    Authorization: `Client-ID ${UNSPLASH_ACCESS_KEY}`,
  },
}

export const getUnsplashImages = (keyword, page) => {
  const url = keyword
    ? `https://api.unsplash.com/search/photos?page=${page}&per_page=50&query=${keyword}`
    : `https://api.unsplash.com/photos/random?page=${page}&count=50&query`

  return axios.get(url, unsplashHttpOptions)
}

export const getPexelsImages = (keyword, page) => {
  const url = keyword
    ? `https://api.pexels.com/v1/search?query=${keyword}&page=${page}&per_page=50`
    : `https://api.pexels.com/v1/curated?page=${page}&per_page=50`

  return axios.get(url, pexelsHttpOptions)
}

export const getPexelsVideos = (keyword, page) => {
  const url = keyword
    ? `https://api.pexels.com/videos/search?query=${keyword}&page=${page}&per_page=50`
    : `https://api.pexels.com/videos/popular?page=${page}&per_page=50`

  return axios.get(url, pexelsHttpOptions)
}

// Método para obtener leads
export const getUserLeads = (userId, token) => {
  const url = `${BASE_API_URL}/leads/${userId}`
  const headers = {
    Authorization: `Bearer ${token}`,
  }

  return axios.get(url, { headers })
}
