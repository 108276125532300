import React, { useRef } from 'react'

import {
  Square3Stack3DIcon,
  TrashIcon,
  EyeIcon,
  EyeSlashIcon,
  Bars3Icon,
  CodeBracketIcon,
  PhotoIcon,
} from '@heroicons/react/24/outline'
import { OverlayPanel } from 'primereact/overlaypanel'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

function LayerTool({ setLocalTemplate, localTemplate, slideId }) {
  // OverlayPanel / Menús desplegables de la barra de herramientas
  const layersPanel = useRef(null)

  const slide = localTemplate.slides.find(slide => slide.slideId === slideId)

  // Comprueba que slide no sea indefinido
  if (!slide) {
    console.error(`No se encontró el slide con ID: ${slideId}`)

    return null
  }

  const { items: layers } = slide

  const toggleLayerVisibility = layerId => {
    const newLayers = layers.map(layer =>
      layer.elementId === layerId
        ? { ...layer, visible: !layer.visible }
        : layer
    )

    setLocalTemplate({
      ...localTemplate,
      slides: localTemplate.slides.map(slideItem => {
        if (slideItem.slideId === slideId) {
          return { ...slideItem, items: newLayers }
        }

        return slideItem
      }),
    })
  }

  const deleteLayer = layerId => {
    const newLayers = layers.filter(layer => layer.elementId !== layerId)

    setLocalTemplate({
      ...localTemplate,
      slides: localTemplate.slides.map(slideItem => {
        if (slideItem.slideId === slideId) {
          return { ...slideItem, items: newLayers }
        }

        return slideItem
      }),
    })
  }

  // truncate layer.config.text string to 20 characters and add ellipsis
  // const truncate = (str, n) => str.length > n ? str.substr(0, n - 1) + '...' : str

  const handleDragEnd = result => {
    if (!result.destination) return

    setLocalTemplate(prev => {
      // Directly create newLayers within the functional update.
      const newLayers = Array.from(prev.slides.find(s => s.slideId === slideId).items)
      const [reorderedLayer] = newLayers.splice(result.source.index, 1)

      newLayers.splice(result.destination.index, 0, reorderedLayer)

      const newSlides = prev.slides.map(slide => {
        if (slide.slideId === slideId) {
          return { ...slide, items: newLayers }
        }

        return slide
      })

      return { ...prev, slides: newSlides }
    })
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <button
        className='flex flex-col items-center px-2 hover:text-primary'
        onClick={e => layersPanel.current.toggle(e)}
        aria-haspopup
        aria-controls='overlay_panel'
      >
        <Square3Stack3DIcon className='h-5 w-5 mb-1' aria-hidden='true' />
        <span className='text-[10px] leading-none'>Layers</span>
      </button>
      <OverlayPanel
        ref={layersPanel}
        showCloseIcon
        id='overlay_panel'
        style={{ width: '300px' }}
        className='overlaypanel-demo'
      >
        <div className='px-6 py-3 border-b border-gray-200 text-sm font-semibold'>Layers</div>
        <div className='px-6 py-4'>
          <Droppable droppableId='layers'>
            {provided => (
              <ul {...provided.droppableProps} ref={provided.innerRef}>
                {layers.map((layer, index) => (
                  <Draggable key={layer.elementId} draggableId={String(layer.elementId)} index={index}>
                    {provided => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className='flex items-center mb-1'
                      >
                        <button onClick={() => toggleLayerVisibility(layer.elementId)}
                          className='pe-2 text-gray-50 hover:text-gray-600'>
                          {layer.visible
                            ? <EyeIcon className='h-4 w-4 text-gray-500' aria-hidden='true' />
                            : <EyeSlashIcon className='h-4 w-4 text-gray-500' aria-hidden='true' />
                          }
                        </button>
                        <div className={`grow flex items-center p-1 bg-gray-10 rounded-lg min-w-[1px] ${layer.visible ? 'opacity-100' : 'opacity-50'}`}>
                          <div className='h-6 w-12 rounded text-white bg-primary flex items-center justify-center text-xl font-serif leading-none me-2 flex-[0_0_48px]'>
                            {layer.type === 'text' ? 'T' : layer.type === 'code' ? <CodeBracketIcon className='h-5 w-5' aria-hidden='true' /> : layer.type === 'media' ? <PhotoIcon className='h-5 w-5' aria-hidden='true' /> : null}
                          </div>
                          {/* <span className='text-sm font-semibold truncate me-2'>{truncate(layer.config.text, 10)}</span> */}
                          <span className='text-xs grow font-semibold truncate me-2'>{layer.elementId}</span>
                          <Bars3Icon className='h-4 w-4 ms-auto text-gray-50 flex-[0_0_16px]' aria-hidden='true' />
                        </div>
                        <button className='ps-2 text-gray-50 hover:text-red-600' onClick={() => deleteLayer(layer.elementId)}>
                          <TrashIcon className='h-4 w-4' aria-hidden='true' />
                        </button>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </div>
      </OverlayPanel>
    </DragDropContext>
  )
}

export default LayerTool
