import React from 'react'

import ReactPlayer from 'react-player'

const SocialsCanvasItem = ({ item }) => {
  return (
    <div
      className='overflow-hidden h-full w-full'
    ><ReactPlayer url={item.src} muted={true} loop={true} playing={true}/></div>
  )
}

export default SocialsCanvasItem
