import { Fragment } from 'react'

import { Menu, Transition } from '@headlessui/react'
import {
  Bars3Icon,
} from '@heroicons/react/24/outline'
import { signOut } from 'firebase/auth'
import { useNavigate, Link } from 'react-router-dom'

import logo from '../assets/images/logo.svg'
import { auth } from '../firebase'

const userNavigation = [
  { name: 'Your profile', href: '/user-profile' },
  { name: 'Pricing', href: '/pricing' }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Header = ({ setSidebarOpen, announcementVisible }) => {

  const navigate = useNavigate()

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        // Redirect the user to the login page after sign out
        navigate('/login')
      })
      .catch(error => {
        // Handle errors here
        console.log(error)
      })
  }

  return (
    <header className={`sticky z-50 ${announcementVisible ? 'top-[44px]' : 'top-0'}`}>
      <div className='flex h-16 shrink-0 items-center gap-x-4 bg-white px-4 sm:gap-x-6 sm:px-6 lg:px-8'>
        <button type='button' className='-m-2.5 p-2.5 text-gray-700 lg:hidden' onClick={() => setSidebarOpen(true)}>
          <span className='sr-only'>Open sidebar</span>
          <Bars3Icon className='h-6 w-6' aria-hidden='true' />
        </button>

        {/* Separator */}
        <div className='h-6 w-px bg-gray-200 lg:hidden' aria-hidden='true' />

        <div className='flex flex-1 justify-between gap-x-4 lg:gap-x-6'>
          <div className='flex items-center'>
            <Link to='/dashboard' className='flex font-semibold text-indigo-600 hover:text-indigo-500'>
              <img
                className='w-[90px]'
                src={logo}
                alt='InApp Logo'
              />
            </Link>
            <span className='ml-4 whitespace-nowrap rounded-full bg-blue-20 px-2 py-0.5 text-xs leading-6'>Starter</span>
          </div>

          <div className='flex items-center gap-x-4 lg:gap-x-6'>
            {/* <button type="button" className="-m-2.5 p-2.5 text-gray-50 hover:text-gray-500">
              <span className="sr-only">View notifications</span>
              <BellIcon className="h-6 w-6" aria-hidden="true" />
            </button> */}

            {/* Separator */}
            {/* <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" aria-hidden="true" /> */}

            {/* Profile dropdown */}
            <Menu as='div' className='relative'>
              <Menu.Button className='-m-1.5 flex items-center p-1.5'>
                <span className='sr-only'>Open user menu</span>
                <img
                  className='h-8 w-8 rounded-full bg-gray-50'
                  src='https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
                  alt=''
                />

              </Menu.Button>
              <Transition
                as={Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
              >
                <Menu.Items className='absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none'>
                  {userNavigation.map(item => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <a
                          href={item.href}
                          className={classNames(
                            active ? 'bg-gray-10' : '',
                            'block px-3 py-1 text-sm leading-6 text-gray-900 w-full text-left'
                          )}
                        >
                          {item.name}
                        </a>
                      )}
                    </Menu.Item>
                  ))}
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={handleSignOut}
                        className={classNames(
                          active ? 'bg-gray-10' : '',
                          'block px-3 py-1 text-sm leading-6 text-red-600 w-full text-left'
                        )}
                      >
                        Sign out
                      </button>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
