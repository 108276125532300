import { v4 as uuidv4 } from 'uuid'

const SlideMock = {
  version: 1, // Versión del objeto
  config: {
    integrations: [],
    settings: {
      theme: {
        id: 0,
        name: 'Default',
        fontFamily: 'Arial',
        themeColor: ['#000000', '#ffffff', '#000000', '#ffffff'],
      },
      title: 'Slide Title',
      duration: 5000,
      autoDuration: false,
      visibility: false,
      schedule: [
        {
          id: 0,
          value: '00:00',
          active: true,
        },
        {
          id: 1,
          value: '23:59',
          active: true,
        }
      ],
      daysActive: [
        {
          id: 0,
          value: 'L',
          active: true,
        },
        {
          id: 1,
          value: 'M',
          active: true,
        },
        {
          id: 2,
          value: 'X',
          active: true,
        },
        {
          id: 3,
          value: 'J',
          active: true,
        },
        {
          id: 4,
          value: 'V',
          active: true,
        },
        {
          id: 5,
          value: 'S',
          active: false,
        },
        {
          id: 6,
          value: 'D',
          active: false,
        }
      ],
      hours: ['00:00', '23:59'],
      leads: {
        enabled: false,
        type: 'url',
        settings: {
          url: null,
          urlQr: null,
          form: {
            inputs: [],
            formHandler: null,
          },
        },
      },
      type: null,
    },
  },
  slideId: uuidv4(),
  creationTime: new Date(),
  updateTime: new Date(),
  items: [],
}

export default SlideMock
