import React, { useState, useEffect, useRef } from 'react'

import {
  CalendarDaysIcon,
  ClockIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline'
import { OverlayPanel } from 'primereact/overlaypanel'

// Esta función debe estar fuera de tu componente
const millisecondsToTime = milliseconds => {
  const totalSeconds = Math.floor(milliseconds / 1000)
  const minutes = Math.floor(totalSeconds / 60)
  const seconds = totalSeconds % 60

  return { minutes, seconds }
}

function PlanTool({ setLocalTemplate, localTemplate, slideId, slide: currentSlide }) {

  // Extrae el slide actual usando el slideId
  // const currentSlide = localTemplate.slides.find(slide => slide.slideId === slideId)

  // Obtiene la duración en milisegundos desde el slide actual
  const slideDurationInMilliseconds = currentSlide?.config?.settings?.duration || 0

  // Convierte la duración en milisegundos a minutos y segundos
  const slideDuration = millisecondsToTime(slideDurationInMilliseconds)

  // Inicializa el estado "duration" con la duración del slide
  const [duration, setDuration] = useState(slideDuration)

  console.log('PlanTool localTemplate: ', localTemplate)

  // OverlayPanel / Menús desplegables de la barra de herramientas
  const planPanel = useRef(null)
  const [weekActive, setWeekActive] = useState(true)
  const [morningActive, setMorningActive] = useState(false)
  const [afternoonActive, setAfternoonActive] = useState(false)
  const [eveningActive, setEveningActive] = useState(false)
  const [allWeekActive] = useState(false)
  const [weekendActive] = useState(false)
  const [updatedDayState, setUpdatedDayState] = useState(null)
  // const [duration, setDuration] = useState({ minutes: 0, seconds: 5 })
  const [durationAuto, setDurationAuto] = useState(false)

  // eslint-disable-next-line no-unused-vars
  const [schedule, setSchedule] = useState([
    {
      id: 0,
      value: '00:00',
      active: true,
    },
    {
      id: 1,
      active: true,
      value: '23:59',
    }
  ])

  // OverlayPanel Plan
  const [daysActive, setDaysActive] = useState([
    {
      id: 0,
      value: 'L',
      active: true,
    },
    {
      id: 1,
      value: 'M',
      active: true,
    },
    {
      id: 2,
      value: 'X',
      active: true,
    },
    {
      id: 3,
      value: 'J',
      active: true,
    },
    {
      id: 4,
      value: 'V',
      active: true,
    },
    {
      id: 5,
      value: 'S',
      active: false,
    },
    {
      id: 6,
      value: 'D',
      active: false,
    }
  ])

  const setScheduleAllDay = () => {
    const newSchedule = [
      {
        id: 0,
        value: '00:00',
        active: true,
      },
      {
        id: 1,
        active: true,
        value: '23:59',
      }
    ]

    setSchedule(newSchedule)
    handleScheduleChange(newSchedule)

  }

  const setScheduleMorning = () => {
    const newSchedule = [
      {
        id: 0,
        value: '06:00',
        active: true,
      },
      {
        id: 1,
        active: true,
        value: '12:00',
      }
    ]

    setSchedule(newSchedule)
    handleScheduleChange(newSchedule)
  }

  const setScheduleAfternoon = () => {
    const newSchedule = [
      {
        id: 0,
        value: '12:01',
        active: true,
      },
      {
        id: 1,
        active: true,
        value: '18:00',
      }
    ]

    setSchedule(newSchedule)
    handleScheduleChange(newSchedule)
  }

  const setScheduleEvening = () => {
    const newSchedule = [
      {
        id: 0,
        value: '18:01',
        active: true,
      },
      {
        id: 1,
        active: true,
        value: '23:59',
      }
    ]

    setSchedule(newSchedule)
    handleScheduleChange(newSchedule)
  }
  const handleScheduleChange = newSchedule => {
    // Creamos una copia del localTemplate actual
    const newLocalTemplate = { ...localTemplate }

    // Buscamos el slide correcto en la copia de localTemplate
    const currentSlide = newLocalTemplate.slides.find(slide => slide.slideId === slideId)

    // Asegurémonos de que el slide y su configuración existan, y si no, los creamos.
    currentSlide.config = currentSlide.config || {}
    currentSlide.config.settings = currentSlide.config.settings || {}

    // Actualizamos la configuración del slide con la nueva configuración.
    currentSlide.config.settings.schedule = newSchedule

    // Establecemos la copia de localTemplate como la nueva localTemplate.
    setLocalTemplate(newLocalTemplate)
  }

  const handleDurationChange = newDuration => {
    // Creamos una copia del localTemplate actual
    const newLocalTemplate = { ...localTemplate }

    // Buscamos el slide correcto en la copia de localTemplate
    const currentSlide = newLocalTemplate.slides.find(slide => slide.slideId === slideId)

    // Asegurémonos de que el slide y su configuración existan, y si no, los creamos.
    currentSlide.config = currentSlide.config || {}
    currentSlide.config.settings = currentSlide.config.settings || {}

    // Actualizamos la configuración del slide con la nueva configuración.
    currentSlide.config.settings.duration = newDuration

    // Establecemos la copia de localTemplate como la nueva localTemplate.
    setLocalTemplate(newLocalTemplate)
  }

  const handleDaysActiveChange = newDaysActive => {
    // Creamos una copia del localTemplate actual
    const newLocalTemplate = { ...localTemplate }

    // Buscamos el slide correcto en la copia de localTemplate
    const currentSlide = newLocalTemplate.slides.find(slide => slide.slideId === slideId)

    // Asegurémonos de que el slide y su configuración existan, y si no, los creamos.
    currentSlide.config = currentSlide.config || {}
    currentSlide.config.settings = currentSlide.config.settings || {}

    // Actualizamos la configuración del slide con la nueva configuración.
    currentSlide.config.settings.daysActive = newDaysActive

    // Establecemos la copia de localTemplate como la nueva localTemplate.
    setLocalTemplate(newLocalTemplate)
  }

  const toggleDayActive = dayId => {
    setDaysActive(prevDaysActive => {
      const updatedDays = prevDaysActive.map(day =>
        day.id === dayId
          ? { ...day, active: !day.active }
          : day
      )

      handleDaysActiveChange(updatedDays)

      return updatedDays
    })
  }

  const setActiveDays = activeDaysIds => {
    setDaysActive(prevDaysActive => {
      const updatedDays = prevDaysActive.map(day =>
        activeDaysIds.includes(day.id)
          ? { ...day, active: true }
          : { ...day, active: false }
      )

      handleDaysActiveChange(updatedDays)

      return updatedDays
    })
  }

  const updateLocalTemplate = (slideId, updatedValues) => {
    setLocalTemplate(prevTemplate => {
      // Busca el índice del slide en el array de slides que tiene el slideId proporcionado
      const slideIndex = prevTemplate.slides.findIndex(slide => slide.slideId === slideId)

      // Si el slide existe en el array de slides
      if (slideIndex > -1) {
        // Crea una copia de la plantilla previa
        const newLocalTemplate = { ...prevTemplate }

        // Crea una copia de los slides
        newLocalTemplate.slides = [...prevTemplate.slides]

        // Actualiza el slide específico que necesita ser actualizado
        newLocalTemplate.slides[slideIndex] = {
          ...newLocalTemplate.slides[slideIndex],
          config: {
            ...newLocalTemplate.slides[slideIndex].config,
            settings: {
              ...newLocalTemplate.slides[slideIndex].config.settings,
              ...updatedValues,
            },
          },
        }

        return newLocalTemplate
      } else {
        // Si el slide no existe, solo devuelve la plantilla previa
        return prevTemplate
      }
    })
  }

  const handleMorningActive = () => {
    setMorningActive(true)
    setAfternoonActive(false)
    setWeekActive(false)
    setEveningActive(false)
  }

  const handleAfternoonActive = () => {
    setAfternoonActive(true)
    setMorningActive(false)
    setWeekActive(false)
    setEveningActive(false)
  }

  const handleEveningActive = () => {
    setEveningActive(true)
    setMorningActive(false)
    setAfternoonActive(false)
    setWeekActive(false)
  }

  const handleWeekActive = () => {
    setWeekActive(!weekActive)
    setMorningActive(false)
    setAfternoonActive(false)
  }

  const incrementSeconds = () => {
    setDuration(prevDuration => {
      let newMinutes = prevDuration.minutes
      let newSeconds = prevDuration.seconds + 5

      if (newSeconds >= 60) {
        newMinutes += 1
        newSeconds = 0
      }

      const newDuration = { minutes: newMinutes, seconds: newSeconds }
      const newDurationInMilliseconds = newDuration.minutes * 60 * 1000 + newDuration.seconds * 1000

      handleDurationChange(newDurationInMilliseconds)
      handleAutoDurationChange(false)

      return newDuration
    })
    setDurationAuto(false)
  }

  const decrementSeconds = () => {
    setDuration(prevDuration => {
      if (prevDuration.minutes === 0 && prevDuration.seconds === 0) {
        return prevDuration
      }

      let newMinutes = prevDuration.minutes
      let newSeconds = prevDuration.seconds - 5

      if (newSeconds < 0) {
        newMinutes = Math.max(newMinutes - 1, 0)
        newSeconds = 55
      }

      const newDuration = { minutes: newMinutes, seconds: newSeconds }
      const newDurationInMilliseconds = newDuration.minutes * 60 * 1000 + newDuration.seconds * 1000

      handleDurationChange(newDurationInMilliseconds)
      handleAutoDurationChange(false)

      return newDuration
    })
    setDurationAuto(false)
  }

  const formatTime = (minutes, seconds) => {
    const formattedMinutes = minutes.toString().padStart(2, '0')
    const formattedSeconds = seconds.toString().padStart(2, '0')

    return `${formattedMinutes}:${formattedSeconds}`
  }

  const handleDurationAutoActive = () => {
    setDurationAuto(prevDurationAuto => {
      const newDurationAuto = !prevDurationAuto

      handleAutoDurationChange(newDurationAuto)

      return newDurationAuto
    })
  }

  const handleAutoDurationChange = newAutoDuration => {
    const newLocalTemplate = { ...localTemplate }
    const currentSlide = newLocalTemplate.slides.find(slide => slide.slideId === slideId)

    currentSlide.config = currentSlide.config || {}
    currentSlide.config.settings = currentSlide.config.settings || {}

    currentSlide.config.settings.autoDuration = newAutoDuration

    setLocalTemplate(newLocalTemplate)
  }

  useEffect(() => {
    const currentSlide = localTemplate.slides.find(slide => slide.slideId === slideId)

    if (currentSlide?.config?.settings?.autoDuration !== undefined) {
      setDurationAuto(currentSlide.config.settings.autoDuration)
    }
  }, [localTemplate, slideId])

  // Carga inicial de la duración del slide
  useEffect(() => {
    const currentSlide = localTemplate.slides.find(slide => slide.slideId === slideId)
    const slideDurationInMilliseconds = currentSlide?.config?.settings?.duration ? currentSlide.config?.settings?.duration : 0
    const minutes = Math.floor(slideDurationInMilliseconds / (60 * 1000))
    const seconds = (slideDurationInMilliseconds - minutes * 60 * 1000) / 1000

    setDuration({ minutes, seconds })
  }, [localTemplate, slideId])

  // Usa useEffect para manejar la actualización del estado
  useEffect(() => {
    if (updatedDayState) {
      updateLocalTemplate(slideId, { daysActive: updatedDayState })
      setUpdatedDayState(null)
    }
  }, [updatedDayState, slideId])

  useEffect(() => {
    const currentSlide = localTemplate.slides.find(slide => slide.slideId === slideId)

    if (currentSlide && currentSlide.config && currentSlide.config.settings) {
      const { daysActive: savedDaysActive } = currentSlide.config.settings

      if (savedDaysActive) {
        setDaysActive(savedDaysActive)
      } else {
        // Este slide no tiene una configuración de días activos guardada,
        // así que usamos la configuración por defecto
        setDaysActive([
          { id: 0, value: 'L', active: true },
          { id: 1, value: 'M', active: true },
          { id: 2, value: 'X', active: true },
          { id: 3, value: 'J', active: true },
          { id: 4, value: 'V', active: true },
          { id: 5, value: 'S', active: false },
          { id: 6, value: 'D', active: false }
        ])
      }
    }
  }, [localTemplate, slideId])

  useEffect(() => {
    const currentSlide = localTemplate.slides.find(slide => slide.slideId === slideId)

    if (currentSlide && currentSlide.config && currentSlide.config.settings) {
      const { schedule: savedSchedule } = currentSlide.config.settings

      if (savedSchedule) {
        const isMorning = savedSchedule[0].value === '06:00' && savedSchedule[1].value === '12:00'
        const isAfternoon = savedSchedule[0].value === '12:01' && savedSchedule[1].value === '18:00'
        const isEvening = savedSchedule[0].value === '18:01' && savedSchedule[1].value === '23:59'
        const isWeek = savedSchedule[0].value === '00:00' && savedSchedule[1].value === '23:59'

        setMorningActive(isMorning)
        setAfternoonActive(isAfternoon)
        setWeekActive(isWeek)
        setEveningActive(isEvening)
      }
    }
  }, [localTemplate, slideId])

  return (
    <>
      <button className='flex flex-col items-center px-2 hover:text-primary' onClick={e => planPanel.current.toggle(e)} aria-haspopup aria-controls='overlay_panel' >
        <CalendarDaysIcon className='h-5 w-5 mb-1' aria-hidden='true' />
        <span className='text-[10px] leading-none'>Plan</span>
      </button>
      <OverlayPanel ref={planPanel} showCloseIcon id='overlay_panel' style={{ width: 'auto' }} className='overlaypanel-demo'>
        <div className='px-6 py-3 border-b border-gray-200 text-sm font-semibold'>Plan & Schedule</div>
        <div className='px-6 py-1'>
          <ul>
            <li className='py-3'>
              <span className='text-sm font-semibold mb-2 block'>Week days</span>
              <div className='flex items-center mb-2'>
                <CalendarDaysIcon className='h-5 w-5 text-gray-50 me-3' aria-hidden='true' />
                {daysActive.map(day => (
                  <button
                    key={day.id}
                    className={`h-6 w-6 flex items-center justify-center rounded-full text-sm border me-1 ${day.active ? 'bg-primary text-white border-primary' : 'border-gray-300'}`}
                    onClick={() => toggleDayActive(day.id)}
                  >{day.value}</button>
                ))}
              </div>
              <div className='flex items-center'>
                <CalendarDaysIcon className='h-5 w-5 text-gray-50 me-3' aria-hidden='true' />
                <button
                  className={`h-6 px-2.5 rounded-full text-sm border me-1 whitespace-nowrap ${allWeekActive ? 'bg-primary text-white border-primary' : 'border-gray-300'}`}
                  onClick={() => setActiveDays([0, 1, 2, 3, 4, 5, 6])}
                >All Week</button>
                <button
                  className={`h-6 px-2.5 rounded-full text-sm border me-1 ${weekendActive ? 'bg-primary text-white border-primary' : 'border-gray-300'}`}
                  onClick={() => setActiveDays([5, 6])}
                >Weekend</button>
              </div>
            </li>
            <li className='py-3'>
              <span className='text-sm font-semibold mb-2 block'>Schedule</span>
              <div className='flex items-center'>
                <ClockIcon className='h-5 w-5 text-gray-50 me-3' aria-hidden='true' />
                <button
                  className={`h-6 px-2.5 rounded-full text-sm border me-1 ${weekActive ? 'bg-primary text-white border-primary' : 'border-gray-300'}`}
                  onClick={() => { handleWeekActive(); setScheduleAllDay() }}
                >All day</button>
                <button
                  className={`h-6 px-2.5 rounded-full text-sm border me-1 ${morningActive ? 'bg-primary text-white border-primary' : 'border-gray-300'}`}
                  onClick={() => { handleMorningActive(); setScheduleMorning() }}
                >Morning</button>
                <button
                  className={`h-6 px-2.5 rounded-full text-sm border me-1 ${afternoonActive ? 'bg-primary text-white border-primary' : 'border-gray-300'}`}
                  onClick={() => { handleAfternoonActive(); setScheduleAfternoon() }}
                >Afternoon</button>
                <button
                  className={`h-6 px-2.5 rounded-full text-sm border me-1 ${eveningActive ? 'bg-primary text-white border-primary' : 'border-gray-300'}`}
                  onClick={() => { handleEveningActive(); setScheduleEvening() }}
                >Evening</button>
              </div>
            </li>
            <li className='py-3'>
              <span className='text-sm font-semibold mb-2 block'>Duration</span>
              <div className='flex items-center'>
                <ClockIcon className='h-5 w-5 text-gray-50 me-3' aria-hidden='true' />
                <div className='flex me-1'>
                  <div className={`h-6 px-2.5 min-w-[56px] flex items-center justify-center rounded-full text-sm border bg-white relative ${durationAuto ? 'border-gray-300' : 'border-blue-500'}`}>{formatTime(duration.minutes, duration.seconds)}</div>
                  <div className={`h-6 ps-5 pe-2.5 -ms-4 flex flex-col rounded-full ${durationAuto ? 'bg-gray-300' : 'bg-primary text-white'}`}>
                    <button className='mt-px' onClick={incrementSeconds}><ChevronUpIcon className='h-3 w-3' aria-hidden='true' /></button>
                    <button className='-mt-0.5' onClick={decrementSeconds} disabled={duration.minutes === 0 && duration.seconds === 0}><ChevronDownIcon className='h-3 w-3' aria-hidden='true' /></button>
                  </div>
                </div>
                <button
                  className={`h-6 px-2.5 rounded-full text-sm border ${durationAuto ? 'bg-primary text-white border-primary' : 'border-gray-300'}`}
                  onClick={handleDurationAutoActive}
                >Auto</button>
              </div>
            </li>
          </ul>
        </div>
      </OverlayPanel>
    </>
  )
}

export default PlanTool
