import { useState } from 'react'

import ContentEditable from 'react-contenteditable'

export default function TextCanvasItem({
  item: {
    elementId,
    slideId,
    config: { text = '', style = {} },
  },
  changeItemStyles,
  editable,
  fontSize,
  color,
  setLocalTemplate,
  localTemplate,
  isTextEditable,
  setIsTextEditable,
}) {

  const [textContent, setTextContent] = useState(text)

  console.log(style)

  /**
   * Returns an updated template with the provided HTML text.
   * @param {string} html - The HTML text to update the template with.
   * @returns {Object} The updated template.
   */
  function getUpdatedTemplate(html) {
    const updatedTemplate = JSON.parse(JSON.stringify(localTemplate))
    const slide = updatedTemplate.slides.find(s => s.slideId === slideId)

    if (!slide) {
      console.error('No matching slide found')

      return
    }

    const layer = slide.items.find(l => l.elementId === elementId)

    if (!layer) {
      console.error('No matching layer found')

      return
    }

    layer.config.text = html

    return updatedTemplate
  }

  /**
   * Handles the change event of the text input element.
   * @param {Event} event - The change event object.
   */
  function handleChange(event) {

    const html = event.target.value

    setTextContent(html)

    changeItemStyles({
      elementId,
      text: html,
      fontSize,
      color,
    })

    const updatedTemplate = getUpdatedTemplate(html)

    if (updatedTemplate) {
      setLocalTemplate(updatedTemplate)
    }
  }

  // function shouldDeleteElement() {
  //   return !isEditingText && textContent === ''
  // }

  // if (shouldDeleteElement()) {
  //   // Si el elemento debe ser eliminado, no renderizas nada.
  //   return null
  // }

  return (
    <ContentEditable
      className='h-full w-full outline-none border-none'
      html={textContent}
      disabled={isTextEditable}
      onChange={handleChange}
      style={{
        fontSize,
        ...style,
        color: `#${style.color}`,

      }}
    />
  )
}
