const EmbedCanvasItem = ({ item }) => {

  // Use DOMParser to extract iframe src from embed code
  const extractIframeSrc = html => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, 'text/html')
    const iframe = doc.querySelector('iframe')

    return iframe ? iframe.src : null
  }

  if (item.src && item.type === 'urlWebsite') {
    return (
      <div className='overflow-hidden h-full w-full'>
        <iframe
          src={item.src}
          frameBorder='0'
          width='600px'
          height='450px'
          allow='autoplay; fullscreen'
          allowFullScreen
        />
      </div>
    )
  }

  if (item.embedCode && item.type === 'embed') {
    const iframeSrc = extractIframeSrc(item.embedCode)

    if (!iframeSrc) return null // or return some error message

    return (
      <div className='overflow-hidden h-full w-full'>
        <iframe
          src={iframeSrc}
          frameBorder='0'
          width='600px'
          height='450px'
          allow='autoplay; fullscreen'
          allowFullScreen
        />
      </div>
    )
  }

  return null
}

export default EmbedCanvasItem
