import React, { useState } from 'react'

import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

import SocialsComponent from './socialsComponent'

function SocialsInputComponent({ addNewItem, selectedPlatform, closeMediaPanel }) {
  const [videoURL, setVideoURL] = useState('')
  // eslint-disable-next-line no-unused-vars

  const PLATFORM_CONFIG = {
    youtube: {
      pattern: /^(https?:\/\/)?(www\.)?youtube\.com\/(watch\?v=|embed\/|shorts\/)[\w-]+$/,
      example: 'https://www.youtube.com/watch?v=exampleID',
    },
    twitch: {
      pattern: /^(https?:\/\/)?(www\.)?twitch\.tv\/.+$/,
      example: 'https://www.twitch.tv/exampleChannel',
    },
    vimeo: {
      pattern: /^(https?:\/\/)?(www\.)?vimeo\.com\/\d+$/,
      example: 'https://www.vimeo.com/123456789',
    },
    dailymotion: {
      pattern: /^(https?:\/\/)?(www\.)?dailymotion\.com\/video\/[^_]+$/,
      example: 'https://www.dailymotion.com/video/exampleID',
    },
    // Add similar config for other platforms like tiktok
  }

  function isValidURLForPlatform(url, platform) {
    const config = PLATFORM_CONFIG[platform]

    return config && config.pattern.test(url)
  }

  function handleAddClick() {

    if (!isValidURLForPlatform(videoURL, selectedPlatform)) {
      alert('Invalid or mismatched URL for the selected platform.')

      return
    }

    addNewItem({
      type: 'url',
      url: videoURL,
    })

    closeMediaPanel()
  }

  const [showSocialsComponent, setShowSocialsComponent] = useState(false)

  if (showSocialsComponent) {
    return <SocialsComponent addNewItem={addNewItem} />
  }

  return (
    <>
      <div className='relative mb-4 rounded-md'>
        <button
          className='bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md mb-4'
          onClick={() => setShowSocialsComponent(true)}
        >
          Return to Socials
        </button>

        {selectedPlatform && (
          <div className='mb-5 text-gray-800'>
            You have selected the {selectedPlatform.charAt(0).toUpperCase() + selectedPlatform.slice(1)} platform. Please paste the URL of the video you want to embed below.
            <br/>
            <br/>
            Here is an example URL for {selectedPlatform.charAt(0).toUpperCase() + selectedPlatform.slice(1)}: {PLATFORM_CONFIG[selectedPlatform].example}
          </div>
        )}

        {/* Wrapper div */}
        <div className='relative mb-4'>
          {/* Adjust positioning as needed */}
          <div className='absolute top-3 left-3'>
            <MagnifyingGlassIcon className='h-5 w-5 text-gray-50' aria-hidden='true' />
          </div>
          <textarea
            className='block w-full pt-3 pb-1 pl-12 border rounded-md placeholder-gray-500 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'
            placeholder='Paste your URL here'
            value={videoURL}
            onChange={e => setVideoURL(e.target.value)}
          ></textarea>
        </div>
        <button
          className='bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md'
          onClick={handleAddClick}
        >
          Add
        </button>
      </div>
    </>
  )

}

export default SocialsInputComponent
