import { collection, query, where, getDocs, updateDoc, doc, getDoc, writeBatch } from 'firebase/firestore'

import { db } from '../firebase'

export async function fetchScreenByAccessCode(codeAsString) {
  try {
    const screensRef = collection(db, 'screens')
    const q = query(screensRef, where('accessCode', '==', codeAsString))
    const querySnapshot = await getDocs(q)

    return querySnapshot.empty ? null : querySnapshot.docs[0]
  } catch (error) {
    console.error('Error fetching screen by access code:', error)

    return null
  }
}

export async function updateScreenUser(screenId, userId) {
  try {
    await updateDoc(doc(db, 'screens', screenId), {
      user: userId,
    })

    return true // if successful
  } catch (error) {
    console.error('Error updating screen:', error)

    return false // if there's an error
  }
}

export function fetchWorkspaceByName(workspaceName, workspaces) {
  try {
    // Filter the workspaces array for the desired name
    console.log('workspaceName', workspaceName)
    const matchingWorkspace = workspaces.find(workspace => workspace.name === workspaceName)

    return matchingWorkspace || null
  } catch (error) {
    console.error('Error fetching workspace by name:', error)

    return null
  }
}

export function fetchDefaultWorkspace(workspaces) {
  try {
    // Filter the workspaces array for the default name
    const defaultWorkspace = workspaces.find(workspace => workspace.name === 'Default Workspace')

    return defaultWorkspace || null
  } catch (error) {
    console.error('Error fetching default workspace:', error)

    return null
  }
}

export async function updateScreenWorkspace(screenId, subCategoryName, targetWorkspaceId) {
  try {
    await updateDoc(doc(db, 'screens', screenId), {
      workspaceName: subCategoryName,
      workspaceId: targetWorkspaceId,
    })

    return { success: true, message: 'Screen workspace updated successfully' }
  } catch (error) {
    console.error('Error updating screen workspace:', error)

    return { success: false, message: error.message }
  }
}

export async function addScreenToWorkspace(targetWorkspaceId, updatedScreen) {
  try {
    console.log('targetedWorkspaceId', targetWorkspaceId)
    console.log('updatedScreen', updatedScreen)
    const workspaceSnapshot = await getDoc(doc(db, 'workspaces', targetWorkspaceId))
    const workspaceData = workspaceSnapshot.data()

    if (!workspaceData) {
      throw new Error(`Workspace with ID ${targetWorkspaceId} does not exist.`)
    }

    if (!workspaceData.screens) workspaceData.screens = []
    workspaceData.screens.push(updatedScreen)

    await updateDoc(doc(db, 'workspaces', targetWorkspaceId), {
      screens: workspaceData.screens,
    })

    console.log('Updated screens array:', workspaceData.screens)

    return { success: true, message: 'Screen added successfully' }

  } catch (error) {
    console.error('Error adding screen to workspace:', error)

    return { success: false, message: error.message }
  }
}

export function getWorkspaceName(workspace) {
  if (typeof workspace === 'string') return workspace
  if (workspace && workspace.name) return workspace.name
  if (typeof workspace === 'object') return workspace.toString()

  return ''
}

// for templates

export async function getTemplatesByUser(userId) {
  const templatesRef = collection(db, 'templates')
  const editorQuery = query(templatesRef, where('users.editor', 'array-contains', userId))
  const ownerQuery = query(templatesRef, where('users.owner', 'array-contains', userId))
  const viewerQuery = query(templatesRef, where('users.viewer', 'array-contains', userId))

  const [editorSnapshot, ownerSnapshot, viewerSnapshot] = await Promise.all([
    getDocs(editorQuery),
    getDocs(ownerQuery),
    getDocs(viewerQuery)
  ])

  const templates = []

  editorSnapshot.forEach(doc => {
    const templateData = doc.data()
    const template = {
      id: doc.id,
      ...templateData,
      userRole: 'editor',
    }

    templates.push(template)
  })

  ownerSnapshot.forEach(doc => {
    const templateData = doc.data()
    const template = {
      id: doc.id,
      ...templateData,
      userRole: 'owner',
    }

    templates.push(template)
  })

  viewerSnapshot.forEach(doc => {
    const templateData = doc.data()
    const template = {
      id: doc.id,
      ...templateData,
      userRole: 'viewer',
    }

    templates.push(template)
  })

  return templates
}

// Additional function to append to an array field in Firestore
const appendToArray = (arr, newItem) => {
  if (!arr) {
    return [newItem]
  }
  if (arr.includes(newItem)) {
    return arr
  }

  return [...arr, newItem]
}

export async function saveTemplateToScreen(accessCode, template, shouldPublish) {
  const accessCodeString = accessCode.join('')
  const screenDoc = await fetchScreenByAccessCode(accessCodeString)

  if (!screenDoc) {
    throw new Error('Screen not found')
  }

  // Create a batch to perform atomic updates
  const batch = writeBatch(db)

  // Update the template with the new screenId, get the current screensIdAttached to append
  const templateDocRef = doc(db, 'templates', template.templateId)
  const templateDoc = await getDoc(templateDocRef)
  const templateData = templateDoc.data()

  // Append the new screen ID to the existing array of attached screen IDs
  const updatedScreensIdAttached = appendToArray(templateData.screensIdAttached, screenDoc.id)

  // Always update the template's screensIdAttached array
  batch.update(templateDocRef, {
    screensIdAttached: updatedScreensIdAttached,
  })

  // Initialize the update object for the screen document with the templateId and templateTitle
  const screenUpdate = {
    templateId: template.templateId,
    templateTitle: template.config.settings.title,
  }

  // If shouldPublish is true, also update the slides and format
  if (shouldPublish) {
    screenUpdate.slides = template.slides
    screenUpdate.format = template.config.settings.aspectRatio
  }

  // Update the screen with the template details
  const screenDocRef = doc(db, 'screens', screenDoc.id)

  batch.update(screenDocRef, screenUpdate)

  try {
    await batch.commit()
    const action = shouldPublish ? 'published to' : 'linked to'

    return `Template ${template.templateId} has been successfully ${action} screen ${screenDoc.id}.`
  } catch (error) {
    console.error('Error committing batch: ', error)
    throw error
  }
}

export async function handleSaveTemplate(shouldPublish, accessCode, template) {
  try {

    const message = await saveTemplateToScreen(accessCode, template, shouldPublish)

    console.log(message)
  } catch (error) {
    console.error(error.message)
    // Handle error, perhaps show a notification to the user
  }
}
