import React, { useState } from 'react'

import { CodeBracketIcon, GlobeAltIcon } from '@heroicons/react/24/outline'

import EmbedInputComponent from './embedInputComponent'

function EmbedComponent({ addNewItem, closeMediaPanel }) {
  const [selectedOption, setSelectedOption] = useState(null)

  const ICON_OPTIONS = [
    { name: 'codeEmbed', icon: CodeBracketIcon, description: 'Embed with code' },
    { name: 'globeURL', icon: GlobeAltIcon, description: 'Embed with URL' }
  ]

  if (selectedOption) {
    return <EmbedInputComponent selectedOption={selectedOption} addNewItem={addNewItem} closeMediaPanel={closeMediaPanel} />
  }

  return (
    <div className='flex justify-center items-center h-full rounded-md'>
      <div className='flex space-x-4'>
        {ICON_OPTIONS.map(option => (
          <div
            key={option.name}
            className='bg-gray-200 p-4 rounded cursor-pointer hover:bg-gray-300'
            onClick={() => setSelectedOption(option.name)}
          >
            <option.icon className='h-6 w-6' aria-hidden='true' title={option.description} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default EmbedComponent
