// useScaledDimensions.js
import { useState } from 'react'

function useScaledDimensions(initialScale = 1, defaultWidth = 1920, defaultHeight = 1080) {
  const [scale, setScale] = useState(initialScale)
  const dimensions = {
    width: defaultWidth * scale,
    height: defaultHeight * scale,
  }

  return [dimensions, scale, setScale]
}

export default useScaledDimensions
