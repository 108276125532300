import React, { useContext, useEffect, useState } from 'react'

import { Link } from 'react-router-dom'

import { AuthContext } from '../AuthProvider'
import CardScreen from '../components/CardScreen'
import CardTemplate from '../components/CardTemplate'
import DialogNewScreen from '../components/dialog/dialog-new-screen/DialogNewScreen'
import DialogNewTemplate from '../components/dialog/DialogNewTemplate'
import useMediaQuery from '../hooks/useMediaQuery'
import { Layout } from '../layout/Layout'
import { getTemplatesByUser, getScreensByUser } from '../services/userService'

export default function DashboardPage() {

  // Obtenemos el usuario actual del contexto
  const { user } = useContext(AuthContext)
  const [userTemplates, setUserTemplates] = useState([])
  const [userScreens, setUserScreens] = useState([])

  useEffect(() => {
    async function fetchData() {
      const templates = await getTemplatesByUser(user.uid)
      const screens = await getScreensByUser(user.uid)

      setUserTemplates(templates)
      setUserScreens(screens)
    }

    fetchData()
  }, [user.uid])

  // Resolución de la pantalla
  const isMobile = useMediaQuery('(max-width: 639px)')
  const isMedium = useMediaQuery('(min-width: 640px) and (max-width: 1279px)')
  const isLarge = useMediaQuery('(min-width: 1280px) and (max-width: 1535px)')
  const isExtraLarge = useMediaQuery('(min-width: 1536px)')

  let displayProducts = userTemplates

  if (isMobile) {
    displayProducts = userTemplates.slice(0, 3) // Mostrar los primeros 3 productos en móviles
  } else if (isMedium) {
    displayProducts = userTemplates.slice(0, 1) // Mostrar el primer producto en resoluciones medianas
  } else if (isLarge) {
    displayProducts = userTemplates.slice(0, 2) // Mostrar los primeros 2 productos en resoluciones grandes
  } else if (isExtraLarge) {
    displayProducts = userTemplates.slice(0, 3) // Mostrar los primeros 3 productos en resoluciones extra grandes
  }

  let displayScreens = userScreens

  if (isMobile) {
    displayScreens = userScreens.slice(0, 2) // Mostrar los primeros 2 productos en móviles
  } else if (isMedium) {
    displayScreens = userScreens.slice(0, 1) // Mostrar el primer producto en resoluciones medianas
  } else if (isLarge) {
    displayScreens = userScreens.slice(0, 1) // Mostrar los primeros 2 productos en resoluciones grandes
  } else if (isExtraLarge) {
    displayScreens = userScreens.slice(0, 2) // Mostrar los primeros 2 productos en resoluciones extra grandes
  }

  const [visibleDialogNewTemplate, setVisibleDialogNewTemplate] = useState(false)
  const [visibleDialogNewScreen, setVisibleDialogNewScreen] = useState(false)

  const showDialogNewTemplate = () => {
    setVisibleDialogNewTemplate(true)
  }

  const showDialogNewScreen = () => {
    setVisibleDialogNewScreen(true)
  }

  return (
    <Layout>
      {/* Dialog New template */}
      <DialogNewTemplate visible={visibleDialogNewTemplate} setVisible={setVisibleDialogNewTemplate} />
      {/* Dialog New Screen */}
      <DialogNewScreen visible={visibleDialogNewScreen} setVisible={setVisibleDialogNewScreen} />
      <div className='mx-auto px-4 pt-8 sm:px-6 lg:px-8'>
        <div className='sm:flex sm:items-baseline sm:justify-between mb-1'>
          <h2 className='text-2xl font-bold tracking-tight text-gray-900'>Your Templates</h2>
          <Link to='/template-list' className='hidden text-sm font-semibold text-primary hover:text-blue-600 sm:block'>
            See all templates
            <span aria-hidden='true'> &rarr;</span>
          </Link>
        </div>
        <p className='text-sm'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer elementum accumsan nisl</p>

        <div className='mt-6 grid grid-cols-1 gap-y-10 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-0 xl:grid-cols-3 2xl:grid-cols-4'>
          <button
            onClick={showDialogNewTemplate}
            type='button'
            className='relative block w-full rounded-lg bg-white p-12 text-center focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
          >
            <svg
              className='mx-auto h-8 w-8 text-gray-50'
              stroke='currentColor'
              fill='none'
              viewBox='0 0 48 48'
              aria-hidden='true'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6'
              />
            </svg>
            <span className='mt-2 block text-sm font-semibold text-gray-900'>Create new template</span>
          </button>
          {displayProducts.map((template, index) =>
            <CardTemplate key={template.id} template={template} index={index} />
          )}
        </div>

        <div className='mt-6 sm:hidden'>
          <Link to='/template-list' className='block text-sm font-semibold text-primary hover:text-blue-600'>
            See all templates
            <span aria-hidden='true'> &rarr;</span>
          </Link>
        </div>

      </div>
      <div className='mx-auto px-4 pt-8 sm:px-6 lg:px-8'>
        <div className='sm:flex sm:items-baseline sm:justify-between mb-1'>
          <h2 className='text-2xl font-bold tracking-tight text-gray-900'>Your Screens/TV</h2>
          <Link to='/tv-list' className='hidden text-sm font-semibold text-primary hover:text-blue-600 sm:block'>
            Browse all Screens/TV
            <span aria-hidden='true'> &rarr;</span>
          </Link>
        </div>
        <p className='text-sm'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer elementum accumsan nisl</p>

        <div className='mt-6 grid grid-cols-1 gap-y-10 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-0 2xl:grid-cols-3'>
          <button
            onClick={showDialogNewScreen}
            type='button'
            className='relative block w-full rounded-lg bg-white p-12 text-center focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 aspect-video mb-6'
          >
            <svg
              className='mx-auto h-8 w-8 text-gray-50'
              stroke='currentColor'
              fill='none'
              viewBox='0 0 48 48'
              aria-hidden='true'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6'
              />
            </svg>
            <span className='mt-2 block text-sm font-semibold text-gray-900'>Pair new Screen</span>
          </button>
          {displayScreens.map(screen =>
            <CardScreen key={screen.id} screen={screen} />
          )}
        </div>
      </div>
    </Layout>
  )
}
