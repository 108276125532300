import { useContext, useEffect } from 'react'

import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'

import { AuthContext } from '../AuthProvider'
import DialogDeleteWorkspace from '../components/dialog/DialogDeleteWorkspace'
import DisplayScreens from '../components/DisplayScreens'
import DisplayTemplates from '../components/DisplayTemplates'
import { useWorkspaceContext } from '../context/workspace/workspace-context'
import { Layout } from '../layout/Layout'

export default function Workspace() {
  const navigate = useNavigate()
  const { idWorkspace } = useParams()
  const { user } = useContext(AuthContext)
  const { currentWorkspace, getUserCurrentWorkspace, getTemplatesAndScreens, templatesByWorkspace, screensByWorkspace, openDeleteWorkspaceDialog } = useWorkspaceContext()

  useEffect(() => {
    // Validar que venga parametro idWorkspace
    if (idWorkspace) {
      (async () => {
        const response = await getUserCurrentWorkspace(user.uid, idWorkspace)

        await getTemplatesAndScreens(idWorkspace)

        // Si el parametro  es incorrecto, redireccionar a la home
        if (!response) {
          navigate('/')
        }
      })()
    }

  }, [idWorkspace])

  if (!user && !idWorkspace) {
    return <h1>Something went wrong</h1>
  }

  return (
    <Layout>
      <div className='sm:flex sm:items-baseline sm:justify-between mb-1'>
        <h2 className='text-2xl font-bold tracking-tight text-gray-900 lg:py-4'>#{currentWorkspace?.name}</h2>
        { !currentWorkspace?.isDefaultWorkspace && <button onClick={openDeleteWorkspaceDialog}className='bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline-red active:bg-red-800' >Delete workspace</button> }
        <DialogDeleteWorkspace />
      </div>
      { templatesByWorkspace.length > 0 && <DisplayTemplates
        title={'Your templates associated to this workspace'}
        templates={templatesByWorkspace}
      /> }
      { templatesByWorkspace.length === 0 && <h1>You have no templates associated to this workspace</h1> }
      { screensByWorkspace.length === 0 && <h1>You have no screens associated to this workspace</h1> }
      { screensByWorkspace.length > 0 && <DisplayScreens
        title={'Your screens associated to this workspace'}
        screens={screensByWorkspace} />}
    </Layout>
  )
}
