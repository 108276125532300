/* eslint-disable no-unused-vars */
import React, { useState, useContext } from 'react'

import {
  ArrowRightIcon,
  ArrowLeftIcon,
} from '@heroicons/react/24/outline'
import { setDoc, doc } from 'firebase/firestore'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import Step1NewTemplate from './Step1NewTemplate'
import Step2NewTemplate from './Step2NewTemplate'
import { AuthContext } from '../../AuthProvider'
import SlideMock from '../../constants/SlideMock'
import TemplateMock from '../../constants/TemplateMock'
import { useWorkspaceContext } from '../../context/workspace/workspace-context'
import { db } from '../../firebase'

function DialogNewTemplate({ visible, setVisible }) {
  // eslint-disable-next-line no-unused-vars
  const [currentStep, setCurrentStep] = useState(1)
  const [templateFormat, setTemplateFormat] = useState('16:9')
  const [templateName, setTemplateName] = useState('')
  const { user } = useContext(AuthContext)
  const navigate = useNavigate()
  const { addNewWorkspace, workspaces } = useWorkspaceContext()

  const [selectedWorkspace, setSelectedWorkspace] = useState(null)

  function pickAFormat(format) {
    setTemplateFormat(format)
  }

  function nextStep() {
    if (!templateFormat) return alert('Select a format')
    setCurrentStep(currentStep + 1)
  }
  function prevStep() {
    setCurrentStep(currentStep - 1)
  }

  console.log(templateFormat)
  const createTemplate = async () => {

    if (!templateName) {
      return alert('Please enter a valid template name')
    }

    if (!templateFormat) {
      return alert('Please select a Template format')
    }
    const templateId = uuidv4()
    const newTemplate = TemplateMock
    let responseAddNewWorkspace

    if (selectedWorkspace === null) {
      const defaultWorkspace = workspaces.find(workspace => workspace.isDefaultWorkspace)

      newTemplate.workspaceId = defaultWorkspace.id
      newTemplate.config.settings.workspace.id = defaultWorkspace.id
      newTemplate.config.settings.workspace.title = defaultWorkspace.name
    } else if (selectedWorkspace.id && selectedWorkspace.name) {
      newTemplate.workspaceId = selectedWorkspace.id
      newTemplate.config.settings.workspace.id = selectedWorkspace.id
      newTemplate.config.settings.workspace.title = selectedWorkspace.name
    } else if (selectedWorkspace.name && !selectedWorkspace.id) {
      responseAddNewWorkspace = await addNewWorkspace(selectedWorkspace.name, user.uid)
      newTemplate.workspaceId = responseAddNewWorkspace
      newTemplate.config.settings.workspace.id = responseAddNewWorkspace
      newTemplate.config.settings.workspace.title = selectedWorkspace.name
    }

    newTemplate.templateId = templateId
    newTemplate.creatorId = user.uid
    newTemplate.config.settings.aspectRatio = templateFormat
    newTemplate.creationTime = new Date()
    newTemplate.updateTime = new Date()
    newTemplate.users.owner.push(user.uid)
    newTemplate.slides.push(SlideMock)

    newTemplate.config.settings.title = templateName
    await setDoc(doc(db, 'templates', templateId), {
      ...newTemplate,
    })

    console.log(newTemplate)

    // Aquí puedes redirigir al usuario a la página de edición con el ID del template
    navigate(`/template-editor/${templateId}`)
  }

  const headerDialog = (
    <>
      <h3 className='text-xl'>Create a new template</h3>
      <p className='text-sm font-light'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer elementum accumsan nisl</p>
    </>
  )
  const footerDialog = (
    <div className='flex'>
      {currentStep === 2 &&
        <button
          className='rounded-md bg-white text-primary border border-primary px-3 py-2 text-sm hover:bg-primary hover:text-white flex items-center'
          onClick={prevStep}
        ><ArrowLeftIcon className='me-1.5 h-4 w-4' aria-hidden='true' /> Back</button>
      }
      <div className='flex ms-auto'>
        <button onClick={() => setVisible(false)} className='rounded-md bg-gray-200 px-3 py-2 text-sm hover:bg-gray-300'>Cancel</button>
        <button
          className='rounded-md bg-primary px-3 py-2 text-sm text-white hover:bg-blue-600 flex items-center'
          onClick={nextStep}
          autoFocus
        >Next <ArrowRightIcon className='ml-1.5 h-4 w-4 text-white' aria-hidden='true' />
        </button>
      </div>
    </div>
  )

  return (
    <Dialog
      header={headerDialog}
      visible={visible}
      style={{ 'max-width': '760px', width: '90vw' }}
      onHide={() => setVisible(false)}
      draggable={false}
      footer={footerDialog}
    >
      {currentStep === 1 &&
        <Step1NewTemplate
          pickAFormat={pickAFormat}
          templateFormat={templateFormat}
        />
      }
      {currentStep === 2 &&
        <Step2NewTemplate
          prevStep={prevStep}
          templateName={templateName}
          setTemplateName={setTemplateName}
          selectedWorkspace={selectedWorkspace}
          setSelectedWorkspace={setSelectedWorkspace}
          createTemplate={createTemplate}/>
      }
    </Dialog>
  )
}

export default DialogNewTemplate
