/* eslint-disable no-useless-return */
/* eslint-disable no-unused-vars */
// services/userService.js

import { collection, query, where, getDocs, getDoc, setDoc, doc, updateDoc, deleteDoc } from 'firebase/firestore'

import { db } from '../firebase'

export const getTemplatesByUser = async userId => {
  const templatesRef = collection(db, 'templates')
  const editorQuery = query(templatesRef, where('users.editor', 'array-contains', userId))
  const ownerQuery = query(templatesRef, where('users.owner', 'array-contains', userId))
  const viewerQuery = query(templatesRef, where('users.viewer', 'array-contains', userId))

  const [editorSnapshot, ownerSnapshot, viewerSnapshot] = await Promise.all([
    getDocs(editorQuery),
    getDocs(ownerQuery),
    getDocs(viewerQuery)
  ])

  const templates = []

  const addToTemplates = (snapshot, role) => {
    snapshot.forEach(doc => {
      const templateData = doc.data()
      const template = {
        id: doc.id,
        ...templateData,
        userRole: role,
      }

      templates.push(template)
    })
  }

  addToTemplates(editorSnapshot, 'editor')
  addToTemplates(ownerSnapshot, 'owner')
  addToTemplates(viewerSnapshot, 'viewer')

  return templates
}

export const getScreensByUser = async userId => {
  const screensRef = collection(db, 'screens')
  const queryRef = query(screensRef, where('user', '==', userId))

  const screensSnapshot = await getDocs(queryRef)

  const screens = []

  screensSnapshot.forEach(doc => {
    const screenData = doc.data()
    const screen = {
      id: doc.id,
      ...screenData,
    }

    screens.push(screen)
  })

  return screens
}

export const fetchTemplateFromFirestore = async (id, userId) => {
  const templateRef = await getDoc(doc(db, 'templates', id))

  if (!templateRef.exists()) return null
  const templateData = templateRef.data()

  if (templateData.userId !== userId) return templateData
  await setDoc(doc(db, 'templates', id), {
    slides: templateData.slides,
    userId,
  })

  return templateData
}

export const saveTemplateToFirestore = async (id, template) => {
  const templateRef = doc(db, 'templates', id)

  await updateDoc(templateRef, template)
}

export const publishTemplateToScreenFirestore = async (id, localTemplate) => {
  const screensIdAttached = localTemplate.screensIdAttached

  await Promise.all(screensIdAttached.map(async screenId => {
    const screenRef = doc(db, 'screens', screenId)
    const screenSnapshot = await getDoc(screenRef)

    if (screenSnapshot.exists()) {
      await updateDoc(screenRef, {
        slides: localTemplate.slides,
      })
    }
  }))
  const templateRef = doc(db, 'templates', id)

  await updateDoc(templateRef, localTemplate)
}

export const fetchSlidesFromFirestore = async id => {
  const templateRef = doc(db, 'templates', id)
  const templateSnapshot = await getDoc(templateRef)

  return templateSnapshot.exists() ? templateSnapshot.data().slides : []
}

export const getAllUserWorkspacesService = async userId => {
  const q = query(collection(db, 'workspaces'), where('userId', '==', userId))

  const querySnapshot = await getDocs(q)

  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
}

export const getUserCurrentWorkspaceService = async (userId, workspaceId) => {
  const q = query(collection(db, 'workspaces'), where('userId', '==', userId))

  const querySnapshot = await getDocs(q)

  for (const doc of querySnapshot.docs) {
    if (doc.id === workspaceId) {
      return { id: doc.id, ...doc.data() }
    }
  }

  return null
}

export const getTemplatesByWorkspaceIdService = async workspaceId => {
  const q = query(collection(db, 'templates'), where('workspaceId', '==', workspaceId))

  const querySnapshot = await getDocs(q)

  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
}

export const getScreensByWorkspaceIdService = async workspaceId => {
  const q = query(collection(db, 'screens'), where('workspaceId', '==', workspaceId))

  const querySnapshot = await getDocs(q)

  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
}

export const deleteWorkspaceService = async (workspaceId, userId) => {
  const workspaceDoc = await getDoc(doc(db, 'workspaces', workspaceId))

  if (!workspaceDoc.exists()) {
    throw new Error('El workspace no existe.')
  }

  const workspaceData = workspaceDoc.data()

  if (workspaceData.userId !== userId) {
    throw new Error('El workspace no pertenece al usuario.')
  }

  if (!workspaceData.isDefaultWorkspace) {
    const workspacesRef = collection(db, 'workspaces')
    const queryRef = query(workspacesRef, where('userId', '==', userId), where('isDefaultWorkspace', '==', true))

    const defaultWorkspaceSnapshot = await getDocs(queryRef)

    if (defaultWorkspaceSnapshot.size !== 1) {
      throw new Error('No se encontró un único workspace por defecto.')
    }

    const defaultWorkspace = defaultWorkspaceSnapshot.docs[0]
    const defaultWorkspaceId = defaultWorkspace.id

    const templatesByWorkspaceRef = collection(db, 'templates')
    const queryTemplatesRef = query(templatesByWorkspaceRef, where('creatorId', '==', userId), where('workspaceId', '==', workspaceId))

    const templatesSnapshot = await getDocs(queryTemplatesRef)

    if (!templatesSnapshot.empty) {
      templatesSnapshot.docs.forEach(async templateDoc => {
        const templateDocRef = doc(
          db,
          'templates',
          templateDoc.id
        )

        await updateDoc(templateDocRef, { workspaceId: defaultWorkspaceId })
        console.log('Actualización exitosa de los templates.')
      })
    }

    // Actualizar screens

    const screensByWorkspaceRef = collection(db, 'screens')
    const queryScreensRef = query(screensByWorkspaceRef, where('user', '==', userId), where('workspaceId', '==', workspaceId))

    const screensSnapshot = await getDocs(queryScreensRef)

    if (!screensSnapshot.empty) {
      screensSnapshot.forEach(async screenDoc => {
        const screenDocRef = doc(
          db,
          'screens',
          screenDoc.id
        )

        await updateDoc(screenDocRef, { workspaceId: defaultWorkspaceId })
      })
    }

    return await deleteDoc(doc(db, 'workspaces', workspaceId))
  } else {
    throw new Error('No se puede eliminar el workspace por defecto.')
  }

}

//! Update workspace properties

export const updateWorkspacePropertiesService = async (workspaceId, workspace) => {
  return await updateDoc(doc(db, 'workspaces', workspaceId), workspace)
}
