import React, { useState, useRef, useEffect } from 'react'

import {
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { InputSwitch } from 'primereact/inputswitch'
// import { Droppable } from 'react-beautiful-dnd'
import { v4 as uuidv4 } from 'uuid'

import CanvasItem from './CanvasItem'
import LayerTool from '../../components/editor-tools/layerTool'
import LeadsTool from '../../components/editor-tools/leadsTool'
import MediaTool from '../../components/editor-tools/mediaTool'
import PlanTool from '../../components/editor-tools/planTool'
import StyleTool from '../../components/editor-tools/styleTool'
import TextTool from '../../components/editor-tools/textTool'
import SlideItemMock from '../../constants/SlideItemMock'
import useScaledDimensions from '../../hooks/useScaledDimensions'

function DroppableCanvas({ slideId, addItem, setSelectedSlide, setLocalTemplate, localTemplate, setSlides, selectedSlide: slide, deleteSlide, selectedSlide }) {

  // const slide = localTemplate.slides.find(slide => slide.slideId === slideId)
  const canvasRef = useRef()

  const [dimensions, scale, setScale] = useScaledDimensions(1)

  function centerScroll(container, content) {
    const containerWidth = container.clientWidth
    const containerHeight = container.clientHeight
    const contentWidth = content.offsetWidth
    const contentHeight = content.offsetHeight

    container.scrollLeft = (contentWidth - containerWidth) / 2
    container.scrollTop = (contentHeight - containerHeight) / 2
  }

  useEffect(() => {
    const container = containerRef.current
    const content = contentRef.current

    centerScroll(container, content)
  }, [scale]) // Asume que 'dimensions' contiene las dimensiones actuales del contenido escalado

  const containerRef = useRef(null)
  const contentRef = useRef(null)

  // Add a check to make sure slide is not undefined
  if (!slide) {
    console.error(`No slide found with ID: ${slideId}`)

    return null
  }
  // Keep the existing definition of slide as it is
  const { items: originalItems } = slide

  // Find the current slide from localTemplate
  const currentSlideFromLocalTemplate = localTemplate.slides.find(s => s.slideId === slideId)

  // If the slide from localTemplate exists, use its items; otherwise, use the original items.
  const items = currentSlideFromLocalTemplate ? currentSlideFromLocalTemplate.items : originalItems

  function changeItemPositionAndSize(elementId, position, size) {
    const updatedTemplate = JSON.parse(JSON.stringify(localTemplate))

    const slide = updatedTemplate.slides.find(slide => slide.slideId === slideId)

    if (!slide) {
      console.error('No matching slide found')

      return
    }

    const layer = slide.items.find(layer => layer.elementId === elementId)

    if (!layer) {
      console.error('No matching layer found')

      return
    }

    // Actualiza la posición y el tamaño en la capa correspondiente
    layer.x = position.x
    layer.y = position.y
    layer.width = size.width ? size.width : '300px'
    layer.height = size.height ? size.height : 'auto'
    console.log('layer', {
      x: position.x,
      y: position.y,
    })

    setLocalTemplate(updatedTemplate)

    setSelectedSlide(prevState => {
      return {
        ...prevState,
        items: prevState.items.map(item => {
          if (item.elementId === elementId) {
            console.log('item', item)

            return { ...item, ...position, ...size }
          }

          return item
        }),
      }
    })
  }

  function changeItemStyles({
    elementId,
    font = 'Arial', // Default values
    fontSize = 16, // Default values
    color = 'red', // Default values
    text = 'sample text',
  }) {

    console.log(color)

    const newItemsStyles = selectedSlide.items.map(item => {
      if (item.elementId === elementId) {
        return {
          ...item,
          config: {
            ...item.config,
            text,
            style: { fontSize, font, color },
          },
        }
      }

      return item
    })

    setSelectedSlide(prevState => {
      return {
        ...prevState,
        items: [...newItemsStyles],
      }
    })

    const selectedSlideTemplateStyled = localTemplate.slides.map(slide => {
      if (slide.slideId === slideId) {
        return {
          ...selectedSlide,
        }
      }

      return slide
    })

    setLocalTemplate({ ...localTemplate, slides: selectedSlideTemplateStyled })

  }

  const handleDragStop = (elementId, x, y) => {
    // Actualizar la posición del item en el slide

    const newItemsDrop = items.map(item => {
      if (item.elementId === elementId) {
        return { ...item, x, y }
      }

      return item
    })

    setSelectedSlide(prevState => {
      return {
        ...prevState,
        items: [...newItemsDrop],
      }
    })

  }

  const handleResizeStop = (elementId, width, height, x, y) => {
    // Actualizar el tamaño y la posición del item en el slide

    const newItemsSize = items.map(item => {
      if (item.elementId === elementId) {
        return { ...item, x, y, width, height }
      }

      return item
    })

    setSelectedSlide(prevState => {
      return {
        ...prevState,
        items: [...newItemsSize],
      }
    })
  }

  function addNewItem(item) {

    const newItem = {
      ...SlideItemMock,
      slideId,
      elementId: uuidv4(),
      x: 0,
      y: 0,
      type: item.type, // Usa el tipo de item
    }

    if (item.type === 'image' && item.url) {

      newItem.src = item.url // Usa la URL del item
      newItem.width = '600px' // Usa un ancho por defecto
      newItem.height = '600px' // Usa un alto por defecto
      newItem.x = 300 // Usa una posición x por defecto
      newItem.y = 200 // Usa una posición y por defecto

    } else if (item.type === 'video' && item.url) {

      newItem.src = item.url // Suponiendo que también usarías una propiedad src para videos
      newItem.width = '600px' // Usa un ancho por defecto
      newItem.height = '600px' // Usa un alto por defecto
      newItem.x = 300 // Usa una posición x por defecto
      newItem.y = 200 // Usa una posición y por defecto

    } else if (item.type === 'url' && item.url) {

      newItem.src = item.url // Suponiendo que tendrías un código de inserción para contenido embebido

    } else if (item.type === 'embed' && item.embedCode) {

      newItem.embedCode = item.embedCode // Suponiendo que tendrías un código de inserción para contenido embebido

    } else if (item.type === 'urlWebsite' && item.url) {

      newItem.src = item.url // Suponiendo que tendrías un código de inserción para contenido embebido

    }

    addItem(newItem)
  }

  /*  const handleDeleteItem = elementId => {
     Eliminar el item del slide

    const updateSlide = localTemplate.slides.map(activeLoopSlide => {
      if (activeLoopSlide.slideId === slideId) {
        return {
          ...activeLoopSlide,
          items: activeLoopSlide.items.filter(item => item.elementId !== elementId),
        }
      }

      return activeLoopSlide
    })

    setLocalTemplate({ ...localTemplate, slides: updateSlide })
  } */

  // Estado para editar y almacenar el título del slide
  const [editingSlideTitle, setEditingSlideTitle] = useState(false)

  // const handleSlideTitleChange = event => {
  //   setSelectedSlide({ ...slide, config: { ...slide.config, settings: { ...slide.config.settings, title: event.target.value } } })
  // }

  const handleSlideTitleChange = event => {
    setLocalTemplate({
      ...localTemplate,
      slides: localTemplate.slides.map(slideItem => {
        if (slideItem.slideId === slide.slideId) {
          return {
            ...slideItem,
            config: {
              ...slideItem.config,
              settings: {
                ...slideItem.config.settings,
                title: event.target.value,
              },
            },
          }
        }

        return slideItem
      }),
    })

    setSlides(prevState => {
      return prevState.map(slideItem => {
        if (slideItem.slideId === slideId) {
          return {
            ...slideItem,
            config: {
              ...slideItem.config,
              settings: {
                ...slideItem.config.settings,
                title: event.target.value,
              },
            },
          }
        }

        return slideItem
      })
    })
    setSelectedSlide(prevState => {
      return {
        ...prevState,
        config: {
          ...prevState.config,
          settings: {
            ...prevState.config.settings,
            title: event.target.value,
          },
        },
      }
    })

  }

  const handleSlideTitleEdit = () => {
    setEditingSlideTitle(!editingSlideTitle)
  }

  // Inputswitch Brand & Slider
  const [checkedBrand, setCheckedBrand] = useState(true)
  const [checkedSlider, setCheckedSlider] = useState(true)

  useEffect(() => {
    // Definir la función de manejo de redimensionado
    function handleResize() {
      if (canvasRef.current) {
        const parentWidth = canvasRef.current.parentElement.offsetWidth
        const aspectRatio = localTemplate.config.settings.aspectRatio === '16:9' ? 16 / 9 : 9 / 16

        // Aseguramos que el lienzo tenga el ancho completo
        canvasRef.current.style.width = `${parentWidth}px`

        // Definimos el alto en base al aspect ratio
        canvasRef.current.style.height = `${parentWidth / aspectRatio}px`

        // Calculamos el nuevo factor de escala
        // const newScaleFactor = parentWidth / (aspectRatio === 16 / 9 ? 1920 : 1080)
      }
    }

    // Añadir el event listener
    window.addEventListener('resize', handleResize)

    // Invocar la función de manejo de redimensionado inmediatamente para obtener el tamaño inicial
    handleResize()

    // Eliminar el event listener cuando el componente se desmonte
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [localTemplate])

  useEffect(() => {
    console.log('localTemplate has been updated:', localTemplate)

    // Check if slides exist and has at least one slide
    if (localTemplate.slides && localTemplate.slides.length > 0) {
      // Access the first slide
      const firstSlide = localTemplate.slides[0]

      // Iterate over the items of the first slide and log the elementId and their index
      firstSlide.items.forEach((item, index) => {
        console.log(`Item index: ${index}, elementId: ${item.elementId}`)
      })

      console.log('slide', items)
    }
  }, [localTemplate, items])

  return (
    <>
      <div className='rounded-lg bg-white flex items-center justify-between h-14 mb-4 px-6'>
        <div className='flex'>
          { editingSlideTitle ? (
            <input
              className='block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
              type='text'
              value={slide.config.settings.title}
              onChange={handleSlideTitleChange}
            />
          )
            : <span className='font-semibold text-sm cursor-pointer' onClick={handleSlideTitleEdit}>{slide.config.settings.title}</span>
          }
          <button type='button' className='ms-2' onClick={handleSlideTitleEdit}>
            <PencilIcon className='h-5 w-5 text-gray-300 hover:text-gray-400' aria-hidden='true' />
          </button>
        </div>
        <div className='flex'>
          <LayerTool setLocalTemplate={setLocalTemplate} localTemplate={localTemplate} slideId={slideId}/>
          <PlanTool setLocalTemplate={setLocalTemplate} localTemplate={localTemplate} slideId={slideId} slide={slide}/>
          <StyleTool />
          {/* <button className='flex flex-col items-center px-2 hover:text-blue-500' onClick={addNewItem} >
            <span className='text-xl font-serif leading-none mb-1'>T</span>
            <span className='text-[10px] leading-none'>Text</span>
          </button> */}
          <TextTool addNewItem={addNewItem} />
          <MediaTool addNewItem={addNewItem} />
          <LeadsTool setLocalTemplate={setLocalTemplate} localTemplate={localTemplate} slideId={slideId}/>
        </div>
        <div className='flex'>
          <div className='flex items-center'>
            <span className='text-sm text-gray-400'>Show:</span>
            <div className='flex items-center ms-4'>
              <InputSwitch checked={checkedBrand} onChange={e => setCheckedBrand(e.value)} />
              <span className='text-xs ms-2'>Brand</span>
            </div>
            <div className='flex items-center ms-4'>
              <InputSwitch checked={checkedSlider} onChange={e => setCheckedSlider(e.value)} />
              <span className='text-xs ms-2'>Slider</span>
            </div>
            <div className='flex items-center ms-4'>
              <input
                type='range'
                min='0.1'
                max='3'
                step='0.001'
                value={scale}
                onChange={e => setScale(parseFloat(e.target.value))}
              />
              <span className='text-xs ms-2'>{Math.round(scale * 100)}%</span>
              <button onClick={() => setScale(1)}>Reset</button>
            </div>

          </div>
          {/* Separator */}
          <div className='h-6 w-px bg-gray-200 mx-6' aria-hidden='true' />
          <button className='text-red-500 hover:text-red-600' onClick={() => deleteSlide(slide.slideId)} >
            <TrashIcon className='h-5 w-5' aria-hidden='true' />
          </button>
        </div>
      </div>
      <div ref={containerRef} className='overflow-scroll relative h-full rounded-lg'>
        <div className='flex absolute min-h-full min-w-full'>
          <div className='flex flex-1 relative scale-100 min-h-0 overflow-hidden'>
            <div ref={contentRef} className='flex flex-row shrink-0 justify-center m-auto relative aspect-video'
              style={{ width: `${dimensions.width}px`, height: `${dimensions.height}px` }}
            >
              <div
                className='bg-white absolute top-0 left-0'
                style={{
                  transformOrigin: 'top left',
                  width: '1920px',
                  height: '1080px',
                  transform: `scale(${scale})`,
                }}
              >
                {items?.map((item, index) => (
                  <CanvasItem
                    key={item.elementId}
                    item={item}
                    index={index}
                    scale={scale}
                    onDragStop={handleDragStop}
                    onResizeStop={handleResizeStop}
                    changeItemStyles={changeItemStyles}
                    changeItemPositionAndSize={changeItemPositionAndSize}
                    setLocalTemplate={setLocalTemplate}
                    localTemplate={localTemplate}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DroppableCanvas
