import React, { useRef, useState } from 'react'

import {
  PaintBrushIcon,
} from '@heroicons/react/24/outline'
import { Dropdown } from 'primereact/dropdown'
import { OverlayPanel } from 'primereact/overlaypanel'

import cairoImage from '../../assets/images/color-cairo.png'
import customizeImage from '../../assets/images/color-custom.png'
import londonImage from '../../assets/images/color-london.png'
import miamiImage from '../../assets/images/color-miami.png'
import newYorkImage from '../../assets/images/color-ny.png'

function StyleTool() {

  const stylePanel = useRef(null)
  const [selectedColor, setSelectedColor] = useState(null)
  const [selectedType, setSelectedType] = useState(null)

  // Typeface
  const types = [
    { name: 'Poppins', code: 'poppins' },
    { name: 'Roboto', code: 'roboto' }
  ]

  // Colors
  const colors = [
    { name: 'Miami', image: miamiImage, code: 'miami' },
    { name: 'Cairo', image: cairoImage, code: 'cairo' },
    { name: 'London', image: londonImage, code: 'london' },
    { name: 'New York', image: newYorkImage, code: 'ny' },
    { name: 'Customize', image: customizeImage, code: 'custom' }
  ]

  const selectedColorTemplate = (option, props) => {
    if (option) {
      return (
        <div className='flex align-items-center justify-between'>
          <img alt={option.name} src={option.image} className='ms-4' />
        </div>
      )
    }

    return <span>{props.placeholder}</span>
  }

  const colorOptionTemplate = option => {
    return (
      <div className='flex align-items-center justify-between'>
        <div>{option.name}</div>
        <img alt={option.name} src={option.image} className='ms-4' />
      </div>
    )
  }

  return (
    <>
      <button className='flex flex-col items-center px-2 hover:text-primary' onClick={e => stylePanel.current.toggle(e)} aria-haspopup aria-controls='overlay_panel' >
        <PaintBrushIcon className='h-5 w-5 mb-1' aria-hidden='true' />
        <span className='text-[10px] leading-none'>Style</span>
      </button>
      <OverlayPanel ref={stylePanel} showCloseIcon id='overlay_panel' style={{ width: '300px' }} className='overlaypanel-demo'>
        <div className='px-6 py-3 border-b border-gray-200 text-sm font-semibold'>Style</div>
        <div className='px-6 py-4'>
          <div className='flex items-center'>
            <span className='text-sm'>Typeface</span>
            <Dropdown
              value={selectedType}
              onChange={e => setSelectedType(e.value)}
              options={types}
              optionLabel='name'
              placeholder='Select typeface'
              className='p-dropdown-xs ms-auto'
              panelClassName='p-dropdown-panel-xs' />
          </div>
          <div className='flex items-center'>
            <span className='text-sm'>Color</span>
            <Dropdown
              value={selectedColor}
              onChange={e => setSelectedColor(e.value)}
              options={colors}
              optionLabel='name'
              placeholder='Select color'
              valueTemplate={selectedColorTemplate}
              itemTemplate={colorOptionTemplate}
              className='p-dropdown-xs ms-auto'
              panelClassName='p-dropdown-panel-xs' />
          </div>
        </div>
      </OverlayPanel>
    </>
  )
}

export default StyleTool
