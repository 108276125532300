import React, { useState, useEffect } from 'react'

import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

import { getUnsplashImages } from './../../../services/ApiService'

function UnsplashComponent({ addNewItem }) {
  const [images, setImages] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [actualPage, setActualPage] = useState(1)

  const fetchImages = (page = 1, term = searchTerm) => {
    getUnsplashImages(term, page)
      .then(response => {
        if (page === 1) {
          setImages(response.data.results)
        } else {
          setImages(prevImages => [...prevImages, ...response.data.results])
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  useEffect(() => {
    fetchImages(1, 'backgrounds') // Pasando 'backgrounds' directamente
  }, []) // Se ejecutará una vez, similar a ngOnInit

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return
      setActualPage(prevPage => prevPage + 1)
      fetchImages(actualPage + 1)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [actualPage])

  return (
    <>
      <div className='relative mb-4 rounded-full'>
        <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
          <MagnifyingGlassIcon className='h-5 w-5 text-gray-50' aria-hidden='true' />
        </div>
        <input
          type='text'
          name='search'
          id='search'
          className='block w-full rounded-full border-0 py-1.5 pl-10 ring-1 ring-inset ring-gray-50 placeholder:text-gray-50 focus:ring-inset focus:ring-gray-600 sm:text-xs sm:leading-6'
          placeholder='Search'
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          onKeyPress={e => e.key === 'Enter' && fetchImages()}
        />
      </div>
      <div className='inline-grid grid-cols-3 gap-4'>
        {images && images.map((image, index) => (
          <div key={index} className='w-full aspect-[4/3] rounded-lg overflow-hidden' onClick={() => addNewItem({
            type: 'image',
            url: image.urls.full, // o image.urls.full si prefieres la versión de alta resolución
          })}>
            <img className='w-full h-full object-cover' src={image.urls.small} alt={image.alt_description || 'Unsplash img'} />
          </div>
        ))}
      </div>
    </>
  )
}

export default UnsplashComponent
