import React from 'react'

import {
  ArrowRightIcon,
} from '@heroicons/react/24/outline'
import { Dialog } from 'primereact/dialog'

function DialogAICreateSlide({ visible, setVisible }) {

  const headerContent = (
    <>
      <h3 className='text-xl'>Create your content with AI</h3>
      <p className='text-sm font-light'>ej. &quot;Create content for a event location.&quot;</p>
    </>
  )

  const footerContent = (
    <div className='flex flex-row-reverse'>
      <button className='flex items-center rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'>
        Create
        <ArrowRightIcon className='ml-1.5 h-5 w-5 text-white' aria-hidden='true' />
      </button>
      <button onClick={() => setVisible(false)} className='rounded-md bg-gray-200 px-3 py-2 text-sm hover:bg-gray-300'>
        Cancel
      </button>
    </div>
  )

  return (
    <Dialog visible={visible} style={{ 'max-width': '465px', width: '90vw' }} onHide={() => setVisible(false)} header={headerContent} footer={footerContent}>
      <div className='flex'>
        <label htmlFor='search-user' className='sr-only'>
          Use your prompt
        </label>
        <textarea
          rows='5'
          id='search-user'
          name='user'
          type='text'
          autoComplete='user'
          required
          className='min-w-0 flex-auto rounded-l-lg border-0 px-3.5 py-2 ring-1 ring-inset ring-gray-200 placeholder:text-gray-50 sm:text-sm sm:leading-6'
          placeholder='Use your prompt'
        />
      </div>
    </Dialog>
  )
}

export default DialogAICreateSlide
