/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'

import { useParams } from 'react-router-dom'

function UrlComponent({ slideId, setLocalTemplate, localTemplate, enabledQR }) {
  const [url, setUrl] = useState('')
  const [newLocalTemplate, setNewLocalTemplate] = useState(localTemplate)
  const { id: templateId } = useParams()

  const handleUrlQrChange = newUrl => {
    const newLocalTemplate = { ...localTemplate }
    const currentSlide = newLocalTemplate.slides.find(slide => slide.slideId === slideId)

    if (currentSlide) {
      currentSlide.config = currentSlide.config || {}
      currentSlide.config.settings = currentSlide.config.settings || {}
      currentSlide.config.settings.leads = currentSlide.config.settings.leads || {}
      currentSlide.config.settings.leads.settings = currentSlide.config.settings.leads.settings || {}

      // Set the urlQr
      currentSlide.config.settings.leads.settings.urlQr = newUrl

      // Update the enabled property based on enabledQR
      currentSlide.config.settings.leads.enabled = enabledQR

      setLocalTemplate(newLocalTemplate)
    }
  }

  const handleAdd = () => {
    handleUrlQrChange(url)
  }

  useEffect(() => {
    const currentSlide = localTemplate.slides.find(slide => slide.slideId === slideId)

    if (currentSlide && currentSlide.config && currentSlide.config.settings && currentSlide.config.settings.leads && currentSlide.config.settings.leads.settings) {
      const savedUrl = currentSlide.config.settings.leads.settings.urlQr

      if (savedUrl) {
        setUrl(savedUrl)
      }
    }
  }, [localTemplate, slideId])

  return (
    <>
      <div className='pt-5 flex rounded-full'>
        <span className='inline-flex items-center rounded-l-md border border-r-0 border-gray-20 bg-gray-20 px-3 text-sm'>
          Your URL:
        </span>
        <input
          type='text'
          name='company-website'
          id='company-website'
          value={url}
          onChange={e => setUrl(e.target.value)}
          className='block w-full min-w-0 flex-1 rounded-none rounded-r-md border py-1.5 border border-gray-20 placeholder:text-gray-300 focus:border-gray-20 text-sm'
          placeholder='www.example.com'
        />
      </div>
      <div className='pt-5'>
        <button onClick={handleAdd} className='bg-gray-20 text-sm border border-gray-20 py-1.5 px-4 rounded-full'>
          Add
        </button>
      </div>
    </>
  )
}

export default UrlComponent
