import { Dialog } from 'primereact/dialog'

const CustomDialog = ({ header, visible, onHide, children }) => {
  return (
    <Dialog
      header={header}
      visible={visible}
      onHide={onHide}
      style={{ width: '50vw' }}
      draggable={false}
    >
      {children}
    </Dialog>
  )
}

export default CustomDialog
