import React, { useRef, useState, useEffect } from 'react'

import { OverlayPanel } from 'primereact/overlaypanel'
import { Rnd } from 'react-rnd'

import EmbedCanvasItem from './EmbedCanvasItem'
import MediaCanvasItem from './MediaCanvasItem'
import SocialsCanvasItem from './SocialsCanvas'
import TextCanvasItem from './TextCanvasItem'
import TextEditorBar from './TextEditorBar'
import VideoCanvasItem from './VideoCanvasItem'

function CanvasItem({ item, onDragStop, onResizeStop, changeItemStyles, handleDeleteItem, setLocalTemplate, localTemplate, changeItemPositionAndSize, scale }) {

  // const [showEditor, setShowEditor] = useState(false)
  // const [showBorderEditing, setShowBorderEditing] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [isEditable, setIsEditable] = useState(false)

  // clases para los handlers de redimensionamiento
  const handleClasses = {
    left: 'c-resize-handle c-resize-handle--x c-resize-handle--l',
    right: 'c-resize-handle c-resize-handle--x c-resize-handle--r',
    top: 'c-resize-handle c-resize-handle--y c-resize-handle--t',
    bottom: 'c-resize-handle c-resize-handle--y c-resize-handle--b',
    topRight: 'c-resize-handle c-resize-handle--xy c-resize-handle--tr',
    bottomRight: 'c-resize-handle c-resize-handle--xy c-resize-handle--br',
    bottomLeft: 'c-resize-handle c-resize-handle--xy c-resize-handle--bl',
    topLeft: 'c-resize-handle c-resize-handle--xy c-resize-handle--tl',
  }

  // opciones de redimensionamiento
  const resizingOptions = item.type === 'text' ? {
    top: false,
    right: true,
    bottom: false,
    left: true,
    topRight: false,
    bottomRight: false,
    bottomLeft: false,
    topLeft: false,
  } : {
    top: true,
    right: true,
    bottom: true,
    left: true,
    topRight: true,
    bottomRight: true,
    bottomLeft: true,
    topLeft: true,
  }

  const {
    elementId,
    config: { style },
  } = item
  const rndRef = useRef()
  const editorRef = useRef()
  const [fontSize, setFontSize] = useState(style.fontSize)
  const [position, setPosition] = useState({ x: item.x, y: item.y })
  const [isTextEditable, setIsTextEditable] = useState(false)
  const [color, setColor] = useState(style.color)
  const [size, setSize] = useState({ width: item.width, height: item.height })

  // Manejador de eventos para cerrar el editor de texto
  console.log(item)

  useEffect(() => {
    const handleOutsideClick = event => {
      console.log(isEditable)
      setIsActive(false)
      setIsEditable(false)

      if (
        isActive &&
        rndRef.current &&
        !rndRef.current.contains(event.target) &&
        editorRef.current &&
        !editorRef.current.contains(event.target)
      ) {
        // setShowEditor(false)
        // setShowBorderEditing(false)
        // setIsEditable(false)
      }
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }

  }, [isActive])

  const handleElementClick = e => {
    e.stopPropagation() // Esto evita que el evento se propague a listeners de eventos superiores

    console.log('click')
    console.log(isEditable)

    // // setShowEditor(true)
    // setShowBorderEditing(true) // Muestra el borde del elemento
    // setIsEditable(true) // Activa la edición del texto
    // console.log('click')
    // console.log(isEditable)

    // if (!isActive) {
    setIsActive(true)
    op.current.show(e) // Muestra el panel de edición
    setIsTextEditable(true)
    // op.current.hide()
    // } else if (isActive && !isEditable) {
    //   setIsEditable(true)
    //   // op.current.show(e) // Muestra el panel de edición
    // }
  }

  const handleDrag = (e, { x, y }) => {
    console.log('drag')
  }

  const handleDragStart = (e, { x, y }) => {
    console.log('dragStart')
    setIsActive(false)
    setIsEditable(false)
  }

  const handleDragStop = (e, { x, y }) => {
    console.log('dragStop')
    setIsActive(true)
    setIsEditable(false)
    setPosition({ x, y })
    changeItemPositionAndSize(item.elementId, { x, y }, size)
  }

  const op = useRef(null)

  return (

    <>
      <Rnd
        ref={c => {
          if (c) {
            rndRef.current = c.getSelfElement()
          }
        }}
        className={`break-words border-solid border border-transparent hover:border-blue-500 ${isActive ? 'active !border-blue-500' : ''}`}
        scale={scale} // Escala del elemento
        size={size} // Tamaño del elemento
        position={position} // Posición del elemento
        onDragStart={handleDragStart} // Manejador de evento cuando se inicia el arrastre
        onDrag={handleDrag} // Manejador de evento cuando se arrastra el elemento
        onDragStop={handleDragStop} // Manejador de evento cuando se detiene el arrastre
        onResizeStop={(
          e,
          direction,
          ref,
          delta,
          position // Manejador de evento cuando se detiene el redimensionamiento
        ) => {
          onResizeStop(
            item.elementId,
            ref.style.width,
            ref.style.height,
            position.x,
            position.y
          )
          setSize({ width: ref.style.width, height: ref.style.height })
          changeItemPositionAndSize(item.elementId, position, { width: ref.style.width, height: ref.style.height })
          // window.localStorage.setItem(`size${item.elementId}`, JSON.stringify({ width: ref.style.width, height: ref.style.height }))
        }
        }
        onClick={e => {
          handleElementClick(e)
        }}
        bounds={'parent'} // Límites del elemento
        // disableDragging={isEditable} // Deshabilita el arrastre del elemento
        resizeHandleClasses={handleClasses} // Clases para los handlers de redimensionamiento
        enableResizing={resizingOptions} // Opciones de redimensionamiento
      >

        {item.type === 'text' && (
          <TextCanvasItem
            item={item}
            changeItemStyles={changeItemStyles}
            editable={isEditable}
            fontSize={fontSize}
            color={color}
            // setShowBorderEditing={setShowBorderEditing}
            setLocalTemplate={setLocalTemplate}
            localTemplate={localTemplate}
            isTextEditable={isTextEditable}
            setIsTextEditable={setIsTextEditable}
          />
        )}
        {item.type === 'image' && <MediaCanvasItem item={item} />}
        {item.type === 'video' && <VideoCanvasItem item={item} />}
        {(item.type === 'embed' || item.type === 'urlWebsite') && <EmbedCanvasItem item={item} />}
        {item.type === 'url' && <SocialsCanvasItem item={item} />}
      </Rnd>
      <OverlayPanel ref={op} my='right top' at='right top'>
        <TextEditorBar
          fontSize={fontSize}
          setFontSize={setFontSize}
          elementId={elementId}
          changeItemStyles={changeItemStyles}
          setIsEditable={setIsEditable}
          editable={isEditable}
          color={color}
          setColor={setColor}
          handleDeleteItem={handleDeleteItem}
        />
      </OverlayPanel>
    </>
  )
}

export default CanvasItem
