import { useState } from 'react'

import FormatCardCustomOption from './FormatCardCustomOption'
import FormatCardOption from './FormatCardOption'
import DisplayThemes from '../DisplayThemes'

export const generalCardStyles = 'rounded-md border border-solid shadow-3xl py-1.5 cursor-pointer flex items-center flex-1 self-stretch h-full'
export const inactiveCardStyles = 'border-white bg-white'
export const activeCardStyles = 'border-blue-500 bg-blue-100'

const formatOptions = [
  {
    label: '16:9',
    value: '16:9',
    text: 'Landscape',
    icon: <span className='w-6 aspect-video border border-primary rounded-sm bg-blue-50' />,
  },
  {
    label: '9:16',
    value: '9:16',
    text: 'Portrait',
    icon: <span className='w-3.5 aspect-[9/16] border border-primary rounded-sm bg-blue-50' />,
  },
  {
    label: '4:3',
    value: '4:3',
    text: 'Square',
    icon: <span className='w-3.5 aspect-square border border-primary rounded-sm bg-blue-50' />,
  },
  {
    label: 'Custom',
    value: 'custom',
    text: 'Customize',
    icon: <span className='w-3.5 aspect-square border border-primary rounded-sm bg-blue-50' />,
  }
]

export default function Step1NewTemplate({ pickAFormat, templateFormat }) {

  const [isCustomActive, setIsCustomActive] = useState(false)

  return (
    <>
      <div>
        <label
          htmlFor='format'
          className='block text-xs font-medium mb-4'
        >
          Select size
        </label>
        <div className='grid grid-cols-4 gap-2' >
          {formatOptions.map((format, index) => {
            if (format.value === 'custom') {
              return <FormatCardCustomOption
                {...format}
                key={index}
                pickAFormat={pickAFormat}
                templateFormat={templateFormat}
                isCustomActive={isCustomActive}
                setIsCustomActive={setIsCustomActive}
              />
            }

            return <FormatCardOption
              {...format}
              key={index}
              pickAFormat={pickAFormat}
              templateFormat={templateFormat}
              setIsCustomActive={setIsCustomActive}
            />
          })}
        </div>
      </div>
      <DisplayThemes />
    </>
  )
}
