import { useContext } from 'react'

import { AuthContext } from '../AuthProvider'

export function usePermissions() {
  const { permissions } = useContext(AuthContext)

  function hasPermission(permission) {
    console.log('hasPermission:', permission)
    console.log(permissions.includes(permission))

    return permissions && permissions.includes(permission)
  }

  return { hasPermission }
}
