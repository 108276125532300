import { Fragment, useContext, useEffect, useState } from 'react'

import { Dialog, Transition } from '@headlessui/react'
import {
  PlusCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { InputText } from 'primereact/inputtext'
import { Link, NavLink } from 'react-router-dom'

import { AuthContext } from '../AuthProvider'
import CustomDialog from '../components/dialog/CustomDialog'
import { useWorkspaceContext } from '../context/workspace/workspace-context'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Sidebar = ({ navigation, sidebarOpen, setSidebarOpen, announcementVisible }) => {

  const { workspaces, addNewWorkspace, getAllUserWorkspaces } = useWorkspaceContext()
  const { user } = useContext(AuthContext)

  const [showCreateWorkspace, setShowCreateWorkspace] = useState(false)
  const [inputValue, setInputValue] = useState('')

  console.log('Log para verificar un posible loop', workspaces)
  useEffect(() => {
    getAllUserWorkspaces(user.uid)
  }, [user])

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as='div' className='relative z-50 lg:hidden' onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-900/80' />
          </Transition.Child>

          <div className='fixed inset-0 flex'>
            <Transition.Child
              as={Fragment}
              enter='transition ease-in-out duration-300 transform'
              enterFrom='-translate-x-full'
              enterTo='translate-x-0'
              leave='transition ease-in-out duration-300 transform'
              leaveFrom='translate-x-0'
              leaveTo='-translate-x-full'
            >
              <Dialog.Panel className='relative mr-16 flex w-full max-w-xs flex-1'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-in-out duration-300'
                  enterFrom='opacity-0'
                  enterTo='opacity-100'
                  leave='ease-in-out duration-300'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <div className='absolute left-full top-0 flex w-16 justify-center pt-5'>
                    <button type='button' className='-m-2.5 p-2.5' onClick={() => setSidebarOpen(false)}>
                      <span className='sr-only'>Close sidebar</span>
                      <XMarkIcon className='h-6 w-6 text-white' aria-hidden='true' />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className='flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2'>
                  <div className='flex h-16 shrink-0 items-center'>
                    <img
                      className='h-8 w-auto'
                      src='https://tailwindui.com/img/logos/mark.svg?color=blue&shade=600'
                      alt='Your Company'
                    />
                  </div>
                  <nav className='flex flex-1 flex-col'>
                    <ul role='list' className='flex flex-1 flex-col gap-y-7'>
                      <li>
                        <ul role='list' className='-mx-2 space-y-1'>
                          {navigation.map(item => (
                            <NavLink
                              key={item.name}
                              to={item.href}
                              activeClassName='bg-blue-20 text-primary'
                              className={({ isActive }) => isActive ? 'text-gray-700 hover:text-primary hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold' : 'bg-gray-50 text-primary group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'}
                            >
                              {({ isActive }) => (
                                <>
                                  <item.icon
                                    className={classNames(
                                      isActive ? 'text-primary' : 'text-dark',
                                      'w-5 h-5 shrink-0'
                                    )}
                                    aria-hidden='true'
                                  />
                                  {item.name}
                                </>)}
                            </NavLink>
                          ))}
                        </ul>
                      </li>
                      <li className='mt-auto flex flex-col justify-end mx-4 grow'>
                        <div className='text-sm font-bold leading-6 border-t border-t-gray-100 py-4'>My workspaces</div>
                        <p className='text-sm'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        <ul className='overflow-y-auto' role='list'>
                          {workspaces?.map(team => (
                            <li key={team.name} className='leading-5'>
                              <a href={team.href} className='text-sm text-primary hover:text-blue-600 truncate' >#{team.name}</a>
                            </li>
                          ))}
                        </ul>
                        { !showCreateWorkspace ? <button
                          type='button'
                          className=' mt-4 inline-flex items-center justify-center rounded-md bg-primary px-3 py-2 text-sm w-full text-white hover:bg-blue-600'
                          onClick={() => {
                            console.log('click')
                            setShowCreateWorkspace(true)
                          }}
                        >
                          <PlusCircleIcon className='-ml-0.5 mr-1.5 h-5 w-5' aria-hidden='true' />
                          Create new workspace
                        </button> : <span className='p-input-icon-right'>
                          <i className='pi pi-spin pi-spinner' />
                          <InputText className='w-full' value={inputValue} onChange={e => setInputValue(e.target.value)} />
                        </span> }
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <div className={`hidden bottom-0 lg:fixed lg:z-40 lg:flex lg:w-72 lg:flex-col ${announcementVisible ? 'top-[108px]' : 'lg:top-16'}`}>
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className='flex grow flex-col gap-y-5 overflow-y-auto border-t border-gray-100 bg-white px-6 py-4'>
          <nav className='flex flex-1 flex-col'>
            <ul role='list' className='flex flex-1 flex-col gap-y-7'>
              <li>
                <ul role='list' className='-mx-2 space-y-1'>
                  {navigation.map(item => (
                    <li key={item.name}>
                      <NavLink
                        to={item.href}
                        className={({ isActive }) => isActive ? 'bg-blue-20 text-primary  group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold' : ' text-dark hover:bg-gray-10 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'}
                      >
                        {({ isActive }) => (
                          <>
                            <item.icon
                              className={classNames(
                                isActive ? 'text-primary' : 'text-dark',
                                'w-5 h-5 shrink-0'
                              )}
                              aria-hidden='true'
                            />
                            {item.name}
                          </>)}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </li>
              <li className='flex flex-col mx-4 grow'>
                <div className='text-sm font-bold leading-6 border-t border-t-gray-100 py-4 mt-auto'>My workspaces</div>
                <p className='text-sm mb-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                <ul role='list'>
                  {workspaces?.map(workspace => (
                    <li key={workspace.name} className='leading-5'>
                      <Link to={`/workspace/${workspace.id}`} className='text-sm text-primary hover:text-blue-600 truncate' >#{workspace.name}</Link>
                    </li>
                  ))}
                </ul>
                <button
                  type='button'
                  className=' mt-4 inline-flex items-center justify-center rounded-md bg-primary px-3 py-2 text-sm w-full text-white hover:bg-blue-600'
                  onClick={() => {
                    setShowCreateWorkspace(true)
                  }}
                >
                  <PlusCircleIcon className='-ml-0.5 mr-1.5 h-5 w-5' aria-hidden='true' />
                  Create new workspace
                </button>
                <CustomDialog visible={showCreateWorkspace} onHide={() => setShowCreateWorkspace(false)} header={'Create new workspace'} >
                  <label htmlFor='workspaceName' className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>Workspace name</label>
                  <InputText className='w-1/2 ' value={inputValue} onChange={e => setInputValue(e.target.value)} />
                  <div className='mt-3' >
                    <button type='button' className='btn-submit' onClick={() => {
                      if (!inputValue) return
                      addNewWorkspace(inputValue, user.uid)
                      setInputValue('')
                      setShowCreateWorkspace(false)
                    }} >Create</button>
                  </div>
                </CustomDialog>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  )
}

export default Sidebar
