import { useState, useEffect } from 'react'

import { RadioGroup } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'
import { loadStripe } from '@stripe/stripe-js'

import Header from '../layout/Header'

const stripePromise = loadStripe('pk_test_BjSfIHsvWGBUG372UA4SZAzr')

async function fetchProductsAndPrices() {
  const stripe = await stripePromise

  console.log('stripe', stripe)
  // const products = await stripe.products.list({ expand: ['data.product'] })
  // const prices = await stripe.prices.list()

  // Aquí puedes transformar estos datos al formato que tu interfaz necesita
  // Para simplicidad, estoy suponiendo que tus productos en Stripe tienen el mismo formato que tu estructura actual
  //   return prices.data.map(price => {
  //     const product = products.data.find(p => p.id === price.product)

//     return {
//       id: product.id,
//       name: product.name,
//       description: product.description,
//       price: {
//         monthly: price.recurring.interval === 'month' ? `$${price.unit_amount_decimal / 100}` : null,
//         annually: price.recurring.interval === 'year' ? `$${price.unit_amount_decimal / 100}` : null,
//       },
//       href: '#', // Esto lo reemplazaremos más adelante
//       features: [], // Supongo que tendrías que extraer estas características de alguna propiedad de los productos en Stripe
//       mostPopular: false, // Esto podría ser una etiqueta o metadato en Stripe para identificarlo
//     }
//   })
}

async function handleCheckout(priceId) {
  const stripe = await stripePromise
  const session = await stripe.checkout.sessions.create({
    payment_method_types: ['card'],
    line_items: [{
      price: priceId,
      quantity: 1,
    }],
    mode: 'subscription',
    success_url: 'YOUR_SUCCESS_URL',
    cancel_url: 'YOUR_CANCEL_URL',
  })

  await stripe.redirectToCheckout({ sessionId: session.id })
}

const frequencies = [
  { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
  { value: 'annually', label: 'Annually', priceSuffix: '/year' }
]
const tiers = [
  {
    name: 'Freelancer',
    id: 'tier-freelancer',
    href: '#',
    price: { monthly: '$15', annually: '$144' },
    description: 'The essentials to provide your best work for clients.',
    features: ['5 products', 'Up to 1,000 subscribers', 'Basic analytics', '48-hour support response time'],
    mostPopular: false,
  },
  {
    name: 'Startup',
    id: 'tier-startup',
    href: '#',
    price: { monthly: '$30', annually: '$288' },
    description: 'A plan that scales with your rapidly growing business.',
    features: [
      '25 products',
      'Up to 10,000 subscribers',
      'Advanced analytics',
      '24-hour support response time',
      'Marketing automations'
    ],
    mostPopular: true,
  },
  {
    name: 'Enterprise',
    id: 'tier-enterprise',
    href: '#',
    price: { monthly: '$60', annually: '$576' },
    description: 'Dedicated support and infrastructure for your company.',
    features: [
      'Unlimited products',
      'Unlimited subscribers',
      'Advanced analytics',
      '1-hour, dedicated support response time',
      'Marketing automations',
      'Custom reporting tools'
    ],
    mostPopular: false,
  }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function PricingPage() {
  const [frequency, setFrequency] = useState(frequencies[0])
  const [loading, setLoading] = useState(true)
  // const [stripeTiers, setStripeTiers] = useState([])

  useEffect(() => {
    async function fetchData() {
      const data = await fetchProductsAndPrices()

      console.log('data', data)
      // setStripeTiers(data)
      setLoading(false)
    }
    fetchData()
  }, [])

  return (
    <>
      <Header setSidebarOpen={false} announcementVisible={false} />
      <div className='bg-white py-24 sm:py-32'>
        <div className='mx-auto max-w-7xl px-6 lg:px-8'>
          <div className='mx-auto max-w-4xl text-center'>
            <h2 className='text-base font-semibold leading-7 text-indigo-600'>Pricing</h2>
            <p className='mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl'>
              Pricing plans for teams of&nbsp;all&nbsp;sizes
            </p>
          </div>
          <p className='mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600'>
            Choose an affordable plan that’s packed with the best features for engaging your audience, creating customer
            loyalty, and driving sales.
          </p>
          <div className='mt-16 flex justify-center'>
            <RadioGroup
              value={frequency}
              onChange={setFrequency}
              className='grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200'
            >
              <RadioGroup.Label className='sr-only'>Payment frequency</RadioGroup.Label>
              {frequencies.map(option => (
                <RadioGroup.Option
                  key={option.value}
                  value={option}
                  className={({ checked }) =>
                    classNames(
                      checked ? 'bg-indigo-600 text-white' : 'text-gray-500',
                      'cursor-pointer rounded-full px-2.5 py-1'
                    )
                  }
                >
                  <span>{option.label}</span>
                </RadioGroup.Option>
              ))}
            </RadioGroup>
          </div>
          <div className='isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3'>
            {loading ? <p>Loading...</p> : tiers.map(tier => (
              <div
                key={tier.id}
                className={classNames(
                  tier.mostPopular ? 'ring-2 ring-indigo-600' : 'ring-1 ring-gray-200',
                  'rounded-3xl p-8 xl:p-10'
                )}
              >
                <div className='flex items-center justify-between gap-x-4'>
                  <h3
                    id={tier.id}
                    className={classNames(
                      tier.mostPopular ? 'text-indigo-600' : 'text-gray-900',
                      'text-lg font-semibold leading-8'
                    )}
                  >
                    {tier.name}
                  </h3>
                  {tier.mostPopular ? (
                    <p className='rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600'>
                      Most popular
                    </p>
                  ) : null}
                </div>
                <p className='mt-4 text-sm leading-6 text-gray-600'>{tier.description}</p>
                <p className='mt-6 flex items-baseline gap-x-1'>
                  <span className='text-4xl font-bold tracking-tight text-gray-900'>{tier.price[frequency.value]}</span>
                  <span className='text-sm font-semibold leading-6 text-gray-600'>{frequency.priceSuffix}</span>
                </p>
                <button onClick={() => handleCheckout(tier.id)}
                  aria-describedby={tier.id}
                  className={classNames(
                    tier.mostPopular
                      ? 'bg-indigo-600 text-white shadow-sm hover:bg-indigo-500'
                      : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
                    'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                  )}
                >
                  Buy plan
                </button>
                <ul role='list' className='mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10'>
                  {tier.features.map(feature => (
                    <li key={feature} className='flex gap-x-3'>
                      <CheckIcon className='h-6 w-5 flex-none text-indigo-600' aria-hidden='true' />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
