import { v4 as uuidv4 } from 'uuid'

const SlideItemMock = {
  version: 1, // Versión del objeto
  slideId: uuidv4(),
  visible: true,
  type: 'text',
  config: {
    text: 'sample text',
    src: null,
    html: null,
    position: {
      x: 50,
      y: 100,
    },
    style: {
      font: 'Arial',
      fontSize: 16,
      color: '#000000',
    },
  },
  elementId: '',
  creationTime: new Date(),
  updateTime: new Date(),
}

export default SlideItemMock
