import React, { createContext, useState, useEffect } from 'react'

import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore'

import { auth, db } from './firebase'

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [token, setToken] = useState(null)
  const [permissions, setPermissions] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async userAuth => {
      if (userAuth) {
        const token = await userAuth.getIdToken() // Obtén el token aquí

        console.log('Token:', token)

        setUser(userAuth)
        setToken(token)
        console.log('User:', userAuth)
        // Obtener el documento del usuario de Firestore para obtener el rol del usuario
        const userDocRef = doc(db, 'users', userAuth.uid)
        const userDoc = await getDoc(userDocRef)
        const userData = userDoc.data()

        // Si el usuario tiene un atributo "role" (ajusta esto según tu esquema)
        if (userData && userData.role) {
          const rolesRef = collection(db, 'userRoles')
          const q = query(rolesRef, where('roleName', '==', userData.role))

          const querySnapshot = await getDocs(q)

          if (!querySnapshot.empty) {
            const roleData = querySnapshot.docs[0].data()

            setPermissions(roleData.permissions || [])
          }
        }
      } else {
        setUser(null)
        setToken(null)
        setPermissions([])
      }
      setLoading(false)
    })

    return unsubscribe
  }, [])

  if (loading) {
    return (
      <div
        className='inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]'
        role='status'>
        <span className='!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]'>Loading...</span>
      </div>
    )
  }
  console.log('Permissions:', permissions)

  return (
    <AuthContext.Provider value={{ user, permissions, token }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
