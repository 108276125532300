import { useState } from 'react'

// Import the components for each section
import { Layout } from '../layout/Layout'
import ItemOne from '../shared/profile-content/ItemOne'
import ItemThree from '../shared/profile-content/ItemThree'
import ItemTwo from '../shared/profile-content/ItemTwo'

const navigation = [
  { name: 'Option 1', href: '#', current: true },
  { name: 'Option 2', href: '#', current: false },
  { name: 'Option 3', href: '#', current: false }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function UserProfilePage() {
  const [activeTab, setActiveTab] = useState('Option 1') // Set default tab to 'Option 1'

  return (
    <Layout>
      <div className='flex'>

        {/* Sidebar */}
        <nav className='w-64 flex-shrink-0 p-4 bg-gray-200'>
          <ul role='list' className='space-y-1'>
            {navigation.map(item => (
              <li key={item.name}>
                <button
                  onClick={() => setActiveTab(item.name)}
                  className={classNames(
                    activeTab === item.name ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                    'group flex gap-x-3 rounded-md p-2 pl-3 text-sm leading-6 font-semibold w-full text-left'
                  )}
                >
                  {item.name}
                </button>
              </li>
            ))}
          </ul>
        </nav>

        {/* Main content area */}
        <div className='flex-grow p-4'>
          {activeTab === 'Option 1' && <ItemOne />}
          {activeTab === 'Option 2' && <ItemTwo />}
          {activeTab === 'Option 3' && <ItemThree />}
        </div>

      </div>
    </Layout>
  )
}
