import React, { useState } from 'react'

import { sendPasswordResetEmail } from 'firebase/auth'
import { useNavigate, Link } from 'react-router-dom'

import { auth } from '../firebase'

function PasswordResetPage() {
  const [email, setEmail] = useState('')
  const [error, setError] = useState(null)
  const navigate = useNavigate()

  const handlePasswordReset = async event => {
    event.preventDefault()
    try {
      await sendPasswordResetEmail(auth, email)
      alert('Se ha enviado un correo electrónico de restablecimiento de contraseña a ' + email)
      navigate('/login') // redirige al usuario a la página de inicio de sesión después de enviar el correo electrónico
    } catch (error) {
      if (error.code === 'auth/user-not-found') {
        setError('No existe ninguna cuenta con este correo electrónico.')
      } else {
        setError(error.message)
      }
    }
  }

  return (
    <div className='flex min-h-screen bg-gray-10 flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8'>
      <div className='sm:mx-auto sm:w-full sm:max-w-md'>
        <h2 className='text-center text-3xl font-bold leading-9 tracking-tight text-gray-900'>
          Sign in to inApp.io
        </h2>
      </div>

      <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]'>
        <div className='px-6 sm:px-12'>
          <form onSubmit={handlePasswordReset}>
            <div>
              <label htmlFor='email' className='block text-xs font-medium'>Email</label>
              <div className='mt-1'>
                <input
                  id='email'
                  name='email'
                  type='email'
                  autoComplete='email'
                  required
                  value={email} onChange={e => setEmail(e.target.value)}
                  className='block w-full rounded-md border-0 py-1.5 ring-1 ring-inset ring-gray-50 placeholder:text-gray-50 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6'
                />
              </div>
            </div>
            <div className='mt-6'>
              <button
                type='submit'
                className='flex w-full justify-center rounded-md bg-primary px-3 py-3 leading-6 text-white hover:bg-blue-600'
              >
                Reset
              </button>
            </div>
            <div className='mt-2'>
              <Link to='/login' className='flex w-full justify-center rounded-md bg-gray-20 px-3 py-3 leading-6 hover:bg-gray-50'>Volver a iniciar sesión</Link>
            </div>
            {error && <div className='mt-2 text-sm px-4 py-3 border border-red-600 text-red-600 rounded-md'>{error}</div>}
          </form>
        </div>
      </div>
    </div>
  )
}

export default PasswordResetPage
