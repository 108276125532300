import React from 'react'

const VideoCanvasItem = ({ item }) => {
  return (
    <div className='overflow-hidden h-full w-full'>
      <video className='block h-full w-full pointer-events-none object-cover' src={item.src} autoPlay loop muted/>
    </div>
  )
}

export default VideoCanvasItem
