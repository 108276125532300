import React, { useRef, useState } from 'react'

import {
  PhotoIcon,
  CodeBracketIcon,
  ArrowUpTrayIcon,
  PlayCircleIcon,
} from '@heroicons/react/24/outline'
import { OverlayPanel } from 'primereact/overlaypanel'

import EmbedComponent from './media/embedComponent'
import GalleryComponent from './media/galleryComponent'
import PexelsComponent from './media/pexelsComponent'
import SocialsComponent from './media/socialsComponent'
import UnsplashComponent from './media/unsplashComponent'
import UploadComponent from './media/uploadComponent'
import LogoPexels from '../../assets/images/logo-pexels.svg'
import LogoUnsplash from '../../assets/images/logo-unsplash.svg'

function MediaTool({ addNewItem }) {

  const mediaPanel = useRef(null)
  const [selectedComponent, setSelectedComponent] = useState('Unsplash')

  // Esta función nos permite cambiar el componente seleccionado
  const handleButtonClick = componentName => {
    setSelectedComponent(componentName)
    // mediaPanel.current.toggle()
  }

  const closeMediaPanel = () => {
    if (mediaPanel && mediaPanel.current) {
      mediaPanel.current.hide()// Use the hide method to close the OverlayPanel
    }
  }

  const renderComponent = () => {
    switch (selectedComponent) {
    case 'Unsplash':
      return <UnsplashComponent addNewItem={addNewItem} />
    case 'Pexels':
      return <PexelsComponent addNewItem={addNewItem} />
    case 'Gallery':
      return <GalleryComponent />
    case 'Upload':
      return <UploadComponent />
    case 'Socials':
      return <SocialsComponent addNewItem={addNewItem} closeMediaPanel={closeMediaPanel}/>
    case 'Embed':
      return <EmbedComponent addNewItem={addNewItem} closeMediaPanel={closeMediaPanel}/>
    default:
      return <UnsplashComponent />
    }
  }

  return (
    <>
      <button className='flex flex-col items-center px-2 hover:text-primary' onClick={e => mediaPanel.current.toggle(e)} aria-haspopup aria-controls='overlay_panel' >
        <PhotoIcon className='h-5 w-5 mb-1' aria-hidden='true' />
        <span className='text-[10px] leading-none'>Media</span>
      </button>
      <OverlayPanel ref={mediaPanel} showCloseIcon id='overlay_panel' style={{ width: '560px' }} className='overlaypanel-demo'>
        <div className='px-6 py-3 border-b border-gray-200 text-sm font-semibold'>Media</div>
        <div className='flex h-[360px]'>
          <div className='bg-gray-10 w-36 h-full px-6 py-4'>
            <ul>
              <li className='mb-4 w-full'>
                <button onClick={() => handleButtonClick('Unsplash')} className={`py-1 ps-1 pe-2 flex items-center hover:bg-gray-200 rounded w-full ${selectedComponent === 'Unsplash' ? 'bg-gray-200' : ''}`}>
                  <div className='h-6 w-6 rounded overflow-hidden bg-black flex items-center justify-center'>
                    <img alt='Unsplash' src={LogoUnsplash} className='h4 w-4' />
                  </div>
                  <span className='ms-2 text-xs font-semibold whitespace-nowrap'>Unsplash</span>
                </button>
              </li>
              <li className='mb-4 w-full'>
                <button onClick={() => handleButtonClick('Pexels')} className={`py-1 ps-1 pe-2 flex items-center hover:bg-gray-200 rounded w-full ${selectedComponent === 'Pexels' ? 'bg-gray-200' : ''}`}>
                  <div className='h-6 w-6 rounded overflow-hidden bg-[#05A081] flex items-center justify-center'>
                    <img alt='Pexels' src={LogoPexels} className='h4 w-4' />
                  </div>
                  <span className='ms-2 text-xs font-semibold whitespace-nowrap'>Pexels</span>
                </button>
              </li>
              <li className='mb-4 w-full'>
                <button onClick={() => handleButtonClick('Gallery')} className={`py-1 ps-1 pe-2 flex items-center hover:bg-gray-200 rounded w-full ${selectedComponent === 'Gallery' ? 'bg-gray-200' : ''}`}>
                  <div className='h-6 w-6 rounded overflow-hidden bg-white flex items-center justify-center'><PhotoIcon className='h-4 w-4' aria-hidden='true' /></div>
                  <span className='ms-2 text-xs font-semibold whitespace-nowrap'>My galery</span>
                </button>
              </li>
              <li className='mb-4 w-full'>
                <button onClick={() => handleButtonClick('Upload')} className={`py-1 ps-1 pe-2 flex items-center hover:bg-gray-200 rounded w-full ${selectedComponent === 'Upload' ? 'bg-gray-200' : ''}`}>
                  <div className='h-6 w-6 rounded overflow-hidden bg-white flex items-center justify-center'><ArrowUpTrayIcon className='h-4 w-4' aria-hidden='true' /></div>
                  <span className='ms-2 text-xs font-semibold whitespace-nowrap'>Upload</span>
                </button>
              </li>
              <li className='mb-4 w-full'>
                <button onClick={() => handleButtonClick('Socials')} className={`py-1 ps-1 pe-2 flex items-center hover:bg-gray-200 rounded w-full ${selectedComponent === 'Socials' ? 'bg-gray-200' : ''}`}>
                  <div className='h-6 w-6 rounded overflow-hidden bg-white flex items-center justify-center'><PlayCircleIcon className='h-4 w-4' aria-hidden='true' /></div>
                  <span className='ms-2 text-xs font-semibold whitespace-nowrap'>Socials</span>
                </button>
              </li>
              <li className='mb-4 w-full'>
                <button onClick={() => handleButtonClick('Embed')} className={`py-1 ps-1 pe-2 flex items-center hover:bg-gray-200 rounded w-full ${selectedComponent === 'Embed' ? 'bg-gray-200' : ''}`}>
                  <div className='h-6 w-6 rounded overflow-hidden bg-white flex items-center justify-center'><CodeBracketIcon className='h-4 w-4' aria-hidden='true' /></div>
                  <span className='ms-2 text-xs font-semibold whitespace-nowrap'>Embed</span>
                </button>
              </li>
            </ul>
          </div>
          <div className='px-6 py-4 h-100 grow bg-scroll overflow-auto'>
            {renderComponent()}
          </div>
        </div>
      </OverlayPanel>
    </>
  )
}

export default MediaTool
