import { useState } from 'react'

import clsx from 'clsx'
import { AutoComplete } from 'primereact/autocomplete'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'

import { useWorkspaceContext } from '../../context/workspace/workspace-context'

export default function Step2NewTemplate({ templateName, setTemplateName, selectedWorkspace, setSelectedWorkspace, createTemplate }) {

  const { workspaces } = useWorkspaceContext()
  const [filteredWorkspaces, setFilteredWorkspaces] = useState([])
  const [autoCompleteWorskpaceValue, setAutoCompleteWorskpaceValue] = useState('')

  function onChangeWorkspaceValue(e) {
    setAutoCompleteWorskpaceValue(e.value)
    const selectedWorkspaceAlreadyExist = workspaces.find(workspace => workspace.name === e.value)

    if (selectedWorkspaceAlreadyExist) {
      setSelectedWorkspace(selectedWorkspaceAlreadyExist)
    } else {
      setSelectedWorkspace({
        name: e.value,
        id: null,
      })
    }
  }

  console.log(selectedWorkspace)

  function search(event) {
    setTimeout(() => {
      let _filteredWorkspace

      if (!event.query.trim().length) {
        _filteredWorkspace = workspaces.map(workspace => workspace.name)
      } else {
        _filteredWorkspace = workspaces
          .filter(workspace => workspace.name.toLowerCase().startsWith(event.query.toLowerCase()))
          .map(workspace => workspace.name)
      }

      setFilteredWorkspaces(_filteredWorkspace)
    }, 250)
  }

  return (
    <>
      <div className={clsx('flex flex-col items-start gap-4')} >
        <div className='w-full' >
          <label
            htmlFor='templateName'
            className='block text-sm font-medium'
          >Template name</label>
          <InputText id='templateName' className='custom-create-template-inputs' value={templateName} onChange={e => setTemplateName(e.target.value)} placeholder={'My first template'} />
        </div>
        <div className='w-full'>
          <label
            htmlFor='format'
            className='block text-sm font-medium'
          >Associate your template to a workspace or create one directly</label>
          <AutoComplete
            suggestions={filteredWorkspaces}
            value={autoCompleteWorskpaceValue}
            completeMethod={search}
            onChange={onChangeWorkspaceValue}
            placeholder='My wonderful workspace'
            className={clsx('custom-autocomplete-input-create-template')}
          />

        </div>
      </div>
      <div className='flex pt-8 justify-between items-center'>
        <div className='text-new-black font-Lato text-xs font-light'>
          Do you have any problem creating a new template?
          <span className=' ml-3 text-blue-400 font-Lato text-xs font-normal underline cursor-pointer'>Help center</span>
        </div>
        <div className='flex items-center justify-between gap-6' >
          <Button
            label='Create'
            icon='pi pi-check'
            onClick={createTemplate}
            autoFocus
          />
        </div>
      </div>
    </>
  )
}
