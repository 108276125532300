import React, { useState, useEffect } from 'react'

import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

import { getPexelsVideos } from './../../../services/ApiService'

function PexelsComponent({ addNewItem }) {
  const [videos, setVideos] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [actualPage, setActualPage] = useState(1)
  const [hoverIndex, setHoverIndex] = useState(null) // Nuevo estado para controlar el índice del video en hover

  const getLowestResolutionVideoLink = videoFiles => {
    // Filtrar los archivos de video para quedarse solo con los de calidad 'sd'
    const sdVideos = videoFiles.filter(video => video.quality === 'sd')

    // Ordenar los videos 'sd' por ancho en orden ascendente para obtener el de menor resolución primero
    sdVideos.sort((a, b) => a.width - b.width)

    // Retornar el enlace del video de menor resolución, o null si no hay videos 'sd'
    return sdVideos.length > 0 ? sdVideos[0].link : null
  }

  const fetchVideos = (page = 1, term = searchTerm) => {
    getPexelsVideos(term, page)
      .then(response => {
        if (page === 1) {
          console.log(response.data.videos)
          setVideos(response.data.videos)
        } else {
          setVideos(prevVideos => [...prevVideos, ...response.data.videos])
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  useEffect(() => {
    fetchVideos(1, 'aerials') // Pasando 'backgrounds' directamente
  }, []) // Se ejecutará una vez, similar a ngOnInit

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return
      setActualPage(prevPage => prevPage + 1)
      fetchVideos(actualPage + 1)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [actualPage])

  return (
    <>
      <div className='relative mb-4 rounded-full'>
        <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
          <MagnifyingGlassIcon className='h-5 w-5 text-gray-50' aria-hidden='true' />
        </div>
        <input
          type='text'
          name='search'
          id='search'
          className='block w-full rounded-full border-0 py-1.5 pl-10 ring-1 ring-inset ring-gray-50 placeholder:text-gray-50 focus:ring-inset focus:ring-gray-600 sm:text-xs sm:leading-6'
          placeholder='Search'
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          onKeyPress={e => e.key === 'Enter' && fetchVideos()}
        />
      </div>
      <div className='inline-grid grid-cols-3 gap-4'>
        {videos && videos.map((video, index) => {
          const lowestResVideoLink = getLowestResolutionVideoLink(video.video_files)

          return (
            <div
              key={index}
              className='w-full aspect-[4/3] rounded-lg overflow-hidden'
              onClick={() => addNewItem({
                type: 'video',
                url: video.video_files[0].link,
              })}
              onMouseEnter={() => setHoverIndex(index)}
              onMouseLeave={() => setHoverIndex(null)}
            >
              {hoverIndex === index || index === 0
                ? <video className='w-full h-full object-cover' src={lowestResVideoLink} autoPlay muted loop />
                : <img className='w-full h-full object-cover' src={video.image} alt={video.user.name || 'Pexels video'} />
              }
            </div>
          )
        })}
      </div>
    </>
  )
}

export default PexelsComponent
