import React, { useEffect, useState } from 'react'

import {
  ArrowLeftIcon,
  Cog6ToothIcon,
  PlayCircleIcon,
  ChevronDownIcon,
  TvIcon,
  PencilIcon,
} from '@heroicons/react/24/outline'
// import moment from 'moment'

import AvatarGroup from '../components/AvatarGroup'
import DialogGlobalSettings from '../components/dialog/DialogGlobalSettings'
import { useWorkspaceContext } from '../context/workspace/workspace-context'
import { usePermissions } from '../hooks/usePermissions'

const HeaderEditor = ({ localTemplate, showDialogSetTemplateToScreen, saveTemplate, publishTemplatetoScreen, setLocalTemplate, showDialogShareTemplate }) => {
  console.log(localTemplate.updateTime)

  const [openDialogGlobalSettings, setOpenDialogGlobalSettings] = useState(false)

  // eslint-disable-next-line no-unused-vars
  const [updatedTime, setUpdatedTime] = useState(null)

  console.log(updatedTime)
  // eslint-disable-next-line no-unused-vars
  const [daysAgo, setDaysAgo] = useState(0)
  const { getUserCurrentWorkspace, currentWorkspace } = useWorkspaceContext()
  const { hasPermission } = usePermissions()
  // Convertir template.updateTime a formato fecha
  // const updateTime = moment(localTemplate.updateTime.seconds * 1000).fromNow()

  // Estado para editar y almacenar el título del template
  const [editingTitle, setEditingTitle] = useState(false)

  function getDaysAgoString() {
    if (daysAgo === 0) {
      return 'Updated Today'
    } else if (isNaN(daysAgo) || daysAgo < 0) {
      return '...'
    } else if (daysAgo === 1) {
      return 'Updated Yesterday'
    } else {
      return `Updated ${daysAgo} days ago`
    }
  }

  useEffect(() => {
    if (localTemplate && localTemplate.updateTime) {
      const firebaseUpdateTime = localTemplate.updateTime

      const updateTime = new Date(
        firebaseUpdateTime.seconds * 1000 +
        firebaseUpdateTime.nanoseconds / 1000000
      )

      if (!isNaN(updateTime)) {
        setUpdatedTime(updateTime)

        const currentTime = new Date()
        const timeDifference = Math.floor(
          (currentTime - updateTime) / (1000 * 60 * 60 * 24)
        )

        setDaysAgo(timeDifference)
      } else {
        setUpdatedTime(null)
        setDaysAgo(null)
      }
    } else {
      setUpdatedTime(null)
      setDaysAgo(null)
    }
  }, [localTemplate])

  console.log(daysAgo)
  console.log(updatedTime)

  const handleTitleChange = event => {
    setLocalTemplate({
      ...localTemplate,
      config: { ...localTemplate.config, settings: { ...localTemplate.config.settings, title: event.target.value } },
    })
  }

  const handleTitleEdit = () => {
    setEditingTitle(!editingTitle)
  }

  useEffect(() => {
    if (localTemplate.workspaceId && localTemplate.creatorId) {
      getUserCurrentWorkspace(localTemplate.creatorId, localTemplate.workspaceId)
    }

  }, [localTemplate])

  return (
    <header className='sticky z-50 top-0 h-16 flex items-center py-2.5'>
      <div className='flex items-center w-[400px] bg-white px-6 border-e border-gray-20'>
        <a href='/' className='-m-2.5 p-2.5 text-gray-50'>
          <ArrowLeftIcon className='h-6 w-6' aria-hidden='true' />
        </a>

        <div className='flex flex-1 justify-between ms-2'>
          <div className='grow ps-2 pe-4'>
            { editingTitle ? (
              <input
                className='block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-50 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                type='text'
                value={localTemplate.config.settings.title}
                onChange={handleTitleChange}
              />
            )
              : <h6 className='font-semibold leading-4 cursor-pointer' onClick={handleTitleEdit}>{localTemplate.config.settings.title}</h6>
            }
            <p className='text-sm text-gray-50'>

              {localTemplate.workspaceId && currentWorkspace && <span className='me-1 cursor-pointer'>In  <span className='text-sm text-primary hover:text-blue-600'> # </span> {currentWorkspace.name} </span> }
              {!localTemplate.workspaceId && <span className='me-1'>Not workspace asociated </span> }

            </p>
          </div>
          <button type='button' onClick={() => setOpenDialogGlobalSettings(true)}>
            <PencilIcon className='h-5 w-5 text-gray-50' aria-hidden='true' />
          </button>
        </div>
      </div>
      <div className='flex items-center px-6'>
        <button
          type='button'
          className='inline-flex items-center justify-center rounded-md bg-gray-20 px-3 py-2 text-sm hover:bg-gray-300'
          onClick={() => setOpenDialogGlobalSettings(true)}
        >
          Global settings
          <Cog6ToothIcon className='ml-1.5 h-5 w-5' aria-hidden='true' />
        </button>
        <DialogGlobalSettings visible={openDialogGlobalSettings} setVisible={setOpenDialogGlobalSettings} localTemplate={localTemplate} setLocalTemplate={setLocalTemplate} />
        { updatedTime !== null && daysAgo !== 0 && <p className='ml-2 text-gray-50 text-[14px]' >{` ${getDaysAgoString()}`}</p> }
        {/* <span className='text-sm text-gray-50 ms-2'>Updated {updateTime}</span> */}
      </div>
      <div className='ms-auto flex items-center pe-6'>
        <div className='h-6 w-px bg-gray-20 mx-6' aria-hidden='true' />
        <a href='#' className='inline-flex items-center justify-center text-sm text-primary'>
          Preview
          <PlayCircleIcon className='ml-1.5 h-5 w-5 text-black' aria-hidden='true' />
        </a>
        {/* Separator */}
        <div className='h-6 w-px bg-gray-20 mx-6' aria-hidden='true' />
        <button type='button' className='flex items-center text-sm text-primary whitespace-nowrap' onClick={showDialogSetTemplateToScreen}>
          Link to screen
          <div className='h-6 flex items-center text-xs text-white rounded-full bg-red-500 ps-2 pe-1 ms-2'>
            <span className='bg-white w-2 h-2 rounded-full me-1'></span>
            Live
            <span className='h-[18px] min-w-[18px] text-xs px-1 rounded-full bg-white ms-2 flex items-center justify-center text-black'>{localTemplate.screensIdAttached.length}</span>
          </div>
          <ChevronDownIcon className='ml-1.5 h-2.5 w-2.5 text-black' aria-hidden='true' />
        </button>
        {/* Separator */}
        <div className='h-6 w-px bg-gray-20 mx-6' aria-hidden='true' />
        <button type='button' className='flex items-center text-sm text-primary' onClick={showDialogShareTemplate}>
          Share
          <AvatarGroup className='ms-2' />
        </button>
        {/* Separator */}
        <div className='h-6 w-px bg-gray-20 mx-6' aria-hidden='true' />
        <button
          onClick={saveTemplate}
          className='rounded-md bg-gray-200 px-3 py-2 text-sm hover:bg-gray-300'
        >
          Save
        </button>
        <button
          disabled={hasPermission('TEMPLATE_PUBLISH')}
          onClick={publishTemplatetoScreen}
          type='button'
          className='ms-2 rounded-md bg-primary px-3 py-2 text-sm text-white hover:bg-blue-600 flex items-center'
        >
          Publish all
          <TvIcon className='ml-1.5 h-4 w-4 text-white' aria-hidden='true' />
        </button>
      </div>
    </header>
  )
}

export default HeaderEditor
