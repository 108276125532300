import React, { useState } from 'react'

import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, setPersistence, browserSessionPersistence, browserLocalPersistence } from 'firebase/auth'
import { doc, setDoc, getDoc, serverTimestamp } from 'firebase/firestore'
import { useNavigate, Link } from 'react-router-dom'

import LogoGoogle from '../assets/images/logo-google.svg'
import UserMock from '../constants/UserMock'
import { useWorkspaceContext } from '../context/workspace/workspace-context'
import { auth, db } from '../firebase'

function LoginPage() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  const { ensureDefaultWorkspace } = useWorkspaceContext()

  const navigate = useNavigate()

  const handleLogin = async (providerName, event) => {
    try {
      let userCredential // Esta variable almacenará la información del usuario

      if (providerName === 'email') {
        event.preventDefault()

        const rememberMe = document.getElementById('remember-me').checked

        if (rememberMe) {
          await setPersistence(auth, browserLocalPersistence)
        } else {
          await setPersistence(auth, browserSessionPersistence)
        }

        userCredential = await signInWithEmailAndPassword(auth, email, password)
      } else {
        const providers = {
          google: GoogleAuthProvider,
          facebook: FacebookAuthProvider,
        }

        if (!providers[providerName]) {
          throw new Error('Invalid provider name.')
        }

        const provider = new providers[providerName]()

        userCredential = await signInWithPopup(auth, provider)
      }

      // Después de la autenticación exitosa, obtenemos el uid del usuario
      const uid = userCredential.user.uid

      console.log('uid', uid)
      // Crear una referencia al documento del usuario en Firestore
      const userDocRef = doc(db, 'users', uid)

      console.log('userDocRef', userDocRef)
      // Verificar si el usuario ya existe en Firestore
      const userDoc = await getDoc(userDocRef)

      console.log('userDoc', userDoc)
      console.log(userDoc.exists())

      if (!userDoc.exists()) {
      // Si el usuario no existe, crea un nuevo documento con el mock del usuario y como uid el uid del usuario autenticado
        await setDoc(userDocRef, {
          ...UserMock,
          uid,
          email: userCredential.user.email,
          displayName: userCredential.user.displayName,
          photoURL: userCredential.user.photoURL || UserMock.photoURL,
          lastLogin: serverTimestamp(),
          createdAt: serverTimestamp(),
        })
      } else {
      // Si el usuario ya existe, verifica si le falta alguna propiedad y actualízala
        const userData = userDoc.data()
        const updatedData = {}

        for (const key in UserMock) {
          if (!Object.prototype.hasOwnProperty.call(userData, key)) {
            updatedData[key] = UserMock[key]
          }
        }

        // Actualizamos lastLogin siempre
        updatedData.lastLogin = serverTimestamp()

        // Si el usuario no tiene createdAt, lo seteamos al timestamp actual
        if (!userData.createdAt) {
          updatedData.createdAt = serverTimestamp()
        }

        // Aseguramos que uid y email siempre sean los correctos
        updatedData.uid = uid
        updatedData.email = userCredential.user.email

        if (Object.keys(updatedData).length > 0) {
          await setDoc(userDocRef, updatedData, { merge: true })
        }
      }
      await ensureDefaultWorkspace(uid)

      navigate('/')
    } catch (error) {
      setError(error.message)
    }
  }

  return (
    <>
      <div className='flex min-h-screen bg-gray-10 flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-md'>
          <h2 className='text-center text-3xl font-bold leading-9 tracking-tight text-gray-900'>
            Sign in to inApp.io
          </h2>
        </div>

        <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]'>
          <div className='px-6 sm:px-12'>
            <p className='text-sm'>Sign in with</p>
            <div className='mt-2 grid grid-cols-2 gap-4'>
              <button
                onClick={() => handleLogin('google')}
                className='flex w-full items-center justify-center rounded-md bg-white border border-gray-50 px-3 py-2'
              >
                <img alt='Pexels' src={LogoGoogle} className='h-6 w-6' />
              </button>

              {/* <button
                  onClick={() => handleLogin('facebook')}
                  className='flex w-full items-center justify-center gap-3 rounded-md bg-[#24292F] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F]'
                >
                  <span className='text-sm font-semibold leading-6'>Facebook</span>
                </button> */}
            </div>
            <div>
              <div className='relative my-4'>
                <div className='absolute inset-0 flex items-center' aria-hidden='true'>
                  <div className='w-full border-t border-gray-50' />
                </div>
                <div className='relative flex justify-center text-sm font-medium leading-6'>
                  <span className='bg-gray-10 px-6'>Or continue with</span>
                </div>
              </div>
            </div>
            <form className='space-y-6' onSubmit={event => handleLogin('email', event)}>
              <div>
                <label htmlFor='email' className='block text-xs font-medium'>Email</label>
                <div className='mt-1'>
                  <input
                    id='email'
                    name='email'
                    type='email'
                    autoComplete='email'
                    required
                    value={email} onChange={e => setEmail(e.target.value)}
                    className='block w-full rounded-md border-0 py-1.5 ring-1 ring-inset ring-gray-50 placeholder:text-gray-50 focus:ring-inset focus:ring-primary text-sm leading-6'
                  />
                </div>
              </div>

              <div>
                <label htmlFor='password' className='block text-xs font-medium'>Password</label>
                <div className='mt-1'>
                  <input
                    id='password'
                    name='password'
                    type='password'
                    autoComplete='current-password'
                    required
                    value={password} onChange={e => setPassword(e.target.value)}
                    className='block w-full rounded-md border-0 py-1.5 ring-1 ring-inset ring-gray-50 placeholder:text-gray-50 focus:ring-inset focus:ring-primary text-sm leading-6'
                  />
                </div>
              </div>

              <div className='flex items-center justify-between'>
                <div className='flex items-center'>
                  <input
                    id='remember-me'
                    name='remember-me'
                    type='checkbox'
                    className='h-4 w-4 rounded border-gray-50 text-primary focus:ring-primary'
                  />
                  <label htmlFor='remember-me' className='ml-3 block text-xs leading-6 text-gray-900'>
                    Remember me
                  </label>
                </div>

                <div className='text-xs leading-6'>
                  <Link to='/reset-password' className='font-medium text-primary hover:text-blue-500'>
                    Forgot password?
                  </Link>
                </div>
              </div>

              <div>
                <button
                  type='submit'
                  className='flex w-full justify-center rounded-md bg-primary px-3 py-3 leading-6 text-white hover:bg-blue-600'
                >
                  Sign in
                </button>
              </div>
              {error && <div className='text-sm px-4 py-3 border border-red-600 text-red-600 rounded-md'>{error}</div>}
            </form>
          </div>

        </div>
      </div>
    </>
  )
}

export default LoginPage
