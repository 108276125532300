import React, { createContext, useContext, useReducer } from 'react'

import { doc, setDoc } from 'firebase/firestore'
import { v4 as uuidv4 } from 'uuid'

import workspaceReducer from './workspace-reducer'
import { db } from '../../firebase'
import { deleteWorkspaceService, getAllUserWorkspacesService, getScreensByWorkspaceIdService, getTemplatesByWorkspaceIdService, getUserCurrentWorkspaceService } from '../../services/userService'

const WorkspaceContext = createContext({})

const WORKSPACE_INITIAL_STATE = {
  workspaces: [
  ],
  currentWorkspace: null,
  templatesByWorkspace: [],
  screensByWorkspace: [],
  isDeleteWorkspaceDialogOpen: false,
}

function WorkspaceProvider({ children }) {

  const [state, dispatch] = useReducer(workspaceReducer, WORKSPACE_INITIAL_STATE)

  //* Add new workspace and return its ID
  async function addNewWorkspace(workspaceName, userId) {

    const payload = { id: uuidv4(), name: workspaceName, initial: workspaceName[0], current: false, templates: [], screens: [], userId, isDefaultWorkspace: false }

    try {
      await setDoc(doc(db, 'workspaces', payload.id), payload)
      dispatch({ type: 'ADD_NEW_WORKSPACE', payload })

      return payload.id // Return the workspace's ID
    } catch (error) {
      console.error('Error al crear el workspace en Firestore:', error)
    }
  }

  async function getAllUserWorkspaces(userId) {
    const response = await getAllUserWorkspacesService(userId)

    dispatch({ type: 'GET_ALL_USER_WORKSPACES', payload: response })
  }

  async function getUserCurrentWorkspace(userId, workspaceId) {
    const currentWorkspaceResponse = await getUserCurrentWorkspaceService(userId, workspaceId)

    if (!currentWorkspaceResponse) {
      return null

    } else {
      dispatch({ type: 'GET_USER_CURRENT_WORKSPACE', payload: currentWorkspaceResponse })

      return currentWorkspaceResponse
    }
  }

  // funcion que crea Default Workspace si no existe para el usuario

  async function ensureDefaultWorkspace(userId) {
    const userWorkspaces = await getAllUserWorkspacesService(userId)

    // Check if "Default Workspace" exists
    const hasDefaultWorkspace = userWorkspaces.some(workspace => workspace.name === 'Default Workspace')

    // If "Default Workspace" doesn't exist, create it
    if (!hasDefaultWorkspace) {
      await addNewWorkspace('Default Workspace', userId)
    }
  }

  async function getTemplatesAndScreens(workspaceId) {
    const templates = await getTemplatesByWorkspaceIdService(workspaceId)
    const screens = await getScreensByWorkspaceIdService(workspaceId)

    console.log({ templates, screens })

    dispatch({ type: 'GET_TEMPLATES_AND_SCREENS', payload: { templates, screens } })
  }

  function openDeleteWorkspaceDialog() {
    dispatch({ type: 'OPEN_DELETE_WORKSPACE_DIALOG' })
  }
  function closeDeleteWorkspaceDialog() {
    dispatch({ type: 'CLOSE_DELETE_WORKSPACE_DIALOG' })
  }

  async function deleteWorkspace(workspaceId, userId) {
    console.log(state.currentWorkspace.id)
    if (!workspaceId) {
      return
    }
    try {
      await deleteWorkspaceService(workspaceId, userId)
      dispatch({ type: 'DELETE_WORKSPACE', payload: workspaceId })
      dispatch({ type: 'CLOSE_DELETE_WORKSPACE_DIALOG' })
    } catch (error) {
      console.error(error)
    }
  };

  return (
    <WorkspaceContext.Provider value={{ ...state, addNewWorkspace, ensureDefaultWorkspace, getAllUserWorkspaces, getUserCurrentWorkspace, getTemplatesAndScreens, openDeleteWorkspaceDialog, closeDeleteWorkspaceDialog, deleteWorkspace }}>
      {children}
    </WorkspaceContext.Provider>
  )
}

const useWorkspaceContext = () => {
  const context = useContext(WorkspaceContext)

  if (!context) {
    throw new Error('useWorkspaceContext must be used within an WorkspaceProvider.')
  }

  return context
}

export { WorkspaceProvider, useWorkspaceContext }
