import React from 'react'

import {
  PhotoIcon,
} from '@heroicons/react/24/outline'
import { FileUpload } from 'primereact/fileupload'

function UploadComponent() {

  return (
    <>
      <div className='relative h-full rounded-full'>
        <div className='card'>
          <FileUpload
            name='demo[]'
            url={'/api/upload'}
            multiple
            accept='image/*'
            maxFileSize={1000000}
            emptyTemplate={
              <div className='flex flex-col items-center'>
                <PhotoIcon className='h-12 w-12 mb-3' aria-hidden='true' />
                <span className='block font-medium'>Upload your images</span>
                <small>Drag and drop here the images you want to upload.</small>
              </div>
            } />
        </div>
      </div>
    </>
  )
}

export default UploadComponent
