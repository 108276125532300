/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useRef } from 'react'

import {
  PauseIcon,
  PhotoIcon,
  PowerIcon,
} from '@heroicons/react/24/outline'
import { getDocs, collection, query, where, updateDoc, doc, getDoc } from 'firebase/firestore'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'

import screenLandscape from '../../assets/images/screen-16x9.svg'
import screenPortrait from '../../assets/images/screen-9x16.svg'
import { AuthContext } from '../../AuthProvider'
import { db } from '../../firebase'

function DialogSetTemplateToScreen({ visible, setVisible, template, setLocalTemplate }) {
  const { user } = useContext(AuthContext)
  const [screens, setScreens] = useState()
  const toast = useRef(null)
  // Desestructura config de template
  const { slides, config, screensIdAttached, templateId } = template
  // Desestructura settings de config
  const { settings } = config || {}
  // Desestructura aspectRatio de settings
  const { aspectRatio, title: templateTitle } = settings || {}

  const fetchScreens = async () => {
    const screensRef = collection(db, 'screens')
    const screensQuery = query(screensRef, where('user', '==', user.uid))
    const querySnapshot = await getDocs(screensQuery)

    setScreens(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })))
  }

  async function saveTemplateToScreen(screen) {
    console.log(screen)

    if (!template || !screen) {
      return
    }

    let succesMessage = ''

    // Verifica si la pantalla ya está asociada al template
    /* if (screensIdAttached.includes(screen.id)) {
      alert('This template is already associated with the selected screen.')

      return
    } */

    // Obteniendo el antiguo template asociado a la pantalla (si existe)
    if (screen.templateId) {
      console.log('If statement')
      console.log(screen.id)
      console.log(screen.templateId)
      const oldTemplateDocRef = doc(db, 'templates', screen.templateId)
      const oldTemplateDocSnapshot = await getDoc(oldTemplateDocRef)

      if (oldTemplateDocSnapshot.exists()) {
        const oldTemplateData = oldTemplateDocSnapshot.data()

        console.log(oldTemplateData)

        /// Eliminando el ID de la pantalla de la lista 'screensIdAttached' del antiguo template
        const updatedScreensIdAttached = oldTemplateData.screensIdAttached.filter(screenId => screenId !== screen.id)

        console.log(updatedScreensIdAttached)
        // Actualizando el antiguo template en Firestore
        await updateDoc(oldTemplateDocRef, {
          screensIdAttached: updatedScreensIdAttached,
        })
        setLocalTemplate({ ...template, screensIdAttached: updatedScreensIdAttached })

        const screenDocRef = doc(db, 'screens', screen.id)

        await updateDoc(screenDocRef, {
          slides: null,
          format: null,
          templateId: null,
          templateTitle: null,
        })
        setScreens(screens.map(s => s.id === screen.id ? { ...s, templateId: null } : s))

      }
      succesMessage = 'Template has been successfully unlinked from your screen.'

    } else {
      console.log('else statement')
      console.log(screen.id)

      const templateDocRef = doc(db, 'templates', template.templateId)

      await updateDoc(templateDocRef, {
        screensIdAttached: [...screensIdAttached, screen.id],
      })

      setLocalTemplate({ ...template, screensIdAttached: [...screensIdAttached, screen.id] })

      const screenDocRef = doc(db, 'screens', screen.id)

      await updateDoc(screenDocRef, {
        slides,
        format: aspectRatio,
        templateId: template.templateId,
        templateTitle,
      })

      setScreens(screens.map(s => s.id === screen.id ? { ...s, templateId: template.templateId } : s))

      // Actualiza el documento de la plantilla en Firestore con el ID de la pantalla seleccionada

      succesMessage = 'Template has been successfully attached to your screen.'

    }

    // Actualiza el documento de la pantalla en Firestore con los datos de la plantilla

    setTimeout(() => {
      toast.current.show({ severity: 'success', summary: 'Success', detail: succesMessage, life: 4000 })
      setVisible(false)
    }, 2000)
  }

  useEffect(() => {
    if (user) {
      fetchScreens()
    }
  }, [user])

  const headerContent = (
    <>
      <h3 className='text-xl'>Link to screen</h3>
      <p className='text-sm font-light'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </>
  )

  const footerContent = (
    <div className='flex flex-row-reverse'>
      <button onClick={() => setVisible(false)} className='rounded-md bg-gray-200 px-3 py-2 text-sm hover:bg-gray-300'>
        Cancel
      </button>
    </div>
  )

  return (
    <>
      <Toast ref={toast} position={'top-center'}/>
      <Dialog header={headerContent} draggable={false} footer={footerContent} visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
        <p className='text-sm font-semibold mb-4'>Screens Linked</p>
        {screens?.map((screen, index) =>
          screensIdAttached.includes(screen.id) &&
          <div key={index} className='grid grid-cols-5 mb-2 items-center gap-4'>
            <div className='flex items-center gap-2 col-span-2'>
              <PowerIcon className='shrink-0 h-4 w-4 text-primary hover:text-blue-600' aria-hidden='true' />
              <PauseIcon className='shrink-0 h-3 w-3 text-black' aria-hidden='true' />
              { aspectRatio === '16:9' ? <img className='w-7' src={screenLandscape} alt='Screen 16:9' /> : <img className='h-7' src={screenPortrait} alt='Screen 9:16' /> }
              <span className='text-xs truncate font-semibold' >{screen.id}</span>
            </div>
            <div className='flex items-center' >
              <span className='me-1 text-gray-50 text-xs'>In</span>
              <span className='text-black text-xs truncate' >
                {`#${screen.accessCode}`}
              </span>
            </div>
            <div className='flex items-center gap-2'>
              <PhotoIcon className='shrink-0 h-4 w-4 text-gray-50' aria-hidden='true' />
              <span className='text-xs font-medium truncate'>{screen.templateId && screen.templateId === templateId ? templateTitle : 'No template associated'}</span>
            </div>
            <button onClick={() => saveTemplateToScreen(screen)} className='ms-auto rounded-lg bg-primary w-28 px-3 gap-2 py-2 text-xs text-white hover:bg-blue-600 flex items-center justify-center' >Unlink</button>
          </div>
        )}
        <hr className='my-4'/>
        <p className='text-sm font-semibold mb-4'>Others Screens</p>
        {screens?.map((screen, index) =>
          !screen.templateId &&
          !screensIdAttached.includes(screen.id) &&
          <div key={index} className='grid grid-cols-5 mb-2 items-center gap-4'>
            <div className='flex items-center gap-2 col-span-2'>
              <PowerIcon className='shrink-0 h-4 w-4 text-primary hover:text-blue-600' aria-hidden='true' />
              <PauseIcon className='shrink-0 h-3 w-3 text-black' aria-hidden='true' />
              { aspectRatio === '16:9' ? <img className='w-7' src={screenLandscape} alt='Screen 16:9' /> : <img className='h-7' src={screenPortrait} alt='Screen 9:16' /> }
              <span className='text-xs truncate font-semibold'>{screen.id}</span>
            </div>
            <div className='flex items-center' >
              <span className='me-1 text-gray-50 text-xs'>In</span>
              <span className='text-black text-xs truncate'>{`#${screen.accessCode}`}</span>
            </div>
            <div className='text-xs truncate text-gray-50'> {screen.templateTitle && screen.templateId ? screen.templateTitle : 'No template associated'}</div>
            {screen.templateId && screen.templateTitle && <button className='bg-gray-300 text-gray-600 w-28 px-3 gap-2 py-2 text-xs  ms-auto rounded-lg cursor-not-allowed' >linked</button> }
            {!screen.templateId && !screen.templateTitle && <button onClick={() => saveTemplateToScreen(screen)} className='ms-auto rounded-lg bg-primary w-28 px-3 gap-2 py-2 text-xs text-white hover:bg-blue-600 flex items-center justify-center'>Link</button> }
          </div>
        )}
      </Dialog>
    </>
  )
}

export default DialogSetTemplateToScreen
