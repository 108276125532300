import React from 'react'

function ItemTwo() {
  return (
    <div className='p-6 bg-white shadow rounded-lg'>
      <h2 className='text-xl font-semibold mb-4'>Item Two Title</h2>
      <p className='text-gray-700 mb-4'>
        Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Donec sollicitudin molestie malesuada.
      </p>
      <ul className='list-disc pl-6'>
        <li>Detail one</li>
        <li>Detail two</li>
        <li>Detail three</li>
      </ul>
    </div>
  )
}

export default ItemTwo
