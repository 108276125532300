import HeaderEditor from '../layout/HeaderEditor'

export const LayoutEditor = ({ children, localTemplate, setLocalTemplate, showDialogSetTemplateToScreen, saveTemplate, publishTemplatetoScreen, showDialogShareTemplate }) => {
  return (
    <div>
      <HeaderEditor localTemplate={localTemplate} setLocalTemplate={setLocalTemplate} showDialogSetTemplateToScreen={showDialogSetTemplateToScreen} saveTemplate={saveTemplate} publishTemplatetoScreen={publishTemplatetoScreen} showDialogShareTemplate={showDialogShareTemplate} />
      <main className='bg-gray-10 min-h-[--main-height]'>
        { children }
      </main>
    </div>
  )
}
