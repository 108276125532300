import React, { useState } from 'react'

import {
  TrashIcon,
  EyeIcon,
  EyeSlashIcon,
  Bars3Icon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline'
import { Dropdown } from 'primereact/dropdown'

function FormComponent() {

  const [items, setItems] = useState([
    {
      id: 1,
      name: 'Name',
      type: 'text',
      visible: true,
    },
    {
      id: 2,
      name: 'Email',
      type: 'email',
      visible: true,
    }
  ])

  const [selectedField, setSelectedField] = useState(null)

  // Fields
  const fields = [
    { name: 'Text', type: 'text' },
    { name: 'Email', code: 'email' },
    { name: 'Phone', code: 'phone' }
  ]

  const toggleItemVisibility = itemId => {
    setItems(items.map(item =>
      item.id === itemId
        ? { ...item, visible: !item.visible }
        : item
    ))
  }

  return (
    <div className='mt-5'>
      <ul>
        {items.map(item => (
          <li key={item.id} className='flex items-center mb-1'>
            <button
              className='pe-2 text-gray-400 hover:text-gray-600'
              onClick={() => toggleItemVisibility(item.id)}
            >
              {item.visible
                ? <EyeIcon className='h-4 w-4' aria-hidden='true' />
                : <EyeSlashIcon className='h-4 w-4' aria-hidden='true' />}
            </button>
            <div className={`grow flex items-center px-3 border border-gray-20 rounded-lg min-w-[1px] ${item.visible ? 'opacity-100' : 'opacity-50'}`}>
              <span className='text-xs font-semibold truncate me-2 grow'>{item.name}</span>
              <Dropdown
                value={selectedField}
                onChange={e => setSelectedField(e.value)}
                options={fields}
                optionLabel='name'
                placeholder='select field'
                className='p-dropdown-xs ms-auto me-2'
                panelClassName='p-dropdown-panel-xs' />
              <Bars3Icon className='h-4 w-4 ms-auto text-gray-300 flex-[0_0_16px]' aria-hidden='true' />
            </div>
            <button className='ps-2 text-gray-400 hover:text-red-600'>
              <TrashIcon className='h-4 w-4' aria-hidden='true' />
            </button>
          </li>
        ))}
      </ul>
      <button
        type='button'
        className='mt-3 text-sm inline-flex items-center justify-center rounded-md bg-primary px-3 py-2.5 w-full text-white hover:bg-blue-600 '
      >
        <PlusCircleIcon className='-ml-0.5 mr-1.5 h-5 w-5' aria-hidden='true' />
        Add new Field
      </button>
    </div>
  )
}

export default FormComponent
