
const themes = [
  {
    imageUrl: 'https://s3-alpha-sig.figma.com/img/5eee/498a/fb40e5844b3c83e18e163598b54aefb7?Expires=1699228800&Signature=fBe18nG6jvn7KBqmR4zB726vH08hL7cEg~Tdh-U1Gsx~sM9rX03kZN2o~xnota6i7rdc5dSTbjwDl3OeYtddAHfVQSDQQHVJsROZsI52sAegxQ9clBH4cYvGJWxC~WvcjQEXPcL-7TAF7sHrtESwNwRSDlYoQa-M6loQht01gUSemKttyucNpz40YubHxR1LUf73QO7u~KBZSEQuxTwm~c9YbaY3jJK3wwWHUkcV5JUPhFaDzeKiYtQPoZSdxsegYfSKiLEFNIuPrmqui43xB1eH21UkCQAz4yxax6b7KuYABkBLU-dR~6HvV5Hp-G~xrhT0Cheug95WP6sFcmS0ww__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
    name: 'Theme 1',
    author: 'Author 1',
  },
  {
    imageUrl: 'https://s3-alpha-sig.figma.com/img/a320/80e2/3f40551415dd93c9e316f2cc19ab218a?Expires=1699228800&Signature=mAcxBCx2Qpfo27Ec49a2HMqm3QCjk4VV4yEC~BAmq8TAlQhcnzUYe4FQyBfVfZ80~N7t6AiDySA7jz9b52Xg2MZeZo28IYvS9JJll8GBZx0r8nk4lHnxtM1vUxu7OW8Z87gfBqRpaCyuU4USkjgxsK42QqxL6nAf~qfCEqduiLmT2dcc6Oyi-mii5QFkT5v8XtHzw9vKdTAcgecigVYXUP47mkLjjwIJddO2E7MaP8MXCtTaSVrYMikP9tkmXmkBo5MP4w6Ssnk8Nfv2W-GH-wMu1CCWTaDfg3daKwGEMC7SlRQhp79nINurw7U5kopMJdKfLGRmtE6FXyp~Six9FA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
    name: 'Theme 2',
    author: 'Author 2',
  },
  {
    imageUrl: 'https://s3-alpha-sig.figma.com/img/a320/80e2/3f40551415dd93c9e316f2cc19ab218a?Expires=1699228800&Signature=mAcxBCx2Qpfo27Ec49a2HMqm3QCjk4VV4yEC~BAmq8TAlQhcnzUYe4FQyBfVfZ80~N7t6AiDySA7jz9b52Xg2MZeZo28IYvS9JJll8GBZx0r8nk4lHnxtM1vUxu7OW8Z87gfBqRpaCyuU4USkjgxsK42QqxL6nAf~qfCEqduiLmT2dcc6Oyi-mii5QFkT5v8XtHzw9vKdTAcgecigVYXUP47mkLjjwIJddO2E7MaP8MXCtTaSVrYMikP9tkmXmkBo5MP4w6Ssnk8Nfv2W-GH-wMu1CCWTaDfg3daKwGEMC7SlRQhp79nINurw7U5kopMJdKfLGRmtE6FXyp~Six9FA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
    name: 'Theme 3',
    author: 'Author 3',
  },
  {
    imageUrl: 'https://s3-alpha-sig.figma.com/img/a320/80e2/3f40551415dd93c9e316f2cc19ab218a?Expires=1699228800&Signature=mAcxBCx2Qpfo27Ec49a2HMqm3QCjk4VV4yEC~BAmq8TAlQhcnzUYe4FQyBfVfZ80~N7t6AiDySA7jz9b52Xg2MZeZo28IYvS9JJll8GBZx0r8nk4lHnxtM1vUxu7OW8Z87gfBqRpaCyuU4USkjgxsK42QqxL6nAf~qfCEqduiLmT2dcc6Oyi-mii5QFkT5v8XtHzw9vKdTAcgecigVYXUP47mkLjjwIJddO2E7MaP8MXCtTaSVrYMikP9tkmXmkBo5MP4w6Ssnk8Nfv2W-GH-wMu1CCWTaDfg3daKwGEMC7SlRQhp79nINurw7U5kopMJdKfLGRmtE6FXyp~Six9FA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
    name: 'Theme 4',
    author: 'Author 4',
  }
]

// Puedes usar este array 'themes' en tu código como un mock de datos.

export default function DisplayThemes() {
  return (
    <div className='mt-6 pt-6 border-t border-gray-200'>
      <label
        className='block text-xs font-medium mb-4'
      >
        Select Theme
      </label>
      <div className='grid grid-cols-4 gap-2' >
        {themes.map((theme, index) => {
          return <ThemeCard
            {...theme}
            key={index}
          />
        })}
      </div>
    </div>
  )
}

function ThemeCard({ imageUrl, name, author }) {
  return (
    <div className='flex flex-col items-start flex-1 self-stretch'>
      <div className='rounded-lg overflow-hidden'>
        <img
          src={imageUrl}
          alt='Theme Image'
          className='w-full h-28 object-cover'
        />
      </div>
      <div className='flex items-center gap-3 mt-2'>
        <div className='h-7 w-7 bg-gray-200 rounded-full overflow-hidden' />
        <div className='leading-tight text-xs'>
          <span className='font-semibold'>
            {name}
          </span>
          <div className='text-gray-500 font-light'>
            <span className='me-1'>By</span>
            <span className='text-primary'>{author}</span>
          </div>
        </div>
      </div>
    </div>

  )
}
