export const exportToExcel = async (selectedData, fileName = 'customers') => {
  const { default: xlsx } = await import('xlsx')
  const { default: FileSaver } = await import('file-saver')

  const worksheet = xlsx.utils.json_to_sheet(selectedData)
  const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] }
  const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' })

  const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const EXCEL_EXTENSION = '.xlsx'
  const data = new Blob([excelBuffer], {
    type: EXCEL_TYPE,
  })

  FileSaver.saveAs(data, `${fileName}_export_${new Date().getTime()}${EXCEL_EXTENSION}`)
}
