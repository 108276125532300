import React, { useState } from 'react'

import { AutoComplete } from 'primereact/autocomplete'
import { InputText } from 'primereact/inputtext'

const ScreenConfigOption = ({
  successMessage,
  handleSubcategoryChange,
  subCategoryName,
  accessCode,
  staticWorkspaces,
  setTempWorkspace,
  selectedWorkspace,
  workspaceMessage,
  tempWorkspace,
  workspacesArray,
}) => {

  const [filteredWorkspace, setFilteredWorkspace] = useState(null)

  const search = event => {
    // Timeout to emulate a network connection
    setTimeout(() => {
      let _filteredWorkspace

      if (!event.query.trim().length) {
        _filteredWorkspace = [...workspacesArray]
      } else {
        _filteredWorkspace = workspacesArray.filter(workspace => {
          return workspace.name.toLowerCase().startsWith(event.query.toLowerCase())
        })
      }

      setFilteredWorkspace(_filteredWorkspace)
    }, 250)
  }

  const panelFooterTemplate = () => {
    const isWorkspaceSelected = (filteredWorkspace || []).some(
      workspace => workspace.name === (selectedWorkspace && selectedWorkspace.name)
    )

    return (
      <div className='py-2 px-3'>
        {isWorkspaceSelected ? (
          <span>
            <b>{selectedWorkspace.name}</b> selected.
          </span>
        ) : 'No workspace selected.'}
      </div>
    )
  }

  return (
    <div>
      <div className='mb-4 text-green-500'>{successMessage}</div>
      <div className='mt-2'>
        <h5 className='mb-5 font-bold'>Screen name</h5>
        <InputText
          placeholder={accessCode.join('')}
          value={subCategoryName}
          onChange={e => handleSubcategoryChange(e)}
        />
      </div>
      <h5 className='mb-5 mt-5 font-bold'>Workspace</h5>
      <h5 className='mb-2 mt-5 font-bold'>Choose one:</h5>
      <div className='flex space-x-4 mb-5'>
        {staticWorkspaces.map(workspaceName => (
          <span
            key={workspaceName}
            className='cursor-pointer bg-gray-200 p-1 px-3 rounded'
            onClick={() => setTempWorkspace({ name: workspaceName })}
          >
            #{workspaceName}
          </span>
        ))}
      </div>
      <h5 className='mb-2 mt-5 font-bold'>Or create a new one:</h5>
      <div className='flex items-center'>
        {/* <InputText
          value={tempWorkspace.name}
          onChange={e => setTempWorkspace({ name: e.target.value })}
          placeholder='Default Workspace'
        /> */}
        <AutoComplete
          field='name'
          value={tempWorkspace}
          suggestions={filteredWorkspace}
          completeMethod={search}
          onChange={e => setTempWorkspace(e.value)}
          placeholder='Default Workspace'
          panelFooterTemplate={panelFooterTemplate}
        />

      </div>
      {selectedWorkspace && workspaceMessage &&
        <div className='mt-4'>{workspaceMessage}</div>
      }
    </div>
  )
}

export default ScreenConfigOption
