const UserMock = {
  version: 1, // Versión del objeto
  uid: '', // El ID de usuario proporcionado por Firebase Auth
  email: '', // El correo electrónico del usuario
  displayName: 'User', // El nombre para mostrar del usuario
  photoURL: 'https://example.com/photo.jpg', // URL de la foto de perfil del usuario
  phoneNumber: '', // Número de teléfono del usuario
  location: '', // La ubicación del usuario
  role: 'free', // Rol del usuario en la aplicación
  lastLogin: '', // La última vez que el usuario inició sesión
  createdAt: '', // Cuando se creó la cuenta del usuario
  appData: {
    // Datos específicos de la aplicación
    templatesCreated: 10,
    templatesId: [],
    sreensId: [],
    templatesIdFavorites: [],
  },
  preferences: {
    // Preferencias del usuario
    theme: 'dark',
    language: 'en',
    timezone: '',
  },
  stripe: {
    customerId: '', // 'cus_Izc2XQSS0GZtbN', // ID del cliente en Stripe
    subscriptionId: '', // 'sub_JK5gY35zSpJ2vr', // ID de la suscripción en Stripe
    subscriptionStatus: '', // 'active', // Estado de la suscripción en Stripe
    subscriptionItem: '', // 'price_1IV5qRHDOGAl5YNY6H3ZSuGu', // ID del producto/precio al que está suscrito el usuario
    subscriptionPeriodEnds: '', // '2024-06-11T00:00:00Z', // Fecha de finalización de la suscripción
    paymentMethod: '', // 'pm_1IVIteDOGAl5YNYOwrOXYZwt', // Método de pago en Stripe
  },
}

export default UserMock
