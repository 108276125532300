import React from 'react'

import ReactDOM from 'react-dom/client'

import AppRoutes from './App'
import { AuthProvider } from './AuthProvider'
import { WorkspaceProvider } from './context/workspace/workspace-context'
import reportWebVitals from './reportWebVitals'
import './theme/tailwind-light/theme.scss'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(

  <AuthProvider>
    <WorkspaceProvider>
      <AppRoutes />
    </WorkspaceProvider>
  </AuthProvider>

)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
