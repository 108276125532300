// hooks/useFirebaseRequestCount.js
import React, { createContext, useContext, useState } from 'react'

const FirebaseRequestContext = createContext()

export const useFirebaseRequestCount = () => {
  return useContext(FirebaseRequestContext)
}

export const FirebaseRequestProvider = ({ children }) => {
  const [count, setCount] = useState(0)

  const incrementCount = () => {
    setCount(prevCount => prevCount + 1)
  }

  return (
    <FirebaseRequestContext.Provider value={{ count, incrementCount }}>
      {children}
    </FirebaseRequestContext.Provider>
  )
}
