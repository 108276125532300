import React, { useEffect, useState } from 'react'

import { doc, onSnapshot } from 'firebase/firestore'

import { db } from '../firebase'

function ScreenStatus({ screenId }) {
  const [status, setStatus] = useState('')

  useEffect(() => {
    if (screenId) {
      const screenDoc = doc(db, 'screens', screenId)
      const unsubscribe = onSnapshot(screenDoc, docSnapshot => {
        const data = docSnapshot.data()

        if (data) {
          // Determine the status of the screen
          const now = new Date()
          const lastOnlineTime = new Date(data.lastOnline)
          const differenceInSeconds = (now - lastOnlineTime) / 1000

          if (differenceInSeconds > 30) {
            setStatus('offline')
          } else if (data.user) {
            if (data.slides) {
              setStatus('live')
            } else {
              setStatus('standby')
            }
          } else {
            setStatus('preview')
          }
        }
      })

      return () => unsubscribe()
    }
  }, [screenId])

  return (
    <div className='h-5 flex items-center text-[10px] rounded-full bg-white px-2 ms-auto'>
      <span className='bg-red-500 w-2 h-2 rounded-full me-1'></span>{status}
    </div>
  )
}

export default ScreenStatus
