
const workspaceReducer = (state, action) => {
  switch (action.type) {
  case 'ADD_NEW_WORKSPACE':
    return {
      ...state,
      workspaces: [
        ...state.workspaces,
        {
          id: action.payload.id,
          name: action.payload.name,
          initial: action.payload.initial,
          current: action.payload.current,
          templates: action.payload.templates,
          screens: action.payload.screens,
        }
      ],
    }
  case 'GET_ALL_USER_WORKSPACES':
    return {
      ...state,
      workspaces: action.payload,
    }

  case 'GET_USER_CURRENT_WORKSPACE':
    return {
      ...state,
      currentWorkspace: action.payload,
    }

  case 'GET_TEMPLATES_AND_SCREENS':
    return {
      ...state,
      templatesByWorkspace: action.payload.templates,
      screensByWorkspace: action.payload.screens,
    }

  case 'OPEN_DELETE_WORKSPACE_DIALOG':
    return {
      ...state,
      isDeleteWorkspaceDialogOpen: true,
    }
  case 'CLOSE_DELETE_WORKSPACE_DIALOG':
    return {
      ...state,
      isDeleteWorkspaceDialogOpen: false,
    }
  case 'DELETE_WORKSPACE':
    return {
      ...state,
      workspaces: state.workspaces.filter(workspace => workspace.id !== action.payload),
    }
  case 'ENSURE_DEFAULT_WORKSPACE':
    return {
      ...state,
      workspaces: [
        ...state.workspaces,
        {
          id: action.payload.id,
          name: action.payload.name,
          initial: action.payload.initial,
          current: action.payload.current,
          templates: action.payload.templates,
          screens: action.payload.screens,
        }
      ],
    }

  default:
    return state
  }
}

export default workspaceReducer
