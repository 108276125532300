import { clsx } from 'clsx'

import { activeCardStyles, inactiveCardStyles, generalCardStyles } from './Step1NewTemplate'

export default function FormatCardOption({
  label,
  text,
  value,
  pickAFormat,
  templateFormat,
  setIsCustomActive,
  icon,
}) {

  return (
    <div onClick={() => {
      pickAFormat(value)
      setIsCustomActive(false)
    }} className={clsx(templateFormat === value && activeCardStyles, templateFormat !== value && inactiveCardStyles, generalCardStyles)}>

      <div className='w-11 flex items-center justify-center'>{icon}</div>
      <div className='ms-2 leading-none'>
        <span className='text-xs font-semibold'>{text}</span>
        <div className='text-xs text-gray-50'>{label}</div>
      </div>
    </div>
  )
}
