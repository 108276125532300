/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react'

import {
  ArrowRightOnRectangleIcon,
  TvIcon,
  PhotoIcon,
} from '@heroicons/react/24/outline'
import { FilterMatchMode } from 'primereact/api'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'

import { AuthContext } from '../AuthProvider'
import { Layout } from '../layout/Layout'
import { getUserLeads } from '../services/ApiService'
// import { CustomerService } from '../services/CustomerService'
import { exportToExcel } from '../services/excelService'
import { formatDateForColumn } from '../utils/dateUtils'

export default function LeadListPage() {
  const { user, token } = useContext(AuthContext)
  const [loading, setLoading] = useState(true)
  const [customers, setCustomers] = useState(null)
  const [leadType] = useState(['url', 'Form lead'])
  const [selectedCustomers, setSelectedCustomers] = useState([])
  const [visible, setVisible] = useState(false)
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedRowData, setSelectedRowData] = useState(null)
  const [filters, setFilters] = useState({
    leadType: { value: null, matchMode: FilterMatchMode.EQUALS },
    screenName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    templateName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    slideName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    category: { value: null, matchMode: FilterMatchMode.IN },
    createdAt: { value: null, matchMode: FilterMatchMode.EQUALS },
  })

  const onSelectionChange = e => {
    const selectedIds = e.value.map(row => row.id).filter(id => id !== undefined)

    setSelectedCustomers(selectedIds)

    // Manually update the selection state of the DataTable
    const updatedCustomers = customers.map(row => {
      if (selectedIds.includes(row.id)) {
        return {
          ...row,
          selected: true,
        }
      }

      return row
    })

    setCustomers(updatedCustomers)

    // eslint-disable-next-line no-unused-vars
    const selectedCustomersData = customers.filter(customer => selectedIds.includes(customer.id))

    const updatedSelection = e.value.map(row => {
      if (selectedIds.includes(row.id)) {
        return {
          ...row,
          selected: true,
        }
      }

      return row
    })

    setSelectedCustomers(updatedSelection)

    // Removed exportExcel(selectedCustomersData) from here
  }

  // Call exportExcel when needed, for example, in a button click event
  const handleExportClick = () => {
    const selectedIds = selectedCustomers.map(row => row.id).filter(id => id !== undefined)
    const selectedCustomersData = customers.filter(customer => selectedIds.includes(customer.id))
      .map(({ type, screen, template, slide, category, date }) => ({
        type,
        screen,
        template,
        slide,
        category,
        date: formatDateForColumn(date),
      }))

    exportToExcel(selectedCustomersData)
  }

  /*  useEffect(() => {
    CustomerService.getCustomersMedium().then(data => {
      setCustomers(getCustomers(data))
      setLoading(false)
    })
  }, []) */

  useEffect(() => {
    getUserLeads(user.uid, token).then(data => {
      setCustomers(data.data.data.map(item => {
        return {
          ...item,
          createdAt: item.createdAt.toString().substring(0, 10),
        }
      }))

      console.log(customers)

      setLoading(false)
    }).catch(error => {
      console.log('getUserLeads - Error:', error)
    })

  }, [user.uid, token])

  const getCustomers = data => {
    const filterValue = filters.createdAt.value

    if (!filterValue) {
      return data?.map(customer => {
        const formattedDate = formatDateForColumn(customer.date)

        return { ...customer, formattedDate }
      })
    }

    const selectedDate = new Date(filterValue)
    const filteredData = data.filter(customer => {
      const customerDate = new Date(customer.createdAt)

      return (
        customerDate.getFullYear() === selectedDate.getFullYear() &&
        customerDate.getMonth() === selectedDate.getMonth() &&
        customerDate.getDate() === selectedDate.getDate()
      )
    })

    return filteredData.map(customer => {
      const formattedDate = formatDateForColumn(customer.date)

      return { ...customer, formattedDate }
    })

  }

  const showDialog = rowData => {
    setSelectedRowData(rowData)
    setVisible(true)
  }

  const statusRowFilterTemplate = options => {

    console.log(options)

    return (
      <Dropdown value={options.value} options={leadType} onChange={e => options.filterApplyCallback(e.value)} placeholder='All' className='p-column-filter' showClear />
    )
  }

  const onDateFilterChange = event => {

    const selectedDateTmp = event.value ? new Date(event.value) : null

    setSelectedDate(selectedDateTmp)

    setFilters(prevFilters => ({
      ...prevFilters,
      createdAt: {
        value: selectedDateTmp ? selectedDateTmp.toISOString().substring(0, 10) : selectedDate,
        matchMode: FilterMatchMode.EQUALS,
      },
    }))
  }

  const dateRowFilterTemplate = options => {

    console.log(options)

    return (
      <Calendar
        value={selectedDate}
        onChange={onDateFilterChange}
        dateFormat='yy-mm-dd'
        placeholder='yyyy-mm-dd'
        showIcon={false}
      />
    )
  }

  const convertDateRange = selectedDates => {
    const startDate = selectedDates[0] ? new Date(selectedDates[0]) : null
    const endDate = selectedDates[1] ? new Date(selectedDates[1]) : null

    console.log('convertDateRange - Start Date:', startDate)
    console.log('convertDateRange - End Date:', endDate)

    setFilters(prevFilters => ({
      ...prevFilters,
      date: {
        value: [startDate, endDate],
        matchMode: FilterMatchMode.CUSTOM,
      },
    }))
  }

  const dateBodyTemplate = rowData => {
    return <span>{formatDateForColumn(rowData.createdAt)}</span>
  }

  const headerTemplate = (
    <div className='header-buttons'>
      <Button
        label='Export'
        iconPos='right'
        type='button'
        severity='primary'
        onClick={handleExportClick}
        data-pr-tooltip='XLS'>
        <ArrowRightOnRectangleIcon className='h-5 w-5' aria-hidden='true' />
      </Button>
    </div>
  )

  const headerDialog = (
    <>
      <h3 className='text-xl'>Lead details</h3>
      <p className='text-sm font-light'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </>
  )

  return (

    <Layout>
      <div className='p-6 flex flex-col h-[--main-height]'>
        <h2 className='text-2xl font-bold tracking-tight text-gray-900 mb-1'>Your Leads</h2>
        <p className='text-sm mb-6'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer elementum accumsan nisl</p>
        {loading && <p>Loading data...</p>}
        <DataTable
          value={customers}
          paginator
          rows={10}
          dataKey='id'
          selectionMode='checkbox'
          // selection={selectedCustomers}
          // onSelectionChange={onSelectionChange}
          filters={filters}
          // showFilterMenu={false}
          filterDisplay='row'
          loading={loading}
          emptyMessage='No results'
        >
          <Column className='p-datatable-select' headerStyle={{ width: '3rem' }} selectionMode='multiple'></Column>
          <Column className='p-datatable-first-colum'
            header='Lead type'
            field='leadType'
            sortable
            filter
            filterPlaceholder='Filter by Lead type'
            showFilterMenu={false}
            filterElement={statusRowFilterTemplate}
            body={rowData => (
              <div className='flex'>
                <PhotoIcon className='shrink-0 h-4 w-4 text-gray-50 mr-2' aria-hidden='true' />
                {rowData.leadType}
              </div>
            )}

          />
          <Column
            header='Screen'
            sortable
            field='screenName'
            filter
            filterPlaceholder='Filter by Screen name'
            showFilterMenu={false}
            body={rowData => (
              <div className='flex'>
                <TvIcon className='shrink-0 h-4 w-4 text-gray-50 mr-2' aria-hidden='true' />
                {rowData.screenName ? rowData.screenName : 'Pending Title  screen'}
              </div>
            )}
          />
          <Column
            header='Template'
            sortable
            filter
            field='templateName'
            filterPlaceholder='Filter by Template name'
            showFilterMenu={false}
            body={rowData => (
              <div className='flex'>
                <PhotoIcon className='shrink-0 h-4 w-4 text-gray-50 mr-2' aria-hidden='true' />
                {rowData.templateName}
              </div>
            )}
          />
          <Column
            header='Slide'
            field='slideName'
            sortable
            filter
            filterPlaceholder='Filter by Slide'
            showFilterMenu={false}
            body={rowData => (
              <div className='flex'>
                <PhotoIcon className='shrink-0 h-4 w-4 text-gray-50 mr-2' aria-hidden='true' />
                {rowData.slideName}
              </div>
            )}
          />
          <Column
            header='Workspace'
            sortable
            filter={false}
            filterPlaceholder='Filter by Workspace'
            showFilterMenu={false}
            body={rowData => (
              <div className='flex'>
                <span className='mr-1 text-gray-50'>In</span>
                {/* {rowData.category} */}
                #Workspace
              </div>
            )}
          />
          {/*     onChange={convertDateRange} */}

          <Column
            className='p-datatable-last-colum date-column'
            header='Date'
            sortable
            field='createdAt'
            filter
            filterPlaceholder='Filter by date'
            showFilterMenu={false}
            filterElement={dateRowFilterTemplate}
            body={dateBodyTemplate}/>

          <Column
            className='p-datatable-button-column'
            header={headerTemplate}
            filterMenuStyle={{ width: '6.5rem' }}
            style={{ minWidth: '6.5rem' }}
            body={rowData => (
              <>
                {rowData.leadType && <Button label='See details' onClick={() => showDialog(rowData)} />}
              </>
            )}
          />
        </DataTable>
      </div>
      <Dialog
        header={headerDialog}
        visible={visible}
        style={{ 'max-width': '760px', width: '90vw' }}
        onHide={() => setVisible(false)}
        draggable={false}
      >
        <div className='selected-row-data'>
          {selectedRowData && (
            <>
              <ul>
                <li className='mb-4'>
                  <span className='text-xs text-gray-50'>Lead type</span>
                  <p className='font-medium'> {selectedRowData.leadType}</p>
                </li>
                <li className='mb-4'>
                  <span className='text-xs text-gray-50'>Screen</span>
                  <p className='font-medium'> {selectedRowData.screenName}</p>
                </li>
                <li className='mb-4'>
                  <span className='text-xs text-gray-50'>Template</span>
                  <p className='font-medium'> {selectedRowData.templateName}</p>
                </li>
                <li className='mb-4'>
                  <span className='text-xs text-gray-50'>Slide</span>
                  <p className='font-medium'> {selectedRowData.slideName}</p>
                </li>
                <li className='mb-4'>
                  <span className='text-xs text-gray-50'>Category</span>
                  <p className='font-medium'> {'Default workspace'}</p>
                </li>
                <li className='mb-4'>
                  <span className='text-xs text-gray-50'>Date</span>
                  <p className='font-medium'> {formatDateForColumn(selectedRowData.createdAt)}</p>
                </li>
              </ul>

              {/* {selectedRowData.type === 'Form lead' && (
                <ul className='mt-4 border-t border-gray-200'>
                  <li className='mb-4'>
                    <span className='text-xs text-gray-50'>Name</span>
                    <p className='font-medium'>Cathy</p>
                  </li>
                  <li className='mb-4'>
                    <span className='text-xs text-gray-50'>Last name</span>
                    <p className='font-medium'>Rolfson</p>
                  </li>
                  <li className='mb-4'>
                    <span className='text-xs text-gray-50'>E-mail</span>
                    <p className='font-medium'>Cathy_Rolf@gmail.com</p>
                  </li>
                </ul>
              )} */}

            </>
          )}
        </div>
      </Dialog>

    </Layout>
  )
}
