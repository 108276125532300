import React from 'react'

function ItemOne() {
  return (
    <div className='p-6 bg-white shadow rounded-lg'>
      <h2 className='text-xl font-semibold mb-4'>Item One Title</h2>
      <p className='text-gray-700 mb-4'>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum.
      </p>
      <button className='mt-2 px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-500'>
        Button One
      </button>
    </div>
  )
}

export default ItemOne
