import { useState } from 'react'

import {
  // Bars3Icon,
  // CalendarIcon,
  // ChartPieIcon,
  // DocumentDuplicateIcon,
  // FolderIcon,
  HomeIcon,
  UsersIcon,
  TvIcon,
  PhotoIcon,
  // XMarkIcon,
} from '@heroicons/react/24/outline'

import AnnouncementBar from '../layout/AnnouncementBar'
import Header from '../layout/Header'
import Sidebar from '../layout/Sidebar'

const navigation = [
  { name: 'Dashboard', href: '/', icon: HomeIcon, current: true },
  { name: 'Templates', href: '/template-list', icon: PhotoIcon, current: false },
  { name: 'Screens/TV', href: '/tv-list', icon: TvIcon, current: false },
  { name: 'Leads', href: '/lead-list', icon: UsersIcon, current: false }
]

export const Layout = ({ children }) => {

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [announcementVisible, setAnnouncementVisible] = useState(true)
  const [workspaces, setWorkspaces] = useState([])

  const handleDismiss = () => {
    setAnnouncementVisible(false)
  }

  return (
    <div>
      {announcementVisible &&
        <AnnouncementBar
          title='New feature: Announcements'
          message='Announcements are a great way to communicate with your users.'
          link='#'
          onDismiss={handleDismiss}
        />
      }
      <Sidebar
        navigation={navigation}
        teams={[]}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        announcementVisible={announcementVisible}
        workspaces={workspaces}
        setWorkspaces={setWorkspaces}
      />
      <Header setSidebarOpen={setSidebarOpen} announcementVisible={announcementVisible} />
      <main className={`bg-gray-100 pb-10 lg:pl-72 ${announcementVisible ? 'min-h-[--main-height-banner]' : 'min-h-[--main-height]'}`}>
        <div className='px-4 sm:px-6 lg:px-8'>
          { children }
        </div>
      </main>
    </div>
  )
}
