import React, { useContext, useEffect, useState } from 'react'

import { collection, query, where, getDocs } from 'firebase/firestore'

import { AuthContext } from '../AuthProvider'
import CardScreen from '../components/CardScreen'
import DialogNewScreen from '../components/dialog/dialog-new-screen/DialogNewScreen'
import { db } from '../firebase'
import { Layout } from '../layout/Layout'

const tabs = [
  { name: 'All', href: '#', current: true },
  { name: 'Linked', href: '#', current: false },
  { name: 'Unlinked', href: '#', current: false }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function TVListPage() {

  // Obtenemos el usuario actual del contexto
  const { user } = useContext(AuthContext)
  const [userScreens, setUserScreens] = useState([])

  async function getScreensByUser(userId) {
    const screensRef = collection(db, 'screens')
    const queryRef = query(screensRef, where('user', '==', userId))

    const screensSnapshot = await getDocs(queryRef)

    const screens = []

    screensSnapshot.forEach(doc => {
      const screenData = doc.data()
      const screen = {
        id: doc.id,
        ...screenData,
      }

      screens.push(screen)
    })

    return screens
  }

  useEffect(() => {
    async function fetchData() {
      const screens = await getScreensByUser(user.uid)

      setUserScreens(screens)
    }

    fetchData()
  }, [user.uid])

  const displayScreens = userScreens

  const [visibleDialogNewScreen, setVisibleDialogNewScreen] = useState(false)

  const showDialogNewScreen = () => {
    setVisibleDialogNewScreen(true)
  }

  return (
    <Layout>
      {/* Dialog New Screen */}
      <DialogNewScreen visible={visibleDialogNewScreen} setVisible={setVisibleDialogNewScreen} />
      {/* Dialog New Screen */}
      {/* Your content */}
      <div className='mx-auto px-4 pt-8 sm:px-6 lg:px-8'>
        <h2 className='text-2xl font-bold tracking-tight text-gray-900 mb-1'>Your Screens/TV</h2>
        <p className='text-sm'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer elementum accumsan nisl</p>
        <div className='sm:flex sm:items-baseline sm:justify-between mt-4'>
          {/* Tabs */}
          <div>
            {/* Select tab mobile */}
            <div className='sm:hidden'>
              <label htmlFor='tabs' className='sr-only'>
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id='tabs'
                name='tabs'
                className='block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                defaultValue={tabs.find(tab => tab.current).name}
              >
                {tabs.map(tab =>
                  <option key={tab.name}>{tab.name}</option>
                )}
              </select>
            </div>
            {/* Tabs Desktop */}
            <div className='hidden sm:block'>
              <nav className='-mb-px flex space-x-4' aria-label='Tabs'>
                {tabs.map(tab => (
                  <a
                    key={tab.name}
                    href={tab.href}
                    className={classNames(
                      tab.current
                        ? 'border-black text-black'
                        : 'border-transparent text-gray-50 hover:border-gray-300 hover:text-gray-700',
                      'whitespace-nowrap border-b-2 py-2 px-1 text-sm font-medium'
                    )}
                    aria-current={tab.current ? 'page' : undefined}
                  >
                    {tab.name}
                  </a>
                ))}
              </nav>
            </div>
          </div>
          {/* End Tabs */}
          <div className='flex text-sm'>
            <div className='flex items-center'>
              <span className='me-1'>In </span>
              <select
                id='workspace'
                name='workspace'
                className='block w-full bg-transparent border-0 py-0 ps-2 pr-8 text-gray-900 sm:text-sm sm:leading-6'
                defaultValue='Default Workspace'
              >
                <option>#Default Workspace</option>
                <option>#Zara</option>
                <option>#Mercadona</option>
              </select>
            </div>
          </div>
        </div>
        <div className='mt-6 grid grid-cols-1 gap-y-10 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-0 2xl:grid-cols-3'>
          <button
            onClick={showDialogNewScreen}
            type='button'
            className='relative block w-full rounded-lg bg-white p-12 text-center focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 aspect-video mb-6'
          >
            <svg
              className='mx-auto h-8 w-8 text-gray-50'
              stroke='currentColor'
              fill='none'
              viewBox='0 0 48 48'
              aria-hidden='true'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6'
              />
            </svg>
            <span className='mt-2 block text-sm font-semibold text-gray-900'>Pair new Screen</span>
          </button>
          {displayScreens.map(screen =>
            <CardScreen key={screen.id} screen={screen} />
          )}
        </div>
      </div>
      {/* Your content */}
    </Layout>
  )
}
