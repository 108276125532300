import React, { useRef } from 'react'

import { OverlayPanel } from 'primereact/overlaypanel'

function TextTool({ addNewItem }) {

  // OverlayPanel / Menús desplegables de la barra de herramientas
  const textPanel = useRef(null)

  return (
    <>
      <button className='flex flex-col items-center px-2 hover:text-blue-500' onClick={e => textPanel.current.toggle(e)} aria-haspopup aria-controls='overlay_panel' >
        <span className='text-xl font-serif leading-none mb-1'>T</span>
        <span className='text-[10px] leading-none'>Text</span>
      </button>
      <OverlayPanel ref={textPanel} showCloseIcon id='overlay_panel' style={{ width: '300px' }} className='overlaypanel-demo'>
        <div className='px-6 py-3 border-b border-gray-200 text-sm font-semibold'>Text</div>
        <ul>
          <li className='py-1.5 px-6 border-b border-gray-100'>
            <button className='w-full text-left font-bold text-4xl' onClick={() => addNewItem({
              type: 'text',
              // otros paremetros que queramos pasar al item tipo texto
            })}>Heading 1</button>
          </li>
          <li className='py-1.5 px-6 border-b border-gray-100'>
            <button className='w-full text-left font-bold text-2xl' onClick={() => addNewItem({
              type: 'text',
              // otros paremetros que queramos pasar al item tipo texto
            })}>Heading 2</button>
          </li>
          <li className='py-1.5 px-6 border-b border-gray-100'>
            <button className='w-full text-left font-bold text-xl' onClick={() => addNewItem({
              type: 'text',
              // otros paremetros que queramos pasar al item tipo texto
            })}>Heading 3</button>
          </li>
          <li className='py-1.5 px-6 border-b border-gray-100'>
            <button className='w-full text-left text-base' onClick={() => addNewItem({
              type: 'text',
              // otros paremetros que queramos pasar al item tipo texto
            })}>Paragraph 1</button>
          </li>
          <li className='py-1.5 px-6 border-b border-gray-100'>
            <button className='w-full text-left text-sm' onClick={() => addNewItem({
              type: 'text',
              // otros paremetros que queramos pasar al item tipo texto
            })}>Paragraph 2</button>
          </li>
          <li className='py-1.5 px-6'>
            <button className='w-full text-left text-xs' onClick={() => addNewItem({
              type: 'text',
              // otros paremetros que queramos pasar al item tipo texto
            })}>Paragraph 3</button>
          </li>
        </ul>
      </OverlayPanel>
    </>
  )
}

export default TextTool
