import React from 'react'

const PairScreenOption = ({ accessCode, handleInputChange, handleKeyDown, handlePaste, isCodeCorrect, setAccessCode }) => {
  return (
    <div className='flex flex-col justify-center h-full'> {/* Add 'justify-center' to center children vertically and 'h-full' to ensure it takes full height of its parent */}
      <div className='flex justify-evenly mb-4'>
        <p className='font-bold text-gray-600'>Enter screen code</p>
        <p className='text-blue-600'>How to link a screen?</p>
      </div>
      <div className='flex space-x-2 justify-center'> {/* 'justify-center' will center the inputs horizontally */}
        {accessCode.map((char, idx) => (
          <input
            key={idx}
            type='text'
            maxLength='1'
            placeholder=' '
            className={`w-16 h-24 text-center ${isCodeCorrect ? 'text-green-500 border-green-500' : 'border-gray-300 text-black'} rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 lg:text-lg`}
            value={char}
            onChange={e => handleInputChange(e, idx, accessCode, setAccessCode)}
            onKeyDown={e => handleKeyDown(e, idx, accessCode, setAccessCode)}
            onPaste={e => idx === 0 ? handlePaste(e, setAccessCode) : null}
          />
        ))}
      </div>
    </div>
  )

}

export default PairScreenOption
