import {
  TvIcon,
} from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

import AvatarGroup from './AvatarGroup'
import ScreenStatus from './ScreenStatus'

export default function CardScreen({ screen }) {

  const imageSrc = 'https://images.unsplash.com/photo-1622547748225-3fc4abd2cca0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1332&q=80'
  const imageAlt = 'Real State'

  return (
    <div key={screen.id} className='group relative mb-4'>
      <div className='w-full overflow-hidden rounded-lg aspect-video relative group-hover:opacity-75'>
        <div className='flex absolute py-3 px-4 inset-0'>
          <ScreenStatus screenId={screen.id} />
        </div>
        <img
          src={imageSrc}
          alt={imageAlt}
          className='h-full w-full object-cover object-center'
        />
        <a href={screen.href}><span className='absolute inset-0' /></a>
      </div>
      <div className='p-4 flex items-center'>
        <div className='flex-1'>
          <h3 className='text-sm font-semibold'>
            <a href={screen.href}>Screen Title</a>
          </h3>
          <p className='text-sm text-gray-50 flex'>
            <span className='me-1'>In </span>
            <Link to={`/workspace/${screen.workspaceId}`} href='#' className='text-sm text-primary hover:text-blue-600'> {screen.workspaceName ? screen.workspaceName : '#Default workspace'} </Link>
            <span className='mx-1.5'>.</span>
            <a href='#' className='text-sm text-primary hover:text-blue-600 flex'><TvIcon className='h-4 w-4 text-gray-50 me-1' aria-hidden='true' /> Template title</a>
          </p>
        </div>
        <AvatarGroup />
      </div>
    </div>
  )
}
