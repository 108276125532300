import { v4 as uuidv4 } from 'uuid'

const TemplateMock = {
  version: 1, // Versión del objeto
  config: {
    integrations: [],
    settings: {
      aspectRatio: '16:9',
      title: 'Default Title',
      workspace: {
        id: '',
        title: 'Title Workspace',
      },
    },
    type: null,
  },
  templateId: uuidv4(),
  creatorId: '',
  slides: [],
  screensIdAttached: [],
  users: {
    editor: [],
    owner: [],
    viewer: [],
  },
  creationTime: new Date(),
  updateTime: new Date(),
  workspaceId: '',
}

export default TemplateMock
