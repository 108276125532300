
import { useContext } from 'react'

import { Dialog } from 'primereact/dialog'
import { useNavigate } from 'react-router-dom'

import { AuthContext } from '../../AuthProvider'
import { useWorkspaceContext } from '../../context/workspace/workspace-context'

export default function DialogDeleteWorkspace() {
  const { user } = useContext(AuthContext)
  const { isDeleteWorkspaceDialogOpen, closeDeleteWorkspaceDialog, currentWorkspace, deleteWorkspace } = useWorkspaceContext()
  const navigate = useNavigate()

  return (
    <Dialog visible={isDeleteWorkspaceDialogOpen} style={{ width: '50vw' }} onHide={closeDeleteWorkspaceDialog}
      draggable={false} resizable={false}
    >
      <p className='m-0'>
        Are you sure you want to delete this workspace?
      </p>
      <p>
        {/* Explain that all the templates and screens are going to default workspace if the current workspace is deleted */}
        All the templates and screens are going to be moved to the default workspace, If you delete the workspace.
      </p>
      <div>
        <button className='bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline-red active:bg-red-800'
          onClick={() => {
            deleteWorkspace(currentWorkspace.id, user.uid)
            navigate('/')
          }} >Delete workspace</button>
        <button onClick={closeDeleteWorkspaceDialog}className='bg-gray-200 hover:bg-gray-300 text-gray-900 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline-red active:bg-gray-800' >Cancel</button>
      </div>
      {/* <div>
        do you want to delete all?

      </div> */}
    </Dialog>
  )
}
