/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react'

import {
  UserGroupIcon,
} from '@heroicons/react/24/outline'
import { OverlayPanel } from 'primereact/overlaypanel'

import FormComponent from './leads/formComponent'
import UrlComponent from './leads/urlComponent'

function LeadsTool({ localTemplate, setLocalTemplate, slideId }) {

  const [selectedTool, setSelectedTool] = useState('URL')
  const [enabledQR, setEnabledQR] = useState(false)

  // OverlayPanel / Menús desplegables de la barra de herramientas
  const leadsPanel = useRef(null)

  const handleButtonClick = toolName => {
    setSelectedTool(toolName)
  }

  const toggleSwitch = () => {
    setEnabledQR(prevState => !prevState)
  }

  return (
    <>
      <button className='flex flex-col items-center px-2 hover:text-primary' onClick={e => leadsPanel.current.toggle(e)} aria-haspopup aria-controls='overlay_panel' >
        <UserGroupIcon className='h-5 w-5 mb-1' aria-hidden='true' />
        <span className='text-[10px] leading-none'>Leads</span>
      </button>
      <OverlayPanel ref={leadsPanel} showCloseIcon id='overlay_panel' style={{ width: '330px' }} className='overlaypanel-demo'>
        <div className='px-6 py-3 border-b border-gray-200 text-sm font-semibold'>Leads</div>
        <div className='px-6 py-5'>
          <div className='mb-3'>
            <label className='relative inline-flex items-center cursor-pointer'>
              <input
                type='checkbox'
                checked={enabledQR}
                onChange={() => setEnabledQR(prev => !prev)}
                className='sr-only peer'
              />
              <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              <span className='ml-3 text-sm font-medium text-gray-900 dark:text-gray-600'>Enable</span>
            </label>
          </div>
          <span className='isolate inline-flex rounded-full w-full'>
            <button
              onClick={() => handleButtonClick('URL')}
              type='button'
              className={`flex-1 relative inline-flex items-center justify-center rounded-l-full px-3 h-7 text-sm border focus:z-10 ${selectedTool === 'URL' ? 'border-primary bg-primary text-white' : 'bg-white border-gray-300'}`}
            >
              URL Lead
            </button>
            <button
              onClick={() => handleButtonClick('Form')}
              type='button'
              className={`flex-1 relative -ml-px inline-flex items-center justify-center rounded-r-full px-3 h-7 text-sm border focus:z-10 ${selectedTool === 'Form' ? 'border-primary bg-primary text-white' : 'bg-white border-gray-300'}`}
            >
              Form Lead
            </button>
          </span>
          {selectedTool === 'URL' && <UrlComponent setLocalTemplate={setLocalTemplate} localTemplate={localTemplate} slideId={slideId} enabledQR={enabledQR}/>}
          {selectedTool === 'Form' && <FormComponent />}
        </div>
      </OverlayPanel>
    </>
  )
}

export default LeadsTool
