import CardScreen from './CardScreen'

export default function DisplayScreens({ title, screens }) {
  return (
    <div className='mb-1'>
      <h3 className='text-2xl font-bold tracking-tight text-gray-900 lg:py-4'>{title}</h3>
      <div className='mt-4 grid grid-cols-1 gap-y-10 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-0 xl:grid-cols-3 2xl:grid-cols-4'>
        {screens.map((screen, index) =>
          <CardScreen key={screen.id} screen={screen} />
        )}
      </div>
    </div>
  )
}
