import React from 'react'

import {
  TrashIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline'
import { ColorPicker } from 'primereact/colorpicker'
import { Dropdown } from 'primereact/dropdown'

const itemsForm = [
  {
    label: 'Arial',
    value: 'Arial',
  },
  {
    label: 'Roboto',
    value: 'Roboto',
  },
  {
    label: 'Sans Serif',
    value: 'Sans Serif',
  }
]

export default function TextEditorBar({
  y,
  x,
  setFontSize,
  changeItemStyles,
  elementId,
  fontSize,
  color,
  setColor,
}) {
  return (
    <div className='flex items-stretch h-7 shadow-lg bg-white rounded-lg divide-x'>
      <div className='flex items-center px-2 py-1'>
        <Dropdown
          options={itemsForm}
          value={itemsForm[0].value}
          filter
          className='border-none'
        />
      </div>
      <div className='flex items-center px-2 py-1'>
        <div className='flex items-center border border-solid rounded-full px-1 space-x-0.5'>
          <button onClick={() => {
            setFontSize(fontSize - 1)
            changeItemStyles({
              elementId,
              fontSize: fontSize - 1,
            })
          }}>
            <ChevronLeftIcon className='h-2 w-2' aria-hidden='true' />
          </button>
          <span className='text-xs'>{fontSize}</span>
          <button onClick={() => {
            setFontSize(fontSize + 1)
            changeItemStyles({
              elementId,
              fontSize: fontSize + 1,
            })
          }}>
            <ChevronRightIcon className='h-2 w-2' aria-hidden='true' />
          </button>
        </div>
      </div>
      <div className='flex items-center px-2 py-1 space-x-1'>
        <button>
          <TrashIcon className='h-4 w-4' aria-hidden='true' />
        </button>
        <button>
          <TrashIcon className='h-4 w-4' aria-hidden='true' />
        </button>
        <button>
          <TrashIcon className='h-4 w-4' aria-hidden='true' />
        </button>
      </div>
      <div className='flex items-center px-2 py-1'>
        <ColorPicker
          inputClassName='!w-4 !h-4 !p-0 !rounded-full'
          value={color}
          onChange={e => {
            setColor(e.value)
            changeItemStyles({
              elementId,
              fontSize: fontSize + 1,
              color: e.value,
            })
          }}
        />
      </div>
      <div className='flex items-center px-2'>
        <button className='text-red-500 hover:text-red-600'>
          <TrashIcon className='h-4 w-4' aria-hidden='true' />
        </button>
      </div>
    </div>
  )
}
